import React, {FC, useRef} from 'react';
import {useAccessStatistics, useDocumentTitle, useI18n} from '@hooks';
import Banner from './Banner';
import Product from './Product';
import Solution from './Solution';
import Source from './Source';
import Case from './Case';
import Partner from './Partner';
import ContactUs from './ContactUs';
import routes from '@routes';

const Home: FC = () => {
	// i18n.
	const i18n = useI18n('Home');

	// After rendering and re-rendering.
	useDocumentTitle(i18n('documentTitle'));

	// Access Statistics.
	useAccessStatistics(routes.Home);

	// Reference equivalent child components.
	const BannerRef = useRef(<Banner />);
	const ProductRef = useRef(<Product />);
	const SolutionRef = useRef(<Solution />);
	const SourceRef = useRef(<Source />);
	const CaseRef = useRef(<Case />);
	const PartnerRef = useRef(<Partner />);
	const ContactUsRef = useRef(<ContactUs />);

	return (
		<div className="oos-home">
			{BannerRef.current}
			{ProductRef.current}
			{SolutionRef.current}
			{SourceRef.current}
			{CaseRef.current}
			{PartnerRef.current}
			{ContactUsRef.current}
		</div>
	);
};

export default Home;
