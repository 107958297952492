const zhCN = {
	documentTitle: '高性能计算解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '高性能计算解决方案',
		subTitle: '采用高密度服务器集群构建存储资源池，面向高性能计算提供可扩展、高可靠、高性能的数据存储能力，具备I/0密集型和网络密集型的存储处理能力。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		lackOfCapacity: {
			title: '存储空间不足，功耗和成本高',
			desc: '传统并行文件系存储资源急剧增长，占用空间大，功耗大，成本高，运维管理复杂度大。',
		},
		highComplexity: {
			title: '复杂度高',
			desc: '传统并行文件存储系统硬件依赖度大，部署操作和运维管理复杂。。',
		},
		performanceBarelyMeetsRequirement: {
			title: '性能仅满足高宽带需求',
			desc: '传统并行文件系存储系统性能仅满足高宽带需求，无法满足对性能要求比较高的应用，局限性大。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '高性能计算解决方案介绍',
			desc: '针对这些需求，奥卡云提出基于OrcaFS分布式并行文件的高性能计算解决方案，采用高密度服务器集群构建存储资源池，面向高性能计算提供可扩展、高可靠、高性能的数据存储能力，具备I/0密集型和网络密集型的存储处理能力。针对高吞吐型、高IOPS型计算做了最佳的软硬件配置，无需用户选择性配置系统，兼容各种高性能计算应用，使用户无缝对接高性能计算应用。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		bigCapacity: {
			title: '超大容量',
			desc: '支持元数据和存储独立横向扩展，容量和性能随节点数量增加线性增长，集群支持EB级容量和亿级文件数量。',
		},
		highPerformance: {
			title: '性能优越',
			desc: '基于RDMA网络协议构建文件系统网络框架，在Infiniband 和100Gb网络环境下可提供上百GB/s的读写带宽和百万级的IOPS，支持上万个客户端并行访问的超大规模集群。',
		},
		easyToUse: {
			title: '简单易用',
			desc: '采用通用型硬件，部署操作便捷，无硬件依赖。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
