import {newsActionTypes} from '@actions/newsAction';
import {Reducer} from '../interfaces';

const newsReducer: Reducer = (state, action) => {
    const {firmNewsList, firmNewsDetail} = action;

    switch (action.type) {
        case newsActionTypes.SET_FIRM_NEWS_LIST:
            return Object.assign({}, state, {firmNewsList});

        case newsActionTypes.SET_FIRM_NEWS_DETAIL:
            return Object.assign({}, state, {firmNewsDetail});

        default:
            return state;
    }
};

export default newsReducer;
