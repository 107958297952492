import {useMemo} from 'react';
import {useI18n} from '@hooks';
import {SolutionMenuCategoryMap} from '@interfaces';
import routes from '@routes';

export default function useSolutionMenuCategoryMap (){
	// Storage solution.
	// General category.
	const i18nBigData = useI18n('BigData');
	const i18nHighPerformanceComputing = useI18n('HighPerformanceComputing');
	const i18nCloudComputingPrivateCloud = useI18n('CloudComputingPrivateCloud');
	const i18nCloudNativeContainerStorage = useI18n('CloudNativeContainerStorage');
	const i18nDataBackup = useI18n('DataBackup');

	// Industry category.
	const i18nNewEnergyVehicle = useI18n('NewEnergyVehicle');
	const i18nIntelligentCampus = useI18n('IntelligentCampus');
	const i18nIntelligentSecurity = useI18n('IntelligentSecurity');
	const i18nAI = useI18n('AI');

	// IoT solution.
	// Intelligent Police category.
	const i18nBrightProject = useI18n('BrightProject')
	const i18nMajorEventSecurity = useI18n('MajorEventSecurity');

	// Intelligent community category.
	const i18nIntelligentCommunity= useI18n('IntelligentCommunity');
	const i18nIntelligentCommunitySecurity = useI18n('IntelligentCommunitySecurity');
	const i18nIntelligentElevator = useI18n('IntelligentElevator');
	const i18nHighAltitudeThrowObject = useI18n('HighAltitudeThrowObject');

	// Intelligent park category.
	const i18nIntelligentPark = useI18n('IntelligentPark');

	// Intelligent scenic spot category.
	const i18nIntelligentScenicSpot = useI18n('IntelligentScenicSpot');
	const i18nAllAreaTourism = useI18n('AllAreaTourism');

	// Carbon neutrality category.
	const i18nCarbonNeutrality = useI18n('CarbonNeutrality');

	// Intelligent garden category.
	const i18nIntelligentGarden = useI18n('IntelligentGarden');

	// Emergency category.
	const i18nEmergencyCommand = useI18n('EmergencyCommand');
	const i18nSafetyProductionEmergency = useI18n('SafetyProductionEmergency');

	// General category.
	const i18nIoTPlatform = useI18n('IoTPlatform');

	return useMemo<SolutionMenuCategoryMap>(() => ({
		storage: {
			general: [
				{
					title: i18nBigData('banner.title'),
					subTitle: i18nBigData('banner.subTitle'),
					path: routes.BigData,
					imgCls: 'big-data',
				}, {
					title: i18nHighPerformanceComputing('banner.title'),
					subTitle: i18nHighPerformanceComputing('banner.subTitle'),
					path: routes.HighPerformanceComputing,
					imgCls: 'high-performance-computing',
				}, {
					title: i18nCloudComputingPrivateCloud('banner.title'),
					subTitle: i18nCloudComputingPrivateCloud('banner.subTitle'),
					path: routes.CloudComputingPrivateCloud,
					imgCls: 'cloud-computing-private-cloud',
				}, {
					title: i18nCloudNativeContainerStorage('banner.title'),
					subTitle: i18nCloudNativeContainerStorage('banner.subTitle'),
					path: routes.CloudNativeContainerStorage,
					imgCls: 'cloud-native-container-storage',
				}, {
					title: i18nDataBackup('banner.title'),
					subTitle: i18nDataBackup('banner.subTitle'),
					path: routes.DataBackup,
					imgCls: 'data-backup',
				},
			],
			industry: [
				{
					title: i18nNewEnergyVehicle('banner.title'),
					subTitle: i18nNewEnergyVehicle('banner.subTitle'),
					path: routes.NewEnergyVehicle,
					imgCls: 'new-energy-vehicle',
				}, {
					title: i18nIntelligentCampus('banner.title'),
					subTitle: i18nIntelligentCampus('banner.subTitle'),
					path: routes.IntelligentCampus,
					imgCls: 'intelligent-campus',
				}, {
					title: i18nIntelligentSecurity('banner.title'),
					subTitle: i18nIntelligentSecurity('banner.subTitle'),
					path: routes.IntelligentSecurity,
					imgCls: 'intelligent-security',
				}, {
					title: i18nAI('banner.title'),
					subTitle: i18nAI('banner.subTitle'),
					path: routes.AI,
					imgCls: 'ai',
				},
			],
		},
		iot: {
			intelligentPolice: [
				{
					title: i18nBrightProject('banner.title'),
					subTitle: i18nBrightProject('banner.subTitle'),
					path: routes.BrightProject,
					imgCls: 'bright-project',
				}, {

					title: i18nMajorEventSecurity('banner.title'),
					subTitle: i18nMajorEventSecurity('banner.subTitle'),
					path: routes.MajorEventSecurity,
					imgCls: 'major-event-security',
				},
			],
			intelligentCommunity: [
				{
					title: i18nIntelligentCommunity('banner.title'),
					subTitle: i18nIntelligentCommunity('banner.subTitle'),
					path: routes.IntelligentCommunity,
					imgCls: 'intelligent-community',
				}, {
					title: i18nIntelligentCommunitySecurity('banner.title'),
					subTitle: i18nIntelligentCommunitySecurity('banner.subTitle'),
					path: routes.IntelligentCommunitySecurity,
					imgCls: 'intelligent-community-security',
				}, {
					title: i18nIntelligentElevator('banner.title'),
					subTitle: i18nIntelligentElevator('banner.subTitle'),
					path: routes.IntelligentElevator,
					imgCls: 'intelligent-elevator',
				}, {
					title: i18nHighAltitudeThrowObject('banner.title'),
					subTitle: i18nHighAltitudeThrowObject('banner.subTitle'),
					path: routes.HighAltitudeThrowObject,
					imgCls: 'high-altitude-throw-object',
				},
			],
			intelligentPark: [
				{

					title: i18nIntelligentPark('banner.title'),
					subTitle: i18nIntelligentPark('banner.subTitle'),
					path: routes.IntelligentPark,
					imgCls: 'intelligent-park',
				},
			],
			intelligentScenicSpot: [
				{
					title: i18nIntelligentScenicSpot('banner.title'),
					subTitle: i18nIntelligentScenicSpot('banner.subTitle'),
					path: routes.IntelligentScenicSpot,
					imgCls: 'intelligent-scenic-spot',
				}, {
					title: i18nAllAreaTourism('banner.title'),
					subTitle: i18nAllAreaTourism('banner.subTitle'),
					path: routes.AllAreaTourism,
					imgCls: 'all-area-tourism',
				},
			],
			carbonNeutrality: [
				{
					title: i18nCarbonNeutrality('banner.title'),
					subTitle: i18nCarbonNeutrality('banner.subTitle'),
					path: routes.CarbonNeutrality,
					imgCls: 'carbon-neutrality',
				},
			],
			intelligentGarden: [
				{
					title: i18nIntelligentGarden('banner.title'),
					subTitle: i18nIntelligentGarden('banner.subTitle'),
					path: routes.IntelligentGarden,
					imgCls: 'intelligent-garden',
				},
			],
			emergency: [
				{
					title: i18nEmergencyCommand('banner.title'),
					subTitle: i18nEmergencyCommand('banner.subTitle'),
					path: routes.EmergencyCommand,
					imgCls: 'emergency-command',
				}, {
					title: i18nSafetyProductionEmergency('banner.title'),
					subTitle: i18nSafetyProductionEmergency('banner.subTitle'),
					path: routes.SafetyProductionEmergency,
					imgCls: 'safety-production-emergency',
				}
			],
			general: [
				{
					title: i18nIoTPlatform('banner.title'),
					subTitle: i18nIoTPlatform('banner.subTitle'),
					path: routes.IoTPlatform,
					imgCls: 'iot-platform',
				}
			],
		},
	}), [
		// Storage solution.
		// General category.
		i18nBigData,
		i18nHighPerformanceComputing,
		i18nCloudComputingPrivateCloud,
		i18nCloudNativeContainerStorage,
		i18nDataBackup,
		// Industry category.
		i18nNewEnergyVehicle,
		i18nIntelligentCampus,
		i18nIntelligentSecurity,
		i18nAI,
		// IoT solution.
		// Intelligent Police category.
		i18nBrightProject,
		i18nMajorEventSecurity,

		// Intelligent community category category.
		i18nIntelligentCommunity,
		i18nIntelligentCommunitySecurity,
		i18nIntelligentElevator,
		i18nHighAltitudeThrowObject,

		// Intelligent park category.
		i18nIntelligentPark,

		// Intelligent scenic spot category.
		i18nIntelligentScenicSpot,
		i18nAllAreaTourism,

		// Carbon neutrality category,
		i18nCarbonNeutrality,

		// Intelligent garden category.
		i18nIntelligentGarden,

		// Emergency category.
		i18nEmergencyCommand,
		i18nSafetyProductionEmergency,

		// General category.
		i18nIoTPlatform,
	]);
}
