import React, {FC, MouseEvent, useCallback, useRef, useState} from 'react';
import {useAccessStatistics, useI18n, useSelector} from '@hooks';
import {OOSModal} from '@components';
import {base64, ckGet, equals, validateEmail, validateMobilePhone} from '@utils';
import httpCaller from '@http/caller';
import {State} from '@redux/interfaces';
import {handleFetchExceptionMsg} from '@http/fetch/httpHelper';
import routes from '@routes';

interface SignUpProps {
	visible: boolean;
	hide: Function;
	showSignIn: Function;
}

interface SelectedState {
	windowWidth: number;
}

const SignUp: FC<SignUpProps> = ({
	visible: pVisible,
	hide: pHide,
	showSignIn: pShowSignIn
}) => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('SignUp');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {windowWidth}} = state;
		return {windowWidth};
	}, []);

	const {windowWidth} = useSelector<State, SelectedState>(selectState, equals);

	// Internal states.
	const [loading, setLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);

	// Ref data.
	const fullnameInputRef = useRef<HTMLInputElement | null>(null);
	const phoneInputRef = useRef<HTMLInputElement | null>(null);
	const emailInputRef = useRef<HTMLInputElement | null>(null);
	const passwordInputRef = useRef<HTMLInputElement | null>(null);
	const rePasswordInputRef = useRef<HTMLInputElement | null>(null);
	const agreementCheckboxRef = useRef<HTMLInputElement | null>(null);

	// Page actions.
	const onShowSignInClick = useCallback(() => {
		pHide();
		pShowSignIn();
	}, [pHide, pShowSignIn]);

	const onSubmitClick = useCallback(async (e: MouseEvent<HTMLDivElement>) => {
		// Prevent the form default behavior.
		e.preventDefault();

		if (!loading) {
			// Trim input values.
			let fullname = fullnameInputRef.current ? fullnameInputRef.current.value.trim() : '';
			let phone = phoneInputRef.current ? phoneInputRef.current.value.trim() : '';
			let email = emailInputRef.current ? emailInputRef.current.value.trim() : '';
			let password = passwordInputRef.current ? passwordInputRef.current.value.trim() : '';
			let rePassword = rePasswordInputRef.current ? rePasswordInputRef.current.value.trim() : '';
			let readAgreement = agreementCheckboxRef.current ? agreementCheckboxRef.current.checked : false;

			// Check form validation.
			if (!fullname) {
				return alert(i18n('validation.fullnameEmpty'));
			}
			if (!validateMobilePhone(phone)) {
				return alert(i18n('validation.phoneIncorrect'));
			}
			if (!validateEmail(email)) {
				return alert(i18n('validation.emailIncorrect'));
			}
			if (password.length < 6 || password.length > 12) {
				return alert(i18n('validation.passwordIncorrect'));
			}
			if (rePassword.length < 6 || rePassword.length > 12) {
				return alert(i18n('validation.rePasswordIncorrect'));
			}
			if (password !== rePassword) {
				return alert(i18n('validation.passwordReInputIncorrect'));
			}
			if (!readAgreement) {
				return alert(i18n('validation.agreementNotReadAndAgree'));
			}

			setLoading(true);

			let user = {
				fullname,
				phone,
				email,
				password: base64(password),
			};

			try {
				await httpCaller.signUp(user);
				setLoading(false);
				setShowSuccess(true);
			} catch (e) {
				setLoading(false);
				alert(i18n('actionFails') + handleFetchExceptionMsg(e));
			}
		}
	}, [loading, i18n]);

	const onHideClick = useCallback(() => {
		if (!loading) {
			setShowSuccess(false);
			pHide();
			let isSignedIn = ckGet('oos_token');
			// If there is a signed-in user, means this hide action is triggered by sign
			// up action.
			if (isSignedIn) {
				// Will do an automatically sign-in after user is successfully signed-up.
				// So a sample way to sync the sign in status is just reload the page.
				window.location.reload();
			}
		}
	}, [pHide, loading]);

	// Access Statistics.
	useAccessStatistics(routes.SignUp, pVisible);

	return pVisible ? (
		<OOSModal
			width={
				windowWidth > 450 ?
					(!showSuccess ? 490 : 340) :
					(!showSuccess ? 340 : 300)
			}
		>
			{
				!showSuccess ? (
					<>
						<div className="oos-modal-hide-button-box">
							<div className="oos-modal-hide-button" onClick={onHideClick} />
						</div>
						<div className="oos-modal-title">{i18n('title')}</div>
						{
							pVisible && (
								<div className="oos-modal-form-content">
									<form autoComplete="off">
										<div className="oos-modal-form-item">
											<div className="label-img fullname" />
											<input type="text" maxLength={20} ref={fullnameInputRef} placeholder={i18n('fullnamePlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img phone" />
											<input type="text" maxLength={11} ref={phoneInputRef} placeholder={i18n('phonePlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img email" />
											<input type="text" maxLength={50} ref={emailInputRef} placeholder={i18n('emailPlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img password" />
											<input type="password" ref={passwordInputRef} autoComplete="new-password" placeholder={i18n('passwordPlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img password" />
											<input type="password" ref={rePasswordInputRef} autoComplete="new-password" placeholder={i18n('rePasswordPlh')} />
										</div>
										<div className="confirm-service-agreement">
											<input id="agreement-checkbox" type="checkbox" ref={agreementCheckboxRef} />
											<label htmlFor="agreement-checkbox">
												<span className="read-agreement">{i18n('readAndAgree')}</span>
												<a className="agreement-name" target="_blank" rel="noopener noreferrer" href="/service_agreement.html">{i18n('serviceAgreementName')}</a>
											</label>
										</div>
										<div
											className={`submit-button ${loading ? 'disabled' : ''}`}
											onClick={onSubmitClick}
										>
											{!loading ? i18nGen('ok') : i18n('signingUp')}
										</div>
									</form>
								</div>
							)
						}
						<div className="oos-modal-form-switch-action">
							<span className="switch-tips">{i18n('hasAccount')}</span>
							<span
								className="switch-action-link"
								onClick={onShowSignInClick}
							>
								{i18n('forwardSignIn')}
								<i className="oos-button-link-icon">→</i>
							</span>
						</div>
					</>
				) :
				(
					<div className="oos-modal-success-tips-box">
						<div className="success-img" />
						<div className="success-title">{i18n('actionOk')}</div>
						<div className="success-desc">{i18n('actionOkTips')}</div>
						<div className="success-ok-button" onClick={onHideClick}>{i18nGen('ok')}</div>
					</div>
				)
			}
		</OOSModal>
	) :
	null;
}

export default SignUp;
