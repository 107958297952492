const zhCN = {
	documentTitle: '应急指挥解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '应急指挥解决方案',
		subTitle: '应急救援管理系统的建设主要以“安全第一、预防为主、综合治理”为理念，以“平战结合”为指导思想，事故前、事故中、事故后全方位监管安全生产。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		lackOfPreparation: {
			title: '预防与准备不足',
			desc: '对应急资源、应急队伍、重大危险源和重要防护目标等基本信息的维护停留在线下台账上，线上与线下有差异，导致应急预案有遗漏。',
		},
		monitorUntimely: {
			title: '监测与预警不及时',
			desc: '重大危险源、风险点的状态和动态变化情况不易掌握，导致不能及时、准确预警并发布到公众和应急责任人。',
		},
		hardToCoordinate: {
			title: '应急协商难、效率低',
			desc: '如何准确获取和传递现场信息，突破最后一公里瓶颈，面对跨领域、跨层级、跨时空重大突发事件，需要解决快速协同会商、综合协调和高效处置等问题和难点。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '应急指挥解决方案介绍',
			desc: '应急救援管理系统的建设主要以“安全第一、预防为主、综合治理”为理念，以“平战结合”为指导思想，事故前、事故中、事故后全方位监管安全生产。事故前对企业的预案和应急资源进行管理，对员工进行演练培训，为事故的应急响应做准备。事故中针对发生的事故自动生成匹配预案，对重大事故应急救援过程辅助决策支持，进行应急资源和应急救援队伍的调度，对事故作出快速响应。事故后，对已发生的事故进行调查分析、整理归档、完善预案，帮助日后的救援更加快速高效。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		alarmQuickly: {
			title: '迅速及时高效',
			desc: '支持多种方式接警，包括电话、短信、传真、集群以及智能终端的接入， 满足不同情况下的快速报警，提升响应速度；',
		},
		earlyWarning: {
			title: '智能分析',
			desc: '支持视频智能分析能力的集成：能够进行目标识别、 趋势识别、 行为分析、 关联分析，实现智能分析；',
		},
		unifiedScheduling: {
			title: '统一联动',
			desc: '支持多种媒体通信方式，通过电话语音调度、通过短信、传真报送警情， 以及PTT下达指令，保证随时随地进行决策和指挥。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
