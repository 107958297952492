import {useEffect, useRef} from 'react';

export default function useInterval (
	callback: Function,
	delay: number | null,
	once: boolean = false
){
	const callbackRef = useRef<Function>();

	useEffect(() => void (callbackRef.current = callback), [callback]);

	useEffect(() => {
		const tick = () => {
			if (callbackRef.current !== void 0) {
				callbackRef.current();
			}
		}

		if (delay !== null) {
			const id = window.setInterval(tick, delay);

			return () => {
				window.clearInterval(id);
			};
		} else {
			if (once) {
				tick();
			}
		}
	}, [delay, once]);
}
