import React, {FC, Suspense, useRef} from 'react';
import * as THREE from 'three';
import {Canvas} from '@react-three/fiber';
import {TrackballControls} from '@react-three/drei';
import Globe from './3DComponent/Globe';

interface SourceGlobeProps {
	onGlobeReady: () => void;
}

const SourceGlobe: FC<SourceGlobeProps> = ({
	onGlobeReady: pOnGlobeReady
}) => {
	const cameraRef = useRef<THREE.Camera>();

	return (
		<div className="source-globe">
			<Suspense fallback={null}>
				<Canvas
					linear
					gl={{
						antialias: true,
						toneMapping: THREE.NoToneMapping,
					}}
					dpr={window.devicePixelRatio}
					camera={{
						ref: () => cameraRef,
						position: [200, 50, -50]
					}}
				>
					<ambientLight color="#fff" />
					<TrackballControls
						camera={cameraRef.current}
						minDistance={200}
						rotateSpeed={1}
						zoomSpeed={0.8}
						noPan
						noZoom
					/>
					<Globe onGlobeReady={pOnGlobeReady} />
				</Canvas>
			</Suspense>
		</div>
	);
};

export default SourceGlobe;
