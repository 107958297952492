const zhCN = {
	documentTitle: '新能源汽车解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '新能源汽车解决方案',
		subTitle: '公安部交通管理科学研究所根据国家重点研发计划“新能源汽车安全风险精准管控技术及应用”，需要搭建新能源汽车监控信息跨部门跨网络交换平台，用于交换工信部新能源汽车监控数据和公安部车辆管控数据。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		lowReliability: {
			title: '可靠性低，无法保证数据安全',
			desc: '存储可靠性低，客户的数据安全性无法保证。',
		},
		highCost: {
			title: '设备种类繁多，功耗和成本大',
			desc: '存储容量大，设备种类多样，需要采购多种设备，功耗和成本极大。',
		},
		hardToManage: {
			title: '管理复杂',
			desc: '人工管理成本高，出错率大，仅从单一角度分析管理数据。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '新能源汽车解决方案介绍',
			desc: '针对这些需求，奥卡云采用OrcaBD分布式存储，提供PB级的统一数据存储空间，解决数据的存储管理和容量要求。采用EC纠删码技术，解决数据安全和可靠性问题。采用智能分层技术，解决客户结构化/非结构化数据交换时间要求，以及跨平台交换数据性能要求。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		highReliability: {
			title: '高可靠',
			desc: '采用EC纠删码数据冗余机制，提高客户的数据安全性和可靠性。。',
		},
		lowCost: {
			title: '低成本',
			desc: '使用HDD节省的钱购买SSD性能，同时减少设备采购数量，降低功耗和不必要的设备采购成本。',
		},
		intelligentManagement: {
			title: '智能管理',
			desc: '统一GUI管理界面，实时硬件状态监控、性能监控，硬盘寿命预测，容量性能智能预警等特性，完全解放运维人员，降低人为误操作风险。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
