const zhCN = {
	documentTitle: '售后案例中心 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '售后案例中心',
		subTitle: '包含产品交付、运维工作经验等有关的案例总结',
	},
	searchPlaceholder: '请输入案例名称进行搜索',
	afterSalesCase: '案例',
	productFilter: '产品：',
	versionFilter: '版本：',
	name: '名称',
	authRequired: '该案例需要对应的用户权限才能浏览和下载',
	publishTime: '发布/更新时间',
	actions: '操作',
	downloadAfterSalesCase: '下载案例',
	afterSalesCaseEmpty: '相关案例即将上线',
	afterSalesCaseSearchEmpty: '暂无搜索结果',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
