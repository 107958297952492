import React, {FC, useCallback, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import generalAction from '@actions/generalAction';
import {useDispatch, useI18n, useLocation, useNavigate} from '@hooks';
import routes from '@routes';

interface ProductDropDownMenuProps {
	visible: boolean;
}

const ProductDropDownMenu: FC<ProductDropDownMenuProps> = ({
	visible: pVisible,
},) => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18nUniIO = useI18n('UniIO');
	const i18nOrcaBD = useI18n('OrcaBD');
	const i18nOrcaFS = useI18n('OrcaFS');

	// Router.
	const location = useLocation();
	const navigate = useNavigate();

	// Redux related.
	const dispatch = useDispatch();

	const setActiveSubmenu = useCallback((activeSubmenu: string) => dispatch(generalAction.setActiveSubmenu(activeSubmenu)), [dispatch]);

	// Ref data.
	const topBarDomRef = useRef<HTMLElement | null>(null);

	// Page actions.
	const onSolutionMenuClick = useCallback((path: string) => {
		if (location.pathname !== path) {
			navigate(path);
			setActiveSubmenu('');
		}
	}, [location, navigate, setActiveSubmenu]);

	// After rendering and re-rendering.
	useEffect(() => {
		// Get portal container DOM.
		topBarDomRef.current = document.getElementById('oos-top-bar');

		return () => {
			topBarDomRef.current = null;
		};
	}, []);

	return topBarDomRef.current ?
		createPortal((
			<div className={`product-drop-down-menu ${pVisible ? 'active' : ''}`}>
				<div className="menu-box">
					<div
						className="menu-item"
						onClick={onSolutionMenuClick.bind(null, routes.UniIO)}
					>
						<div className="title">{i18nUniIO('banner.title')}</div>
						<div className="sub-title">{i18nUniIO('banner.subTitle')}</div>
						<div className="view-detail-button">
							{i18nGen('viewDetail')}
							<i className="oos-button-link-icon">→</i>
						</div>
						<i className="bg uniio" />
					</div>
					<div
						className="menu-item"
						onClick={onSolutionMenuClick.bind(null, routes.OrcaFS)}
					>
						<div className="title">{i18nOrcaFS('banner.title')}</div>
						<div className="sub-title">{i18nOrcaFS('banner.subTitle')}</div>
						<div className="view-detail-button">
							{i18nGen('viewDetail')}
							<i className="oos-button-link-icon">→</i>
						</div>
						<i className="bg orcafs" />
					</div>
					<div
						className="menu-item"
						onClick={onSolutionMenuClick.bind(null, routes.OrcaBD)}
					>
						<div className="title">{i18nOrcaBD('banner.title')}</div>
						<div className="sub-title">{i18nOrcaBD('banner.subTitle')}</div>
						<div className="view-detail-button">
							{i18nGen('viewDetail')}
							<i className="oos-button-link-icon">→</i>
						</div>
						<i className="bg orcabd" />
					</div>
				</div>
			</div>
		), topBarDomRef.current) :
		null;
};

export default ProductDropDownMenu
