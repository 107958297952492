import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useI18n, useSelector} from '@hooks';
import {equals} from '@utils';
import {DeviceMode} from '@interfaces';
import {State} from '@redux/interfaces';

interface SelectedState {
	deviceMode: DeviceMode;
}

const Partner: FC = () => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('Home', 'partner');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {deviceMode}} = state;
		return {deviceMode};
	}, []);

	const {deviceMode} = useSelector<State, SelectedState>(selectState, equals);

	// Internal states.
	const [showMore, setShowMore] = useState(false);

	// Page actions.
	const onShowMoreClick = useCallback(() => {
		setShowMore(true);
	}, []);

	// After rendering and re-rendering.
	useEffect(() => {
		setShowMore(false);
	}, []);

	// Memorized data.
	const PartnerStyleMemo = useMemo(() => {
		let display;

		if (deviceMode === 'pc') {
			display = 'flex';
		} else {
			if (showMore) {
				display = 'flex';
			} else {
				display = 'none';
			}
		}

		return {display};
	}, [deviceMode, showMore]);

	return (
		<>
			<div className="section-title gray-bg">
				<div className="title">{i18n('title')}</div>
				<div className="sub-title">{i18n('subTitle')}</div>
			</div>
			<div className="partner">
				<div className="partner-content-wrap">
					<div className="partner-box">
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.sust.edu.cn">
							<div className="partner-logo sxkjdx" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="http://www.tmri.cn">
							<div className="partner-logo gabjtyjs" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.huawei.com">
							<div className="partner-logo hw" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="http://www.wxiat.com">
							<div className="partner-logo wxxjjsyjs" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.unisinsight.com">
							<div className="partner-logo zghz" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.nwsuaf.edu.cn">
							<div className="partner-logo xbnlkjdx" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="http://www.xiXiAnjituan.com/gaikuang/qiye/sybzgs/19.html">
							<div className="partner-logo xxzc" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.istic.ac.cn">
							<div className="partner-logo zgkjbkxjsyjs" />
						</a>
					</div>
					<div className="partner-box" style={PartnerStyleMemo}>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.sugon.com">
							<div className="partner-logo zksg" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="http://www.chinatelecom.com.cn">
							<div className="partner-logo zgdx" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.inspur.com/">
							<div className="partner-logo lc" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.digitalchina.com">
							<div className="partner-logo szsm" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.hikvision.com">
							<div className="partner-logo hkws" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.phytium.com.cn">
							<div className="partner-logo ft" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.nwpu.edu.cn">
							<div className="partner-logo xbgydx" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.lenovo.com">
							<div className="partner-logo lenovo" />
						</a>
					</div>
					<div className="partner-box" style={PartnerStyleMemo}>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.h3c.com">
							<div className="partner-logo h3c" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.alibabagroup.com">
							<div className="partner-logo alibaba" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="http://www.jingrongshuan.com">
							<div className="partner-logo jrsa" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.fangcloud.com">
							<div className="partner-logo yfy" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.hikunpeng.com">
							<div className="partner-logo kp" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.chinaccs.cn">
							<div className="partner-logo zgtxfw" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.intel.com">
							<div className="partner-logo intel" />
						</a>
						<a className="partner-item" target="_blank" rel="noreferrer" href="https://www.westerndigital.com">
							<div className="partner-logo xbsj" />
						</a>
					</div>
					<div className={`show-more-button-box ${showMore ? 'hide' : ''}`}>
						<div
							className="show-more-button"
							onClick={onShowMoreClick}
						>
							{i18nGen('viewMore')}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Partner;
