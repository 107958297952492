const zhCN = {
	title: '登录',

	phonePlh: '请输入账号（注册账号时输入的手机号码）',
	passwordPlh: '请输入密码',
	verificationCodePlh: '请输入验证码',
	clickToRefresh: '点击刷新',

	validation: {
		phoneIncorrect: '请输入正确的账号！',
		passwordIncorrect: '请输入正确的密码，长度为6-12位！',
		verificationCodeIncorrect: '请输入验证码！',
	},

	signingIn: '登录中...',
	hasNoAccount: '没有账号？',
	forwardSignUp: '立即注册',
	forgetPassword: '忘记密码',
	actionOk: '登录成功',
	actionOkTips: '感谢您登录奥卡云官网，您已可申请试用我们的产品。',
	actionFails: '登录失败，原因：',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
