import React, {FC, useCallback, useEffect, useState} from 'react';
import modalAction from '@actions/modalAction';
import {useDispatch, useI18n} from '@hooks';
import {ckGet, DOM} from '@utils';

interface FloatBarProps {
	visible: boolean;
}

const FloatBar: FC<FloatBarProps> = ({
	visible:  pVisible
}) =>{
	// i18n.
	const i18nGen = useI18n('General');

	// Redux related.
	const dispatch = useDispatch();

	const setSignInVisible = useCallback((visible: boolean) => dispatch(modalAction.setSignInVisible(visible)), [dispatch]);

	const setApplyTrialVisible = useCallback((visible: boolean) => dispatch(modalAction.setApplyTrialVisible(visible)), [dispatch]);

	// Internal states.
	const [isSignedIn, setIsSignedIn] = useState<boolean>(false);

	// Page actions.
	const onScrollToTop = useCallback(() => {
		// DOM.scrollTo(0);
		DOM.getHTML().scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest'
		});
	}, []);

	const onApplyTrialClick = useCallback(() => {
		if (!isSignedIn) {
			alert(i18nGen('doSignInFirst'));
			setSignInVisible(true)
		} else {
			setApplyTrialVisible(true);
		}
	}, [isSignedIn, setApplyTrialVisible, setSignInVisible, i18nGen]);

	useEffect(() => {
		setIsSignedIn(!!ckGet('oos_token'));
	}, []);

	return (
		<div className={`oos-float-bar ${pVisible ? 'visible' : ''}`}>
			<div
				className="scroll-to-top"
				onClick={onScrollToTop}
			>
				<i className="arrow" />
			</div>
			<div
				className="apply-trial"
				onClick={onApplyTrialClick}
			>
				试用
			</div>
		</div>
	);
}

export default FloatBar;
