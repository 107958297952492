const zhCN = {
	documentTitle: '高空抛物解决方案  奥卡云数据 - 存储 物联网 解决方',
	banner: {
		title: '高空抛物解决方案',
		subTitle: '奥卡云高空抛物检测与预警系统基于多种先进的图像处理技术和机器视觉算法，能有效可靠地检测常见的抛物现象并抓取抛物图片和视频。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		hardToSupervise: {
			title: '高空抛物事件频发，难以监管',
			desc: '高空抛物事件，由于发生速度快，经常不超过几秒，识别困难，监管难度大。',
		},
		hardToGetEvidence: {
			title: '事后调查取证难，无依据',
			desc: '发生高空抛物事件后，事后定责时需要定位抛物所在楼层和方位，仅靠目击者证明不准确，且缺乏法律依据。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '高空抛物解决方案介绍',
			desc1: '奥卡云高空抛物检测与预警系统基于多种先进的图像处理技术和机器视觉算法，对多路视频流数据进行背景建模、前景物体检测、物体跟踪检测等，实时对高空抛物现象进行识别，能有效可靠地检测常见的抛物现象并抓取抛物图片和视频。',
			desc2: '除了视频实时预览，平台还支持高空抛物实时检测与预警，对触发的预警，进行高空抛物事件查询及处理，平台自带视频质量自动诊断，有视频图像卡顿等情况出现时，及时预警，以便维修人员进行快速处理。支持对录像存储状态进行检测分析。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		accurateMonitoring: {
			title: '监测准确',
			desc: '拥有高效、准确的高空抛物检测算法，实现计划检测、事件录像，避免事后从海量视频录像中查找几秒的事件过程费时费力。',
		},
		automaticAlarm: {
			title: '异常自动提醒',
			desc: '系统内置图像质量检测算法，可实时分析视频画面的成像效果，当画面出现异常时及时通知维修人员，确保监控画面以及存储录像的有效性。',
		},
		strongAdaptability: {
			title: '适应性强',
			desc: '对复杂环境适应能力强，算法支持白天、黑夜、刮风、下雨等不同场景下的高空抛物事件检测。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
