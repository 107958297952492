const zhCN = {
	documentTitle: '人工智能解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '人工智能解决方案',
		subTitle: '人工智能（Artificial Intelligence），英文缩写为AI。它是研究、开发用于模拟、延伸和扩展人的智能的理论、方法、技术及应用系统的一门新的技术学科。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		dataMining: {
			title: '数据挖掘与分析',
			desc: '面临大数据深度挖掘与分析时，通常采用机器学习，是基于人工神经网络的深度学习。可针对海量数据进行分析计算，并创建相应模型。',
		},
		dataProcess: {
			title: '知识和数据处理能力',
			desc: '知识处理时通常使用专家技术，它能运用特定领域中专家提供的专门知识和经验，通过智能推理求和，便可解决只有专家才能解决的问题。',
		},
		lowReliability: {
			title: '数据安全可靠性低',
			desc: '主要运用到的技术包括机器人学习和模式识别技术，机器人可以模拟人的行为，而模式识别则能使用计算机模拟人类器官对外界的各种感知。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: 'OrcaFS人工智能解决方案',
			desc1: '人工智能企图了解智能的本质，并生产出一种新的能以人类智能相似的方式做出反应的智能机器，该领域的研究包括机器人、语言识别、图像识别、自然语言处理和专家系统等。人工智能是对人的意识、思维的信息过程的模拟。人工智能不是人的智能，但能像人那样思考、也可能超过人的智能。可分为弱人工智能、通用人工智能、强人工智能这三种类型。涉及工业4.0、无人驾驶汽车、智能家居、智能金融、智慧医疗、智能营销、智能教育和智能农业等领域。',
			desc2: '人工智能生态逐步形成：基础资源+技术+应用的模式。其产业生态分为三层基本架构，基础资源层：主要是计算平台和数据中心，属于计算智能；技术层：通过机器学习建模，开发面向不同领域的算法和技术，包含感知智能和认知智能；应用层：主要实现人工智能在不同场景下的应用。',
			desc3: '人工智能对数据的采集、分析和处理能力直接决定着其发展前景，而这些也是对各类软硬件提出了更高的要求。OrcaFS分布式并行文件存储，采用针对高性能计算的HPC Burst Buffer技术，在人工智能领域的数据处理方面有着绝对性优势。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		highPerformance: {
			title: '高性能低时延',
			desc: '高效的并行访问能力和高效的突发缓存系统，在提供高性能的同时，将时延降到最低。',
		},
		highReliability: {
			title: '数据高可靠性',
			desc: '支持节点之间副本机制，节点内部支持RAID5、RAID6。提高数据安全、减少数据丢失。',
		},
		hardwareIndependence: {
			title: '无硬件依赖',
			desc: '采用低成本的通用商用硬件，无任何硬件或内核依赖、简单易用。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '优质的方案实施与客户服务案例',
		laboratory: {
			title: 'XXX大学智能实验室项目',
			introduction: '2019年4月份，陕西省某市的XXX大学针对学校的智能化实验室发展现状和未来发展远景，决定面向外界采购相关设备以满足学校的人工智能发展。奥卡云在得知该校情况后，多次跟校方项目负责人进行沟通。对学校的智能化实验室项目有了深入了解，奥卡云从人工智能化现状、存在的问题，以及客户关注的方向，结合学校的现有资源及现状给出了自己的一整套解决方案。在整个项目的前期、实施、后期维护等全过程，奥卡云都做到想客户所想，做客户所做。充分发挥厂商的专业技术和过硬的产品质量，获得客户的高度认可。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
