const zhCN = {
	title: '重置密码',

	phonePlh: '请输入账号（注册账号时输入的手机号码）',
	emailPlh: '请输入注册邮箱地址',
	sendVerifyCode:  '发送验证码',
	verifyCodePlh: '请输入注册邮箱收到的验证码',
	passwordPlh: '请输入新密码，长度为6-12位',
	rePasswordPlh: '请输入确认新密码，长度为6-12位',

	validation: {
		phoneIncorrect: '请输入正确的账号！',
		emailIncorrect: '请输入正确的注册邮箱地址！',
		verifyCodeIncorrect: '请输入正确的验证码！',
		passwordIncorrect: '请输入正确的新密码，长度为6-12位！',
		rePasswordIncorrect: '请输入正确的确认新密码，长度为6-12位！',
		passwordReInputIncorrect: '两次输入的新密码不一致，请修改正确！',
	},

	resetting: '重置中...',
	sendVerifyCodeOk: '发送验证码成功，请检查您的邮箱！',
	sendVerifyCodeFails: '发送验证码失败，原因：',
	actionOk: '重置密码成功',
	actionOkTips: '请您使用新的密码重新登录。',
	actionFails: '重置密码失败，原因：',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
