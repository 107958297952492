const zhCN = {
	title: '申请试用',

	fullnamePlh: '请输入您的姓名',
	phonePlh: '请输入您的手机号码',
	emailPlh: '请输入您的邮箱地址',
	firmPlh: '请输入您公司或组织的名称，长度为30字以内（选填）',
	jobPlh: '请输入您的职务，长度为30字以内（选填）',
	requirementsPlh: '请输入您的试用需求简述（想进行试用的产品或解决方案的名称、用途、时长等信息），长度为200字以内（选填）',

	validation: {
		fullnameEmpty: '请输入您的姓名！',
		phoneIncorrect: '请输入正确的手机号！',
		emailIncorrect: '请输入正确的邮箱地址！',
	},

	applicationSending: '申请发送中...',
	actionOk: '申请发送成功',
	actionOkTips: '感谢您申请试用奥卡云的产品，我们的工作人员会在您的申请信息审核完后联系您，请保持联系方式的畅通。',
	actionFails: '试用申请发送失败，原因：',
};

const enUS = {};


const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
