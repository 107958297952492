const zhCN = {
	documentTitle: '全域旅游解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '全域旅游解决方案',
		subTitle: '奥卡云全域旅游综合管理服务平台是集数据监测、数据分析、行业管理、应急指挥、公共服务及行业服务于一体，全面提升旅游信息化管理、服务的综合平台。',
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '全域旅游解决方案介绍',
			desc1: '2018年4月国家文化和旅游部挂牌成立，明确了“宜融则融，能融尽融，以文促旅，以旅彰文”的工作思路。文旅产业是国家政治、经济、民生发展的重头戏，是稳增长、调结构、扩内需的重要引擎和动能。文化+旅游产业是国家的战略支柱产业，到2020年，将达到15万亿（人民币），将占国家经济总量的文化旅游融合发展已是大势所趋，文化旅游相互促进、相互融合迎来了历史性的黄金发展期和机遇期。',
			desc2: '奥卡云全域旅游综合管理服务平台是集数据监测、数据分析、行业管理、应急指挥、公共服务及行业服务于一体，全面提升旅游信息化管理、服务的综合平台。平台以文旅大数据为核心，为政府管理者提供文旅行业综合管理及决策分析；为游客提供“一机游”服务，实现游客体验自由自在、政府服务无处不在。',
			desc3: '全域旅游综合管理服务平台建设体系为“1+2+4+N”，即1个中心（文旅大数据中心），两个体系（旅游管理体系、旅游服务体系），4个平台（行业管理、应急指挥、公共服务、行业服务），N个细分应用子系统。产品针对具体客户需求提供专属定制化服务。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		helpForAcceptance: {
			title: '助力验收',
			desc: '符合全域旅游示范区验收标准，实施后可帮助客户在全域旅游示范区验收过程中信息化相关部分不丢分，并突出建设成果。',
		},
		bigDataSupport: {
			title: '大数据支持',
			desc: '融合多业务系统数据，通过大数据分析，可生成节假日客流、旅游收入等专题报告，从事中的被动应对做到事前的主动预测，为管理部门决策提供数据支持。',
		},
		improveServiceLevel: {
			title: '提升服务水平',
			desc: '通过系统化、专业化的平台，全面、实时地感知行业动态，提升服务水平。',
		},
		promoteConsumption: {
			title: '促进旅游消费',
			desc: '整合区域内旅游资源，通过公共服务平台为游客提供旅游资讯、商品等信息，促进域内旅游消费。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		qinHanNewCity: {
			title: '秦汉新城全域旅游建设',
			introduction: '秦汉新城位于西咸新区的几何中心，总规划面积302平方公里，其中城市建设用地约50平方公里，遗址保护区104平方 公里，自贸片区10.85平方公里，下辖6个街办（镇）。提出需要“依托区内丰富的历史文化资源，统筹兼顾历史遗产的保护与开发利用，通过“政府引导，市场运作，项目带动，创新引领”的方式，围绕“秦”、“汉”两大文化主题，利用创新技术打造秦汉新城全域旅游。”作为本期全域旅游的建设目标。',
			point1: '全面提升涉旅场所的信息化水平，打造全面面向公众的公共服务平台',
			point2: '全面提升基础涉旅数据收集能力、打造智能化大数据中心',
			point3: '创新5G+创新服务、树立全域旅游示范样板',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
