const zhCN = {
	documentTitle: '物联网平台解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '物联网平台解决方案',
		subTitle: '奥卡云自主研发的物联网应用平台，可部署在公有云、私有云等云平台，集设备管理、智能物联、便捷基础运维、数据分析系统于一体。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		similarFeature: {
			title: '功能相似，重复建设',
			desc: '各个系统数据大量重复，比如基础的人员数据，区域数据，角色权限数据，都可以复用同一套，但每次都需要单独开发，重复工作导致浪费开发资源。',
		},
		dataRedundancy: {
			title: '数据冗余，缺少沉淀',
			desc: '物联网平台拥有各种数据，就拿最主要的设备数据来说，就有几十种设备类型，每个类型的设备，根据不同厂商，不同规格，又会有不同的型号和相应参数，导致整个系统的数据非常冗余和繁杂，缺少沉淀。',
		},
		hardToInterwork: {
			title: '传感器产品难互通，智能化程度不够',
			desc: '传感器自身的分类本就很复杂，例如触摸传感器、图像传感器、温度传感器、运动传感器、位置传感器、气体传感器、光线传感器和压力传感器等等，且智能化程度低。种类的复杂性也使得多传感器之间互通难，仍需要方案商投入较大的精力去做开发。',
		},
		longChain: {
			title: 'IoT链条长，需要产业链合作',
			desc: '物联网行业种类之复杂、设备以及环节之多、规模以及数据量之大，都成为了物联网发展的难点。一个完整的物联网方案需要兼具各层级的功能，很多功能还需要打通产业链完成，形成资源共享，只有这样才能形成最优解决方案。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '物联网平台解决方案介绍',
			desc: '奥卡云物联网平台是奥卡云自主研发的物联网应用平台，可部署在公有云、私有云等云平台，集设备管理、智能物联、便捷基础运维、数据分析系统于一体。为设备提供安全可靠的连接通信能力，向下连接海量设备，支撑设备数据采集上云；向上提供云端API，可以通过云端API对远程设备进行监控和控制，同时也可以进行二次开发。对下提供设备端SDK，使物联网设备可以快速接入到物联网平台。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		easyToDevelop: {
			title: '简化开发',
			desc: '提供物联网设备，第三方传感产品接入，物联网络、IoT标准化接口服务，让用户专注于核心业务系统开发和场景构建，降低开发门槛和开发成本。',
		},
		continuousUpgrade: {
			title: '持续升级',
			desc: 'IoT开发平台对接更多传感硬件和服务组件，并持续提供丰富、成熟的API接口，让企业一次部署后，可以持续升级自己的企业物联网应用。',
		},
		optimalScheme: {
			title: '最优方案',
			desc: 'IoT开放平台技术开放中立，对接各种物联网协议，接口，物联网络，根据用户场景提供最优的物联网部署策略和技术方案。',
		},
		securityCompatibility: {
			title: '安全兼容',
			desc: 'IoT开放平台整合各厂家传感和网络硬件产品，降低企业开发过程中各种兼容性处理成本，供从传感，网关，到云端的数据传输加密机制，统一安全管理策略，避免各厂家安全机制混乱。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
