const zhCN = {
	documentTitle: '奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		uniio: {
			name: 'OrcaStor UniIO全闪存分布式存储',
			desc: 'OrcaStor UniIO是一款面向云计算领域的全闪存分布式存储产品。',
		},
		orcabd: {
			name: 'OrcaBD分布式存储',
			desc: 'OrcaBD是一款面向视频监控、容灾备份、数据归档等领域的分布式存储产品。',
		},
		orcafs: {
			name: 'OrcaFS分布式并行文件存储',
			desc: 'OrcaFS是一款面向高性能计算、大数据场景的分布式并行文件存储，采用分布式架构实现，支持灵活弹性扩展，满足不同业务的存储需求。',
		},
	},
	hotTab: {
		product: {
			title: 'OrcaStor UniIO',
			desc: '面向云计算领域的全闪存分布式存储产品。',
		},
		solution: {
			title: '大数据解决方案',
			desc: '大数据解决方案是为大数据分析提供经济、高效、稳定的企业级存储服务。',
		},
	},
	product: {
		title: '产品',
		subTitle: '高稳定性、高安全性、高性能、行业覆盖面广的存储产品',
		uniio: {
			name: 'OrcaStor UniIO全闪存分布式存储',
			desc: 'OrcaStor UniIO是一款面向云计算领域的全闪存分布式存储产品，采用 Scale-out 分布式架构，可实现大规模横向扩展。通过将闪存资源组织起来构建全局闪存资源池，按需为上层应用提供块存储服务和文件存储服务，可以同时支持各种数据库负载、虚拟化应用和云原生应用，满足关键业务和形态各异的众多应用的不同存储需求。',
			scenario: {
				virtualization: '虚拟化',
				database: '数据库',
				cloudComputing: '云计算',
				privateCloud: '私有云',
				fiveG: '5G应用',
				containerCloud: '容器云',
			},
			feature: {
				deduplication: {
					name: '重删压缩',
					desc: '高压缩和重删比率，节省空间',
				},
				/*
				qos: {
					name: '基于应用的QoS',
					desc: '智能负载，避免"扰邻"',
				},
				 */
				scale: {
					name: '弹性伸缩',
					desc: '容量和性能随节点数量增加而提升',
				},
				remoteReplication: {
					name: '高效的远程复制',
					desc: '指纹数据复制，减轻网络负载',
				},
				performance: {
					name: '极致性能',
					desc: '单节点10万+IOPS、1.2ms低时延',
				},
				/*
				lowCarbon: {
					name: '低碳环保',
					desc: '77%能耗节省',
				},
				 */
				localization: {
					name: '国产化平台',
					desc: '国产自主可控的飞腾平台',
				},
				cloud: {
					name: '全面云化',
					desc: '提供对接各类虚拟化、云平台的标准接口',
				},
			}
		},
		orcafs: {
			name: 'OrcaFS分布式并行文件存储',
			desc: 'OrcaFS是一款面向高性能计算和大数据应用的存储产品，采用存储服务与元数据服务分离部署的方式，拥有独立的分布式文件系统缓存OrcaOND（OrcaFS On Demand）支持高效的突发缓存系统Burst Buﬀer，支持跨节点的副本和纠删技术，拥有快照、配额管理、域管理、在线升级等功能。',
			scenario: {
				geneSequencing: '基因测序',
				materialScience: '材料科学',
				weatherForecasting: '气象预测',
				energyExploration: '能源勘探',
				remoteSensingSatellite: '遥感卫星',
				ai: '人工智能',
				machineLearning: '机器学习',
				dataAnalysis: '数据分析',
			},
			feature: {
				performance: {
					name: '性能优越',
					desc: '基于RDMA网络架构，百万级IOPS和百GB级带宽',
				},
				protocol: {
					name: '多种协议',
					desc: '支持POSIX、NFS、CIFS、HDFS等多种协议',
				},
				verification: {
					name: '三重校验',
					desc: 'RAID-M元RAID支持同时故障三块盘，数据不丢失',
				},
				orcaond: {
					name: 'OrcaOND',
					desc: '突破IO浪涌问题，提升并行访问能力',
				},
				capacity: {
					name: 'EB级容量+亿级文件',
					desc: '集群支持EB级数据量和亿级文件数量',
				},
				highReliability: {
					name: '高可靠',
					desc: '纠删+副本+RAID，确保数据安全可靠',
				},
			},
		},
		orcabd: {
			name: 'OrcaBD分布式存储',
			desc: 'OrcaBD是一款面向视频监控、容灾备份、数据归档等领域的分布式存储产品，采用全新的分布式软件架构，通过存储系统软件将服务器的本地存储资源构建为统一的资源池。支持融合存储访问协议、大容量、高性能、高可靠、智能运维等特性，通过Amazon S3/swift/iSCSI/FC等接口协议为用户提供块存储服务和对象存储服务。',
			scenario: {
				videoMonitor: '视频监控',
				hybridCloudStorage: '混合云存储',
				dataArchiving: '数据归档',
				disasterRecoveryBackup: '容灾备份',
			},
			feature: {
				fusionArchitecture: {
					name: '融合架构',
					desc: '支持块存储服务和对象存储服务',
				},
				intelligentLayering: {
					name: '智能分层',
					desc: '冷、热数据动态分析，智能分层',
				},
				lowCost: {
					name: '低成本',
					desc: '支持通用X86架构服务器及国产服务器',
				},
				easyToExpand: {
					name: '易扩展',
					desc: '横向扩展，最大支持4096个节点',
				},
				highReliability: {
					name: '高可靠',
					desc: '支持纠删码和副本冗余保护机制',
				},
				easyToUse: {
					name: '简单易用',
					desc: '统一GUI，实时监控及故障预测',
				},
			},
		},
	},
	solution: {
		title: '解决方案',
		subTitle: '面向各行业应用场景的全栈式解决方案',
		overviewText: '奥卡云持续关注各行业业态，深挖行业痛点，有针对性地提供优质的解决方案，助力企业和城市发展',
		general: {
			name: '通用行业',
			desc: '随着科学技术的发展，基于大数据、高性能计算、云计算等技术的解决方案已被应用在各个行业中，包括：金融、汽车、环境、餐饮、电信、能源、体能和娱乐等领域。',
		},
		intelligentCampus: {
			name: '智慧校园',
			desc: '智慧校园通常由以传感器网络及智能硬件为核心的校园基础设施和部署在数据中心内云端服务器上的智慧化软件系统构成，常见功能可分为智慧教学环境、智慧教学资源、智慧校园管理等板块。',
		},
		intelligentPolice: {
			name: '智慧公安',
			desc: '智慧公安通过进行人员管控、大数据深层挖掘和智能研判应用，实现对人员的全方位、立体式管控，对社会安全的保障，提高社会治安防控水平，提高民众社会治安满意度。',
		},
		intelligentCommunity: {
			name: '智慧社区',
			desc: '智慧社区是指通过利用各种智能技术和方式，整合社区现有的各类服务资源，为社区群众提供政务、商务、出行、娱乐、教育、医护及生活互助等多种便捷服务的模式。',
		},
		intelligentScenicSpot: {
			name: '智慧景区',
			desc: '智慧景区是指通过智能网络，对景区地理事物、自然资源、旅游者行为、景区工作人员行迹、景区基础设施和服务设施进行全面、透彻、及时的感知，对有关人员实现可视化管理。',
		},
		emergency: {
			name: '应急管理',
			desc: '智慧化的应急管理可实现安全生产要素的实时监控和智能处理。在此基础上建立包含事前预警、事中处置和事后准备的智能物联网服务型监测预警平台，改变传统的被动管理方式。',
		},
	},
	source: {
		title: '企业资源',
		subTitle: '筑牢企业研发团队、合作伙伴等资源基础，紧跟行业发展趋势',
		rdCenter: '全球研发中心',
		rdPeople: '研发人员',
		case: '应用案列',
		partner: '合作伙伴',
		legendTitle: '合作区域',
		opening: '开通中',
		opened: '已开通',
		toBeOpened: '待开通',
		industryTrends: {
			distributedStorage: {
				title: '分布式存储',
				desc: '分布式存储深入到千行百业的基础设施层已是大势所趋，其扩展性、高性能、开发性等特性与用户数字化转型的基础设施建设需求高度契合，也铸就了产业化的基础。分布式存储产品形式和交付形态会出现重要趋势，即软硬一体机的方式将成为市场的绝对主流和用户首选。',
			},
			iot: {
				title: '物联网',
				desc: '物联网技术将推动数字化转型，到2030年，物联网设备将超过254亿台。人工智能、5G 网络和区块链技术的进步将简化通信和数据处理，使公司能够更高效地满足客户需求。AI和ML将使机器能够处理更复杂的任务，使员工能够专注于其他事情。',
			},
			edgeComputing: {
				title: '边缘计算',
				desc: '随着物联网网络的扩展，它们将为另一项不可避免的创新铺平道路：边缘计算。边缘计算有可能使自动驾驶汽车更加实用，同时还可以解决当今的许多带宽、安全性和云可靠性问题。尽管有这些优势，但它今天仍然是一种利基技术，但随着物联网的扩展，这种情况将会改变。',
			},
			virtualization: {
				title: '虚拟化',
				desc: '虚拟化超大规模部署最早将于2023年开始，并针对特定应用提供一些单点解决方案。然后在集成和测试大量组件、系统和开源软件时进行大规模部署。他们在具有海量数据集的应用程序的早期采用者中处于领先地位，这些应用程序需要尽快交付结果。',
			}
		},
	},
	case: {
		title: '案例精选',
		subTitle: '优质的产品、解决方案实施、客户项目建设等案例',
		xiXianPoliceDepartment: {
			name: '智慧安防社区建设',
			introduction: '在本次智慧安防社区建设中，涉及各类平台（社区应用平台、大数据平台）、电子围栏系统、人行道闸系统等进行数据采集、转换、汇总、分析、汇报等高频操作。在综合考虑、研判整个社区建设、管理及后期维护升级等因素后，奥卡云提出采用分布式全闪+分布式存储组合模式，在确保高性能、低时延的同时，满足大容量、高可靠的数据存储需求。',
		},
		qinHanNewCityWeiLiuJiaYuanIntelligentCommunity: {
			name: '秦汉新城渭柳佳苑智慧社区',
			introduction: '渭柳佳苑社区是西咸新区秦汉新城最大的安居工程，社区面积大、人口多、流动人口频繁，人口居住的集中化、流动的多样化，给社区管理与治理方面出现一些新的问题，各类社会矛盾和冲突逐步增多。通过智慧社区的建设，通过搭建统一的社区综合安防平台，使一线网格员、警务人员、物业管理人员实现了对实有人口、流动人口、重点关注人员、重点车辆的精确管理与管控，视频监控对整个社区全方位实时监控，实现了突发事件可视化指挥调度，提高处理效率。',
		},
		qinHanNewCityAllAreaTourism: {
			name: '秦汉新城全域旅游',
			introduction: '秦汉新城位于西咸新区的几何中心，总规划面积302平方公里，其中城市建设用地约50平方公里，遗址保护区104平方 公里，自贸片区10.85平方公里，下辖6个街办（镇）。提出需要“依托区内丰富的历史文化资源，统筹兼顾历史遗产的保护与开发利用，通过“政府引导，市场运作，项目带动，创新引领”的方式，围绕“秦”、“汉”两大文化主题，利用创新技术打造秦汉新城全域旅游。”作为本期全域旅游的建设目标。',
		},
	},
	partner: {
		title: '生态合作',
		subTitle: '凝聚生态力量，共铸明日辉煌',
	},
	contactUs: {
		title: '商务咨询',
		subTitle: '沟通促成商机、合作助力共赢',
		fullnamePlh: '您的姓名',
		phonePlh: '您的电话号码',
		consultationPlh: '请简述您想要咨询的内容，长度为10-200字',
		readAndAgree: '我已阅读并同意',
		privacyStatementName: '《奥卡云官方网站隐私声明》，',
		dataStore: '知道并同意将涉及的个人数据存储在奥卡云的服务器上。',
		submit: '提交',
		submitting: '提交中...',
		validation: {
			fullnameEmpty: '请输入您的姓名！',
			phoneIncorrect: '请输入正确的手机号或座机号（区号-号码）！',
			consultationEmpty: '请输入您要咨询的内容！',
			consultationIsShort: '咨询内容长度为10-200字！',
			readPrivacyStatement: '请阅读并同意《奥卡云官方网站隐私声明》！',
		},
		actionOk: '咨询提交成功',
		actionOkTips: '感谢您提交咨询，我们的工作人员将稍后联系您。',
		actionFails: '咨询提交失败，原因：',
	},
	applyTrial: {
		title: '立即注册，即可申请试用多款核心产品！',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
