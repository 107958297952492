const zhCN = {
	documentTitle: '智慧园区解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '智慧园区解决方案',
		subTitle: '奥卡云智慧园区解决方案通过平台多个子系统，20多种设备，实现丰富多元化的数据接入，包括视频、门禁、报警、车辆、物联设备等数据，集视频监控、物联网感知和网络安全功能。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		lowEfficiency: {
			title: '管理效率低下，运营成本高',
			desc: '传统园区的人工管理方式不仅效率低下，而且容易出错。同时地产商缺乏相应产业生态、产业集群塑造、清差补优经验，不清楚如何服务好特定的产业人群。同时，创业企业增多也会带来租期缩短、面积分割、变动频繁等问题，缺乏有效的管理工具就会滋生混乱。',
		},
		dataNotPrecipitated: {
			title: '数据没有沉淀，难以形成闭环',
			desc: '所有的智能化都基于数字化，这基本上是“智慧”的门槛。传统口口相传，经验至上的管理办法如果不进行数字沉淀，是没有办法做到标准化管理的，更别说分析决策了。而同时，数据不进行有效管理和储存，其留存、安全、信息传递都会产生一系列问题。',
		},
		highEcologicalRichness: {
			title: '园区生态丰富度高，难以形成闭环',
			desc: '智慧园区中的生态环境丰富度比较高，因为产业园本身有着各种各样的生态链、产业链。如企业资源生态、环保生态、服务生态、数据生态、管理生态等等。每一个生态都要形成闭环，每个生态间还要共建连接，这样每个生态这样才能够体现出每个的生态在各层面的作用。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '智慧园区解决方案介绍',
			desc1: '通过平台多个子系统，20多种设备，实现丰富多元化的数据接入，包括视频、门禁、报警、车辆、物联设备等数据，集视频监控、物联网感知和网络安全功能，基础视频管理包括：视频预览、录像回放，也涵盖人脸智能应用包括人脸抓拍、人脸检索、人脸布控、人员轨迹等功能，车辆智能应用包括图片抓拍、车辆轨迹、车辆布控等功能，利用人脸识别、图像识别、深度学习等技术手段，结合视频智能分析应用，实现对人、车、物的有效管理。',
			desc2: '基于园区综合管理平台，根据标杆项目的成功实践沉淀园区的业务资产，数据资产等，支持园区多场景应用，包括：智能运营中心，综合安防，便捷通行，资产管理，设备设施管理，能效管理，环境空间，企业服务等应用。以科技手段让园区管理、运营和服务更高效、更智能。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		unifiedManagement: {
			title: '统一管理',
			desc: '系统集成视频，停车场，报警，门禁，物联网等子系统，实现一键报警、多点联动、智能分析、统一管理人车物等功能。',
		},
		linkageCommand: {
			title: '协同联动指挥',
			desc: '考虑到智安园区综合安防各类技术防范系统联动化、集成化的需要，系统架构应考虑跨系统、跨平台互连通用，将原本独立运行、信息屏蔽的诸多应用子系统进行横向协同，实现多个系统业务的综合管理，包括智能化联动等，充分发挥系统整体应用价值。',
		},
		experienceOptimization: {
			title: '智能体验优化',
			desc: '园区数据中心和中台进行统一对接，实现数据标准统一与高效互通，通过数据智能驱动进行战略决策。提供园区通行、智能巡更、场地预定、智慧停车等应用，通过信息技术与资源合理配置、AI赋能，使得园区人员工作生活更便捷，园区生活更丰富，提高园区核心竞争力。',
		},
		intelligentManagement: {
			title: '智慧化管理',
			desc: '提供能耗管理、资产管理、企业信息管理等全方位的企业日常运行相关应用，构建设备与场景的物联及数字化，提高业务效率，拓展管控手段，优化业务流程。',
		},
		strongVersatility: {
			title: '平台通用性强',
			desc: '整合通用性服务，如报事报修、意见反馈、政策资讯等，使得产品既适用于园区管理，也适用于社区等形态的用户群体，兼容并包，将多元化的园区和冗余的业务统一化、简单化。',
		},
		allAreaSecurityControl: {
			title: '全域安防管控',
			desc: '通过在园区周界、出入口进行布控，园区重点区域/设备进行巡检等，实现入侵检测、园区安全隐患识别等功能，同时对访客和车辆进行综合管理，并基于智能物联，实现“安消一体”，防患于未然，构建智能消防安全体系，提高园区各场景安全防控水平。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		qinHanNewCityManagementCommitteePark: {
			title: '秦汉新城管委会园区',
			introduction: '秦汉新城管委会大楼于2010年建成，大楼建设初期，弱电安防系统以模拟监控系统为主进行大楼及园区监控覆盖。近年来，设备故障、线路老化、以及楼内其他机关事业单位自行装修建设时，对于大楼主体网络、消防线路造成破坏导致的信号传输，通过对原模拟摄像头的改造升级，西咸新区秦汉新城管委会实现了监控系统的全高清覆盖，使用3D场景建模技术，极致的还原了管委会建筑及地理风貌，通过标签叠加技术，将摄像头位置坐标内置在场景中，所见即所得，大大的提高了视频的易用率和美观度。通过后台的实时视频流解析，实现了园区出入人脸的抓拍、车辆的统计、警戒入侵的告警等功能，打造科技新型智慧园区解决方案。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
