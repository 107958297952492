import store from '@store';
import localeMap from '@i18n';
import {DEFAULT_LOCALE, logger, lsGet} from '@utils';

export const getLocaleText = (
	locale: string,
	componentName: string,
	subsection: string | null,
	localeKey: string,
	param?: Record<string, any>
) => {
	let componentI18n = localeMap[componentName];
	if (componentI18n === void 0) {
		return logger.warn(`i18n Warn: i18n view module of component "${componentName}" is not found.`);
	}

	let localeType = locale.replace('-', ''); // Convert 'zh-CN' to 'zhCN';
	let localeText = componentI18n[localeType];

	if (subsection !== null) {
		localeText = localeText[subsection];
		if (localeText === void 0) {
			return console.warn(`i18n Warn: sub-section ${subsection} is not found in component ${componentName}.`);
		}
	}

	let keys = localeKey.split('.');

	while (keys.length > 0) {
		let key = keys.shift();
		if (key !== void 0) {
			localeText = localeText[key];
			if (localeText === void 0) {
				logger.warn(`i18n Warn: Sub-key "${key}" in key "${localeKey}" of component "${componentName}" is not available.`);
				break;
			}
		} else {
			break;
		}
	}

	if (typeof localeText === 'function') {
		if (param !== void 0) {
			localeText = localeText(param);
		} else {
			logger.warn(`i18n Warn: Parameter for locale function "${localeText}" is not provided.`);
		}
	}

	return localeText;
};

// This a function is for getting i18n text out of a react functional component, if you wanna do the
// same thing in a react functional component, please use the 'useI18n' hook instead.
export const makeI18n = (
	componentName: string
) => {
	let locale = DEFAULT_LOCALE;

	// Get current locale from redux or localStorage.
	if (store && store.getState) {
		const state = store.getState();
		if (state.locale) {
			locale = state.locale;
		}
	} else {
		let lsLocale = lsGet('oos_locale');
		if (lsLocale) {
			locale = lsLocale;
		}
	}

	return (
		localeKey: string,
		param?: any
	) => {
		return getLocaleText(locale, componentName, localeKey, param);
	};
};
