const zhCN = {
	title: '申请职位',
	fullnamePlh: '请输入您的姓名，12字以内',
	phonePlh: '请输入您的手机号码',
	emailPlh: '请输入您的邮箱地址',
	selectFilePlh: '点击选择您的简历文件',
	summaryPlh: '请输入您的简介，长度为200字以内（选填）',
	validation: {
		fullnameEmpty: '请输入您的姓名！',
		phoneIncorrect: '请输入正确的手机号！',
		emailIncorrect: '请输入正确的邮箱地址！',
		fileEmpty: '请选择上传您的简历文件，支持的文件扩展名有：doc、docx、pdf！',
	},
	applicationSending: '申请发送中...',
	actionOk: '申请发送成功',
	actionOkTips: '感谢您申请奥卡云的招聘职位，我们的HR会在您的申请信息审核完后联系您，请保持联系方式的畅通。',
	actionFails: '职务申请发送失败，原因：',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
