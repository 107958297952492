import {modalActionTypes} from '@actions/modalAction';
import {Reducer} from '../interfaces';

const modalReducer: Reducer = (state, action) => {
    let {maskVisible, signUpVisible, signInVisible, resetPasswordVisible, applyTrialVisible, applyJobVisible, certificateDetailVisible} = action;

    switch (action.type) {
        case modalActionTypes.SET_MASK_VISIBLE:
            return Object.assign({}, state, {maskVisible});

		case modalActionTypes.SET_SIGN_UP_VISIBLE:
            return Object.assign({}, state, {signUpVisible});

        case modalActionTypes.SET_SIGN_IN_VISIBLE:
            return Object.assign({}, state, {signInVisible});

        case modalActionTypes.SET_RESET_PASSWORD_VISIBLE:
            return Object.assign({}, state, {resetPasswordVisible});

        case modalActionTypes.SET_APPLY_TRIAL_VISIBLE:
            return Object.assign({}, state, {applyTrialVisible});

		case modalActionTypes.SET_APPLY_JOB_VISIBLE:
			return Object.assign({}, state, {applyJobVisible});

		case modalActionTypes.SET_CERTIFICATE_DETAIL_VISIBLE:
			return Object.assign({}, state, {certificateDetailVisible});

        default:
            return state;
    }
};

export default modalReducer;
