import {useCallback, useEffect, useRef} from 'react';
import {useLocation} from '@hooks';

export default function useScrollToTop (){
	// Router.
	const location = useLocation();

	// Ref data.
	const previousPathnameRef = useRef('');

	const scrollToTop = useCallback(() => {
		if (location.pathname !== previousPathnameRef.current) {
			// Scroll to top.
			if (document.documentElement) {
				document.documentElement.scrollTop = 0;
			} else {
				document.body.scrollTop = 0;
			}
		}
	}, [location]);

	useEffect(() => {
		scrollToTop();
	}, [scrollToTop]);
}
