import {produce} from '@utils';
import {Reducer} from '../interfaces';

// Note:
// When we use produce from immer, we can create immutable data, so if compare whether
// the data is changed or not, we can simply use shallow equal instead of deep equal to
// improve the performance. Since this, the disadvantage is that when we want to mutate
// the grandchildren data of the immutable data, we should use "produce" API from immer
// instead of changing it directly.

const combineReducersImmer = (reducers: Record<string, Reducer>) => {
	const keys = Object.keys(reducers);
	let initialStateEmpty: Record<string, any> = {};
	const initialState = keys.reduce((prev, key) => {
		// Check undefined passed in.
		// Even we are implementing a customized combineReducers method, we still need
		// to follow some rules required by the implement of official combineReducers,
		// so we need to broadcast the undefined state to all reducers to check whether
		// the reducers complies with the official rules in state initializing phase
		// when call redux createStore.
		prev[key] = reducers[key](void 0, {});
		return prev;
	}, initialStateEmpty);

	// Make all the initialized states immutable (curried producer with initial state).
	// We may pay some cost here, but will get huge improvement in further.
	// In terms of performance and simplicity, immer is generally better than immutable.js.
	return produce((draft, action) => {
		let i = 0;
		let len = keys.length;
		for (; i < len; i ++) {
			const key = keys[i];
			draft[key] = reducers[key](draft[key], action);
		}
		return draft;
	}, initialState);
};

export default combineReducersImmer;
