import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {equals, getLocaleText} from '@utils';
import {State} from '@redux/interfaces';
import {I18nComponentFn} from './interface';

interface SelectedState {
	locale: 'zh-CN' | 'en-US';
}

// This customize hook is for getting i18n text in a react functional component, if you wanner do the
// same thing out of a react functional component, please use the function 'makeI18n' in utils instead.
export default function useI18n (
	componentName: string,
	subSection?: string,
): I18nComponentFn {
	// Redux related.
	const selectState = useCallback((state: State) => {
		const {locale} = state;
		return {locale};
	}, []);

	const {locale} = useSelector<State, SelectedState>(selectState, equals);

	return useCallback((
		localeKey,
		param?
	) => {
		return getLocaleText(locale, componentName, subSection || null, localeKey, param);
	}, [locale, componentName, subSection]);
}
