const zhCN = {
	menu: {
		home: '首页',
		product: '产品',
		solution: '解决方案',
		technicalSupport: '技术支持',
		firmNews: '企业资讯',
		about: '关于',
	},
	product: {
		uniio: 'OrcaStor UniIO',
		orcabd: 'OrcaBD',
		orcafs: 'OrcaFS',
	},
	solution: {
		// Storage solution.
		// General category.
		bigData: '大数据',
		highPerformanceComputing: '高性能计算',
		cloudComputingPrivateCloud: '云计算/私有云',
		cloudNativeContainerStorage: '云原生容器存储',
		dataBackup: 'OrcaBackup数据备份',
		// Industry category.
		newEnergyVehicle: '新能源汽车',
		intelligentCampus: '智慧校园',
		intelligentSecurity: '智慧安防',
		ai: '人工智能',

		// IoT solution.
		// Intelligent police category.
		brightProject: '雪亮工程',
		majorEventSecurity: '重大活动安保',
		// Intelligent community category.
		intelligentCommunity: '智慧社区',
		intelligentCommunitySecurity: '智慧社区安防',
		intelligentElevator: '智慧电梯',
		highAltitudeThrowObject: '高空抛物',
		// Intelligent park category.
		intelligentPark: '智慧园区',
		// Intelligent scenic spot category.
		intelligentScenicSpot: '智慧景区',
		allAreaTourism: '全域旅游',
		// Intelligent garden category.
		intelligentGarden: '智慧园林',
		// Carbon neutrality category.
		carbonNeutrality: '碳中和',
		// Emergency category.
		emergencyCommand: '应急指挥',
		safetyProductionEmergency: '安全生产应急监管平台',
		// General category.
		iotPlatform: '物联网平台',
	},
	solutionCategory: {
		storage: '存储解决方案',
		iot: '物联网解决方案',
	},
	solutionSecondaryCategory: {
		industry: '行业',
		intelligentCampus: '智慧校园',
		intelligentPolice: '智慧公安',
		intelligentCommunity: '智慧社区',
		intelligentPark: '智慧园区',
		intelligentScenicSpot: '智慧景区',
		carbonNeutrality: '碳中和',
		intelligentGarden: '智慧园林',
		emergency: '应急管理',
		general: '通用',
	},
	technicalSupport: {
		document: '产品文档',
		afterSalesCase: '售后案例',
		imageGallery: '产品图库',
		guarantee: '保修期查询',
	},
	about: {
		aboutUs: '关于奥卡云',
		leadership: '领导团队',
		honor: '企业荣誉',
		recruitment: '企业招聘',
		contactUs: '联系我们',
	},
	search: {
		plh: '存储 全闪存 智慧社区',
		searchEmpty: '请输入要搜索的内容！',
		emptyText: '暂无搜索结果',
		content: {
			// Product.
			uniio: 'OrcaStor UniIO分布式全闪存存储',
			orcabd: 'OrcaBD分布式存储',
			orcafs: 'OrcaFS分布式并行文件存储',

			// Storage solution.
			// General category.
			bigData: '大数据解决方案',
			highPerformanceComputing: '高性能计算解决方案',
			cloudComputingPrivateCloud: '云计算/私有云解决方案',
			cloudNativeContainerStorage: '云原生容器存储解决方案',
			dataBackup: 'OrcaBackup数据备份解决方案',
			// Industry category.
			newEnergyVehicle: '新能源汽车解决方案',
			intelligentCampus: '智慧校园解决方案',
			intelligentSecurity: '智慧安防解决方案',
			ai: '人工智能解决方案',

			// IoT solution.
			// Intelligent police category.
			brightProject: '雪亮工程解决方案',
			majorEventSecurity: '重大活动安保解决方案',
			// Intelligent community category.
			intelligentCommunity: '智慧社区解决方案',
			intelligentCommunitySecurity: '智慧社区安防解决方案',
			intelligentElevator: '智慧电梯解决方案',
			highAltitudeThrowObject: '高空抛物解决方案',
			// Intelligent park category.
			intelligentPark: '智慧园区解决方案',
			// Intelligent scenic spot category.
			intelligentScenicSpot: '智慧景区解决方案',
			allAreaTourism: '全域旅游解决方案',
			// Intelligent scenic spot category.
			intelligentGarden: '智慧园林解决方案',
			// Carbon neutrality category.
			carbonNeutrality: '碳中和解决方案',
			// Emergency category.
			emergencyCommand: '应急指挥解决方案',
			safetyProductionEmergency: '安全生产应急监管平台解决方案',
			// General category.
			iotPlatform: '物联网平台解决方案',
		},
	},
	padAndPhDropDownMenuTitle: '导航',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
