type msgArgs = unknown[];

type logFn = (...msg: msgArgs) => void;

interface Logger {
	info: logFn;
	warn: logFn;
	error: 	logFn;
}

const info: logFn = (...msg: msgArgs) => {
	window.console.info('%c[Orcadt Info]', 'color: #52a7fe', ...msg);
};

const warn: logFn = (...msg: msgArgs) => {
	window.console.warn('%c[Orcadt Warn]', 'color: #f6b93f', ...msg);
};

const error: logFn = (...msg: msgArgs) => {
	window.console.error('%c[Orcadt Error]', 'color: #f6787a', ...msg);
};

const logger: Logger = {info, warn, error};

export default logger;
