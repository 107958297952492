import {useEffect} from 'react';

export default function useScroll (
	// cbFun: (this: Window, ev: Event) => any,
	cbFun: Function,
	target: HTMLElement | Window = window
){
	useEffect(() => {
		// Check scroll top in page first rendering.
		cbFun();

		const cbFunImpl = (e: Event) => {
			cbFun();
		};

		target.addEventListener('scroll', cbFunImpl);

		return () => {
			target.removeEventListener('scroll', cbFunImpl);
		};
	}, [cbFun, target]);
}
