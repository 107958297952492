import {handleFetchExceptionMsg} from './httpHelper';
import dataAdapter from './dataPreprocessor';
import {logger} from '@utils';
import {FetchOption, TaskExtraArg, ReqPromise} from '../interface/fetch';

// If the browser is not compatible with Web Worker or all the workers are busy,
// will directly initialize a HTTP request in main thread. That is the case this
// function is used for.

const httpInMainThread = async (
    {url, reqInit}: FetchOption,
    {resolve, reject}: ReqPromise,
    extraArg: TaskExtraArg,
) => {
    try {
        const response = await fetch(url, reqInit);
        if (response.ok) {
			if (!extraArg.stream) {
				let {code, data, msg} = await response.json();
				if (code === 0) {
					if (extraArg.processor) {
						const {processor, preArg} = extraArg;
						data = dataAdapter[processor].apply(null, [preArg, data].filter(Boolean));
					}
					resolve(data);
				} else {
					reject({url, code, msg});
				}
			} else {
				resolve(response.arrayBuffer());
			}
        } else {
            const {status: code, statusText} = response;
            reject({url, code, msg: statusText || `HTTP Error: code is ${code}`});
            logger.info(`HTTP Error: code is ${code}`);
        }
    } catch (e){
        reject({
            url,
            code: 'GUI HTTP executor error',
            msg: handleFetchExceptionMsg(e)
        });
    }
};

export default httpInMainThread;
