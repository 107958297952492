const zhCN = {
	title: '注册账号',

	fullnamePlh: '请输入您的姓名，长度为1-20位',
	phonePlh: '请输入您的手机号码（将用作您的登录账号）',
	emailPlh: '请输入您的邮箱地址（将用于忘记密码后重置密码）',
	passwordPlh: '请输入密码，长度为6-12位',
	rePasswordPlh: '请输入确认密码，长度为6-12位',

	validation: {
		fullnameEmpty: '请输入您的姓名！',
		phoneIncorrect: '请输入正确的手机号！',
		emailIncorrect: '请输入正确的邮箱地址！',
		passwordIncorrect: '请输入正确的密码，长度为6-12位！',
		rePasswordIncorrect: '请输入正确的确认密码，长度为6-12位！',
		passwordReInputIncorrect: '两次输入的密码不一致，请修改正确！',
		agreementNotReadAndAgree: '请阅读并同意《奥卡云官方网站服务协议》！',
	},

	readAndAgree: '我已阅读并同意',
	serviceAgreementName: '《奥卡云官方网站服务协议》',
	signingUp: '注册中...',
	hasAccount: '已有账号？',
	forwardSignIn: '立即登录',
	actionOk: '注册成功',
	actionOkTips: '感谢您注册奥卡云账号，您已可申请试用我们的产品。',
	actionFails: '注册账号失败，原因：',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
