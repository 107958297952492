import {ActionCreatorSet} from '../interfaces';

export const recruitmentActionTypes = {
	SET_JOB_LIST: '@RECRUITMENT/SET_JOB_LIST',
};

const recruitmentAction: ActionCreatorSet = {
	setJobList: jobList => ({
		type: recruitmentActionTypes.SET_JOB_LIST,
		jobList
	}),
};

export default recruitmentAction;
