/**
 * This tool is based on sessionStorage API, supports single or batch set/get/remove
 * operations on one key or a keys array.
 */

export const ssGet = (key: string): any => {
    const val = window.sessionStorage.getItem(key);
    try {
        if (val === null) {
            return val;
        }
        return JSON.parse(val);
    } catch (e) {
        return val;
    }
};

export const ssSet = (key: string, val: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(val));
};

export const ssRemove = (keys: string | string[]) => {
    if (!Array.isArray(keys)) {
        keys = [keys];
    }
    for (let key of keys) {
        window.sessionStorage.removeItem(key);
    }
};

export const ssClearAll = () => window.sessionStorage.clear();

export const ssGetWithDefault = <T,>(key: string, defaultVal: T): T => {
    let val = ssGet(key);
    if (val === null) {
        ssSet(key, defaultVal);
        return defaultVal;
    } else {
        return val;
    }
};
