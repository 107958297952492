const zhCN = {
	documentTitle: '关于奥卡云 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '关于奥卡云',
		subTitle: '我们秉持自主研发的坚定理念，服务中国，面向全球',
	},
	introduction: {
		title: '企业简介',
		subTitle: '企业的理念、目标、宗旨、使命、愿景',
		firmName: '西安奥卡云数据科技有限公司',
		firmAliasName: 'ORCA DATA TECHNOLOGY (XI\'AN) CO.,LTD',
		desc1: '西安奥卡云数据科技有限公司是一家以软件定义存储、智慧物联网为核心，双核并进，持续赋能企业数据管理及智能运维的高新技术企业。公司致力于为政府机关、军工、教育科研、医疗、智慧城市等多个领域打造融合、智能、高效、可靠的产品及相关解决方案。',
		desc2: '公司在全球拥有员工100余人，并拥有国家A类人才1位。设立存储事业部和物联网事业部，总部位于西安，在成都、美国分别设立研发中心，在长沙设有办事处。依托深厚的技术背景和研发实力，拥有60多项自主知识产权创新发明专利，先后通过ISO9001、CMMI3等多项认证。奥卡云数据扎根西安，秉持自主研发的坚定理念，与国内外行业资深专家深度融合，服务中国，面向全球，立志向世界发出属于中国科技的最强音。',
	},
	mission: {
		title: '企业使命与理念',
		subTitle: '企业的角色担当与社会责任',
		missionDesc: '简化数据存储与挖掘服务客户快速将数据转化为商业及社会价值。',
		focus: '专注原创、自主可控、国际质量水准产品。',
		trustworthy: '让客户信赖、选择我们作为首选合作伙伴。',
		quality: '与客户共建、共享未来的数据智能时代。',
	},
	honor: {
		title: '企业荣誉',
		subTitle: '企业获得的证书和奖项',
		desc: '奥卡云通过国内外权威标准的合规认证，共获取了10余项资质证书、64项发明专利证书、22项软件著作证书以及数个外观专利证书，为了用户数据的安全性和产品的合规性而努力前行。',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
