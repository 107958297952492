import React, {FC, ReactNode, MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';

interface OOSProductBannerAnimProps {
	children?: ReactNode,
	name: string;
	noBg?: boolean;
}

const OOSProductBannerAnim: FC<OOSProductBannerAnimProps> = ({
	children: pChildren,
	name: pName,
	noBg: pNoBg = false,
}) => {
	// Internal states.
	const [referencePoint, setReferencePoint] = useState({refX: 0, refY: 0});
	const [mouseMappingPoint, setMouseMappingPoint] = useState({mappingX: 0, mappingY: 0});

	// Page action.
	const getReferencePointCoordinate = useCallback(() => {
		// Every point in the two dimensional coordinate system can be the reference point,
		// here we take the center point of the viewport (window) as it as usually.
		setReferencePoint({
			refX: parseInt(String(window.innerWidth / 2), 10),
			refY: parseInt(String(window.innerHeight / 2), 10),
		});
	}, []);

	const getMappingXFromFirstAndSecondQuadrant = useCallback((refX: number, clientX: number) => refX - (clientX - refX), []);

	const getMappingXFromThirdAndFourthQuadrant = useCallback((refX: number, clientX: number) => refX + (refX - clientX), []);

	const getMappingYFromFirstAndFourthQuadrant = useCallback((refY: number, clientY: number) => refY + (refY - clientY), []);

	const getMappingYFromSecondAndThirdQuadrant = useCallback((refY: number, clientY: number) => refY - (clientY - refY), []);

	const onAnimContentMouseMove = useCallback((e: MouseEvent<HTMLDivElement>) => {
		let {refX, refY} = referencePoint;
		let {clientX, clientY} = e;

		if ((clientX === refX) && (clientY === refY)) {
			// Mouse move to the reference point, no transformation occurs.
			return;
		}

		let newMouseMappingPoint = {
			mappingX: 0,
			mappingY: 0,
		};

		// Judge the mouse is in which quadrant, and get the mouse mapping point.
		if ((clientX > refX) && (clientY < refY)) {
			// Mouse point is in the first quadrant, the mapping point should be in the third quadrant.
			newMouseMappingPoint.mappingX = getMappingXFromFirstAndSecondQuadrant(refX, clientX);
			newMouseMappingPoint.mappingY = getMappingYFromFirstAndFourthQuadrant(refY, clientY);
		} else if ((clientX > refX) && (clientY > refY)) {
			// Mouse point is in the second quadrant, the mapping point should be in the fourth quadrant.
			newMouseMappingPoint.mappingX = getMappingXFromFirstAndSecondQuadrant(refX, clientX);
			newMouseMappingPoint.mappingY = getMappingYFromSecondAndThirdQuadrant(refY, clientY);
		} else if ((clientX < refX) && (clientY > refY)) {
			// Mouse point is in the third quadrant, the mapping point should be in the first quadrant.
			newMouseMappingPoint.mappingX = getMappingXFromThirdAndFourthQuadrant(refX, clientX);
			newMouseMappingPoint.mappingY = getMappingYFromSecondAndThirdQuadrant(refY, clientY);
		} else {
			// Mouse point is in the fourth quadrant, the mapping point should be in the second quadrant.
			newMouseMappingPoint.mappingX = getMappingXFromThirdAndFourthQuadrant(refX, clientX);
			newMouseMappingPoint.mappingY = getMappingYFromFirstAndFourthQuadrant(refY, clientY);
		}

		// Trigger a re-rendering.
		setMouseMappingPoint(newMouseMappingPoint);
	}, [referencePoint, getMappingXFromFirstAndSecondQuadrant, getMappingXFromThirdAndFourthQuadrant, getMappingYFromFirstAndFourthQuadrant, getMappingYFromSecondAndThirdQuadrant]);

	// After rendering and re-rendering.
	useEffect(() => {
		getReferencePointCoordinate()
	}, [getReferencePointCoordinate]);

	// Memorized data.
	const imgStyleMemo = useMemo(() => {
		// Calculate the img CSS style (transform), and memorized it to avoid meaningless re-rendering of img DOM.
		const {refX, refY} = referencePoint;
		const {mappingX, mappingY} = mouseMappingPoint;

		if ((mappingX === refX) && (mappingY === refY)) {
			// The case of initial accessing or mouse just on the reference point (currently it is the center point),
			// there is no mouse movement occurred, so there should not be any transformation.
			return {
				transform: 'translate3d(0, 0, 0)',
			}
		} else {
			let offsetX = ~(refX - mappingX) + 1;
			let offsetY = ~(refY - mappingY) + 1;

			// Scale the offset x, y values to 40 times smaller.
			// Directly using the raw offset x, y as the translate3d's value is not visually friendly.
			offsetX = offsetX / 50;
			offsetY = offsetY / 50;

			// Set the maximum offset x, y values.
			if (offsetX > 60) {
				offsetX = 60;
			}
			if (offsetY > 40) {
				offsetY = 40;
			}

			return {
				transform: `translate3d(${offsetX}px, ${offsetY}px, 0)`,
			};
		}
	}, [referencePoint, mouseMappingPoint]);

	// Reference equivalent child components.
	const BannerBaseRef = useRef(
		<>
			<i className="banner-light-curtain big" />
			<i className="banner-light-curtain small" />
			<i className="banner-conveyor-belt-base" />
			<i className="banner-conveyor-belt-stuff" />
			<i className="banner-data-process" />
			<i className="banner-light-bar-box">
				<i className="light-bar" />
				<i className="light-bar" />
			</i>
			<i className="banner-light-ray-box left">
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
			</i>
			<i className="banner-light-ray-box right">
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
			</i>
			<i className="banner-light-point-small" />
		</>
	);

	return (
		<>
			<div
				className="product-banner-anim-base-img"
				onMouseMove={onAnimContentMouseMove}
			>
				{BannerBaseRef.current}
				<i className={`banner-scenario ${pName}`} />
				<i className={`banner-application ${pName}`} />
				<i className={`banner-name ${pName}`} />
				<i className={`banner-float-img ${pName}`}>
					{pChildren}
				</i>
			</div>
			{!pNoBg && (<i className="banner-point-bg" style={imgStyleMemo} />)}
			<div className={`product-banner-static-img-pad-ph ${pName}`} />
		</>
	);
};

export default OOSProductBannerAnim;
