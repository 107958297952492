const zhCN = {
	documentTitle: '智慧园林解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '智慧园林解决方案',
		subTitle: '赋能数字公园建设，助力公园服务管理提升，最终实现科学化、精细化、智能化的公园管理目标。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		stateSupervision: {
			title: '城市园林状态监管问题',
			desc: '无法实时感知、直观了解园林的生态环境、资产、人员等状态；无法掌控全域的动态运行情况，进行整体协调联动；园林安全监控缺位，隐患较多；档案存档难以追溯，成本监测与管理难，管理效率较低；缺乏及时有效的沟通渠道，问题反馈不及时、应急处理效率低。',
		},
		achievementSupervision: {
			title: '业务成果监管问题',
			desc: '缺乏有效监管、考核养护单位工作成效的手段，工作量大、机制不健全；无法有效监管养护单位工作过程，实际业务中的舞弊现象难以发现；责任分包单位信息交接出现断层，效率低；工人老龄化，安全监管困难；巡查方式落后，出现问题处理难以跟踪。',
		},
		curingGuidance: {
			title: '养护业务指导问题',
			desc: '缺乏专业养护知识体系，无法对养护单位进行有效的作业指导；养护依靠经验，传承困难，缺乏标准化指导。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '智慧园林解决方案介绍',
			desc1: '为了打破公园管理过程中各子系统之间的信息孤岛数据壁垒，运用大数据、云计算等技术、结合数据可视化实现公园设备全连接、管理数据全融合、应用场景全联动、人员调度全智能，赋能数字公园建设，助力公园服务管理提升，最终实现科学化、精细化、智能化的公园管理目标。',
			desc2: '奥卡云智慧园林按照“全域覆盖、属地负责、高效处理、杜绝披露”原则，整合园林的日常监测、养护、巡查等主要功能，形成完整的处理机制，最大程度满足园林管理人员对于辖区内园林的养护和管理需求。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		intelligentManage: {
			title: '智能化管理',
			desc: '实时采集土壤、水等数据，及时传输到服务器，服务器终端接收到监测信息后采用物联网技术，实现智能灌溉等智能化应用，通过电脑、手机查询和接收监测数据和预警信息。浇灌过程中所有设备的控制只需通过手机APP和电脑客户端即可完成，接入智能摄像头后，工作人员在办公室即能监控浇灌现场。',
		},
		dataMonitor: {
			title: '实时监测数据',
			desc: '系统可根据不同绿化植被的需水规律自动运行和关闭，使得植被处于适宜生长环境。同时实时监测周围小环境的空气温湿度、风速、风向、降雨和光照度等信息，并把这些数据传输给服务器，为灌溉和管理决策提供参考依据。',
		},
		cutCost: {
			title: '实现降本增效',
			desc: '面向城市管理局、园林绿化管理部门、养护单位等，覆盖了园林绿化移动巡查、绿地设施管养管护、绿化工程项目管理、考核评价等多项业务，优化各环节工作效率，降低巡查和养护成本，提升经济效益。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		qinHanNewCity: {
			title: '秦汉新城智慧园林建设',
			introduction: '秦汉新城位于西咸新区的几何中心，总规划面积302平方公里，其中城市建设用地约50平方公里，遗址保护区104平方 公里，下辖6个街办（镇）。秦汉新城与西安隔渭河相望，规划按照区域统筹、梯度推进、弹性发展的原则，切实践行《西咸新区总体规划》的发展理念，确定新城整体发展模式为“城市组团+绿色廊道+优美小镇”。将帝陵遗址、农田、生态绿地等作为分隔城市组团的生态屏障，通过城市建设与陵、水、田、村等自然人文要素的充分协调，实现城市与乡村的高度融合。规划形成“一轴双核、三带四区”的总体结构。',
			point1: '通过智慧园林建设带来的效果，将原来的管理由“粗犷”变到细化：',
			point2: '节约喷准人力、用水等综合成本52.9%',
			point3: '减少能耗15%',
			point4: '垃圾回收时间减少50%',
			point5: '苗木成活率提升至95%以上',
			point6: '游客数提升30%',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
