const routes = {
	Root: '/',

	// Home.
	Home: '/home',

	// Product.
	UniIO: '/uniio',
	OrcaFS: '/orcafs',
	OrcaBD: '/orcabd',

	// Solutions.
	// Storage solution.
	// General category.
	BigData: '/big-data',
	HighPerformanceComputing: '/high-performance-computing',
	CloudComputingPrivateCloud: '/cloud-computing-private-cloud',
	CloudNativeContainerStorage: '/cloud-native-container-storage',
	DataBackup: '/data-backup',
	// Industry category.
	NewEnergyVehicle: '/new-energy-vehicle',
	IntelligentCampus: '/intelligent-campus',
	IntelligentSecurity: '/intelligent-security',
	AI: '/ai',
	// IoT solution.
	// Intelligent police category.
	BrightProject: '/bright-project',
	MajorEventSecurity: '/major-event-security',
	// Intelligent community category.
	IntelligentCommunity: '/intelligent-community',
	IntelligentCommunitySecurity: '/intelligent-community-security',
	IntelligentElevator: '/intelligent-elevator',
	HighAltitudeThrowObject: '/high-altitude-throw-object',
	// Intelligent park category.
	IntelligentPark: '/intelligent-park',
	// Intelligent scenic spot category.
	IntelligentScenicSpot: '/intelligent-scenic-spot',
	AllAreaTourism: '/all-area-tourism',
	// Carbon neutrality category.
	CarbonNeutrality: '/carbon-neutrality',
	// Intelligent garden category.
	IntelligentGarden: '/intelligent-garden',
	// Emergency category.
	EmergencyCommand: '/emergency-command',
	SafetyProductionEmergency: '/safety-production-emergency',
	// General category.
	IoTPlatform: '/iot-platform',

	// News.
	FirmNews: '/firm-news',
	FirmNewsDetail: '/firm-news-detail',

	// Technical Support.
	Document: '/document',
	DocumentDetail: '/document-detail',
	AfterSalesCase: '/after-sales-case',
	ImageGallery: '/image-gallery',
	ImageGalleryDetail: '/image-gallery-detail',
	Guarantee: '/guarantee',

	// About.
	AboutUs: '/about-us',
	// Leadership: '/leadership',
	Honor: '/honor',
	Recruitment: '/recruitment',
	ContactUs: '/contact-us',

	// Only for access statistics, not real page route.
	SignUp: '/sign-up',
	SignIn: '/sign-in',
	ResetPassword: '/reset-password',
	ApplyTrial: '/apply-trial',
	ApplyJob: '/apply-job',

	// Not found.
	NotFound: '/not-found',

	// Three.js demo.
	Mountain: '/three/mountain',
};

export default routes;

export const routeToMenu: Record<string, string[]> = {
	Home: [
		routes.Home,
	],
	Product: [
		routes.UniIO, routes.OrcaBD, routes.OrcaFS,
	],
	Solution: [
		// Storage solution.
		// General category.
		routes.BigData, routes.HighPerformanceComputing, routes.CloudComputingPrivateCloud, routes.CloudNativeContainerStorage, routes.DataBackup,
		// Industry category.
		routes.NewEnergyVehicle, routes.IntelligentCampus, routes.IntelligentSecurity, routes.AI,

		// IoT solution.
		// Intelligent police category.
		routes.BrightProject, routes.MajorEventSecurity,
		// Intelligent community category.
		routes.IntelligentCommunity, routes.IntelligentCommunitySecurity, routes.IntelligentElevator, routes.HighAltitudeThrowObject,
		// Intelligent park category.
		routes.IntelligentPark,
		// Intelligent scenic spot category.
		routes.IntelligentScenicSpot, routes.AllAreaTourism,
		// Carbon neutrality category.
		routes.CarbonNeutrality,
		// Intelligent garden category.
		routes.IntelligentGarden,
		// Emergency category.
		routes.EmergencyCommand, routes.SafetyProductionEmergency,
		// General category.
		routes.IoTPlatform,
	],
	News: [
		routes.FirmNews, routes.FirmNewsDetail,
	],
	TechnicalSupport: [
		routes.Document, routes.DocumentDetail, routes.AfterSalesCase,
		routes.ImageGallery, routes.ImageGalleryDetail, routes.Guarantee,
	],
	About: [
		routes.AboutUs, /*routes.Leadership, */routes.Honor, routes.Recruitment, routes.ContactUs,
	],
};
