import React, {FC, useMemo} from 'react';

const OOSPlaceholder: FC = () => {
    return (
        <div className="oos-placeholder">
			<div className="placeholder-banner" />
			<div className="placeholder-content" />
        </div>
    );
};

export default OOSPlaceholder;
