const zhCN = {
	documentTitle: 'UniIO 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: 'OrcaStor UniIO全闪存分布式存储',
		subTitle: 'OrcaStor UniIO是一款面向云计算领域的全闪存分布式存储产品。',
	},
	introduction: {
		title: '产品介绍',
		subTitle: '产品的功能特性、定位与应用方向',
		name: 'OrcaStor UniIO全闪存分布式存储',
		desc1: 'OrcaStor UniIO是一款面向云计算领域的全闪存分布式存储产品，采用 Scale-out 分布式架构，可实现大规模横向扩展。通过将闪存资源组织起来构建全局闪存资源池，按需为上层应用提供块存储服务和文件存储服务，可以同时支持各种数据库负载、虚拟化应用和云原生应用，满足关键业务和形态各异的众多应用的不同存储需求。',
		desc2: '块存储服务：提供iSCSI标准访问接口协议，为用户提供结构化的数据存储资源，提供高性能与高扩展能力，满足多种客户端、数据库场景的块存储需求。文件存储服务：提供NFS和CIFS标准接口，为用户提供非结构化的数据共享存储资源，以卓越性能、大规模横向扩展能力和超大规模分布式文件存储满足视频/音频、高性能计算、视频监控等多业务场景的需求。',
		scenario: {
			virtualization: '虚拟化',
			containerCloud: '容器云',
			cloudComputing: '云计算',
			privateCloud: '私有云',
			fiveG: '5G应用',
			database: '数据库',
		},
		feature: {
			deduplication: {
				name: '重删压缩',
				desc: 'UniIO支持全局在线重删，确保I/0写入存储系统前去重，减少下游I/0和CPU负载。重复数据删除和数据压缩结合使用，数据缩减比高达10：1，有效減少写入SSD盘的数据量，提升存储空间利用率。使用重删压缩特性可以减少冗余数据带来的维护成本，降低企业的TCO。',
			},
			qos: {
				name: '基于应用的QoS',
				desc: 'UniIO支持应用级的QoS特性，用户可以为不同的卷设置不同的QoS策略，存储系统根据性能指标分配对应的CPU、内存等资源，消除了业务间的“扰邻”现象，保证高优先级业务负载。丰富的QoS策略使存储系统能够持续为数百个应用程序提供性能，享受流畅的用户体验。',
			},
			scale: {
				name: '弹性伸缩',
				desc: 'UniIO采用DHT架构管理数据，使得存储系统具备大规模的线性扩展能力。支持成本可控的中小规模部署并可按需扩展为上千节点的大规模集群，提供容量性能线性增长的能力，更快响应不断变化的业务需求。',
			},
			remoteReplication: {
				name: '高效的远程复制',
				desc: 'UniIO支持创建集群间卷的异步复制关系，将生产中心的数据周期性同步到异地灾备中心，节省了网络带宽，复制开销降到最低。通过一对一或一对多的数据复制关系，可高效构建两地或多地容灾中心，最大程度保障用户任务连续性和数据可用性。',
			},
			performance: {
				name: '极致性能',
				desc: 'UniIO采用高效的SCM技术，使用非易失性存储介质作为缓存，数据写入时落入非易失性缓存，缩短IO流程，大幅提升写性能；读取数据时，系统将经常访问的数据放在非易失性缓存中，缩短数据寻址时间，大幅提升读性能。确保单节点随机读写性能达10+万IOPS和低于1.2ms的网络时延。',
			},
			lowCarbon: {
				name: '低碳环保',
				desc: 'UniIO采用1U10盘位机架式服务器，结合在线重删压缩技术，相比于传统阵列，可以实现77%的能耗节省和89%的空间节约比。实现服务器的高性能低功耗，助力数据中心的节能减排。',
			},
			localization: {
				name: '国产化平台',
				desc: 'UnilO采用飞腾平台，基于国产自主可控的硬件开发，采用主流NVMe介质替换持久化内存组件，解耦原系统对SCM的强制依赖关系。通过采用自主研发的架构设计与重构技术，确保在同等规格下，国产系统性能接近Intel平台。同时支持大粒度对象，进一步优化飞腾产品的整体成本，实现在基本一致的硬件规格下支持更大的存储空间。',
			},
			cloud: {
				name: '全面云化',
				desc: 'UniIO对外提供对接各类虚拟化、云平台的标准接口，帮助用户在云环境下实现数据的高效流通，实现传统业务无缝迁移至云端。提供restful API对接各类云管理平台、容器编辑器，实现对存储系统的管理，方便云平台的运维使用。实现无缝对接OpenStack、容器平台。',
			},
		}
	},
	scenario: {
		title: '典型应用场景',
		subTitle: '还原真实的客户使用场景',
		virtualization: {
			name: '虚拟化',
			desc: '虚拟化场景存在启动风暴、登陆风暴、病毒扫描、补丁更新、桌面重构、刷新等操作，导致用户体验差、运维耗时长等问题。',
			ability1: '持续稳定的高性能和1.2ms的稳定低时延。',
			ability2: '将启动风暴由30分钟缩减至8分钟，缩减约75%。',
			ability3: '将办公应用响应时间缩短约50%。',
			ability4: '有效缩短规划与部署时间，2000用户仅需8小时即可部署完成，提升运维效率2倍。',
		},
		cloudComputing: {
			name: '云计算',
			desc: '云计算场景一般由控制节点、计算节点、网络节点和存储节点组成，要求后端存储系统具有更高的性能、更低的延时以及更灵活的扩展。',
			ability1: '与OpenStack深度集成，无需在OpenStack和存储系统之间增加管理层，UniIO驱动集成到OpenStack中，不需要额外的特性License。',
			ability2: '从裸金属发放到操作系统安装完成30分钟，配置OpenStack需要不到30分钟。',
			ability3: '计算和存储节点可按需扩展、在线扩容。',
			ability4: '部署1000个实例只需2.5小时，删除1000个实例只需11分钟，启动1000个卷作为新实例只需2.25小时，删除1000个实例和卷只需17分钟。',
		},
		containerCloud: {
			name: '容器云',
			desc: '容器云场景存在容器存储持久化、多Pod间数据共享、可用性、跨节点重建访问元数据以及维护难度大等问题。',
			ability1: '资源的弹性伸缩，标准CSI插件和k8s无缝对接，存储资源简单，并且根据需求合理分配，促使资源高效利用。',
			ability2: '快照、克隆、多副本技术保证数据高可靠。',
			ability3: '在线全局重删压缩功能，提高有效存储空间，降低TCO。',
			ability4: '统一的Web管理，部署简单，自动化运维，实现智能运维、降低管理难度。',
		},
		privateCloud: {
			name: '私有云',
			desc: '支持Scale-Out弹性按需扩展能力、在线扩容不影响前端业务运行。',
			ability1: '支持Scale-Out弹性按需扩展能力、在线扩容不影响前端业务运行。',
			ability2: '多副本数据保护功能，有效避免存储设备损坏或宕机造成的数据丢失。',
			ability3: '自动精简配置、全局重删压缩功能，有效提升存储空间利用率，降低成本。',
			ability4: '容量监控功能，可实时监控容量使用情况，避免由于容量超出导致业务运行中断，数据丢失。',
		},
		fiveG: {
			name: '5G应用',
			desc: '5G应用场景一般要求存储系统能够满足数据指数级增长的存储需求，为视频与文字、数字类信息提供不同的存储模式，并能匹配高速率、大容量、低时延的5G网络。',
			ability1: '采用分布式架构，具有弹性扩展能力，满足不断增长的数据存储需求。',
			ability2: '同时支持块存储和文件存储，满足结构化和非结构化的数据存储要求。',
			ability3: '性能和容量随节点数量增加线性增长，提供高IOPS、低时延的卓越性能。',
			ability4: '支持基于应用的QoS功能，避免“扰邻”情况发生，确保关键应用获得足够的硬件资源和性能需求。',
		},
		database: {
			name: '数据库',
			desc: '数据库场景一般要求存储系统能满足OLTP和OLAP模式的企业级数据性能及容量要求，减少分配不均，实现弹性、可控的数据存储池。',
			ability1: '采用分布式架构，数据均衡分布在每个节点上，确保节点负载、链路负载等。',
			ability2: '采用SCM技术，使用非易失性介质作为缓存，确保数据掉电安全的同时，提升读写性能。',
			ability3: '采用Scale-Out横向扩展，性能和容量随节点数量增加线性增长。',
			ability4: '自动精简配置实现按需分配，提升存储空间利用率。',
		},
	},
	specification: {
		title: '技术规格',
		subTitle: '软硬件系统指标',
	},
	resource: {
		title: '相关资源',
		subTitle: '产品有关的文档、图库等资源',
		datasheet: 'Datasheet',
		document: '产品文档',
		imageGallery: '产品图库',
		clickToDownload: '点击下载',
		clickToForward: '点击跳转',
	},
	case: {
		title: '产品案例',
		subTitle: '优质的产品实施与客户服务案例',
		xiXianPoliceDepartment: {
			title: '智慧安防社区建设',
			introduction: '在本次智慧安防社区建设中，涉及各类平台（社区应用平台、大数据平台）、电子围栏系统、人行道闸系统等进行数据采集、转换、汇总、分析、汇报等高频操作。在综合考虑、研判整个社区建设、管理及后期维护升级等因素后，奥卡云提出采用分布式全闪+分布式存储组合模式，在确保高性能、低时延的同时，满足大容量、高可靠的数据存储需求。采用奥卡云的UniIO分布式全闪存存储，实时采集人车结构化数据、人车进出记录、手机MAC数据等，采集后进行清洗、转换，根据制定的战法模型进行数据分析。采用奥卡云的OrcaBD分布式存储，提供对象存储服务和块存储服务，并将各类结构、非结构化数据存储到统一存储池中。采用万兆网络作为业务网络，为业务提供快速的网络通道、提高性能、降低网络时延。',
			point1: '客户收益：',
			point2: '快速数据处理：通过分布式全闪存存储，提升数据分析能力、制定及时、高效的战法模型。',
			point3: '统一数据存储：通过分布式存储的统一资源池，将各类数据统一存储，消除“数据孤岛”。',
			point4: '降低TCO：通过在线重删压缩技术，提升有效存储空间，节省机房物理空间、减少能耗，降低TCO。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
