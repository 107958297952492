export const formatTime = (
	date: string,
	fmt: string = 'yyyy-MM-dd hh:mm:ss'
): string => {
	if (!date) {
		return '--';
	}

	const dateOrigin = new Date(date);

	const o: Record<string, number> = {
		'M+': dateOrigin.getMonth() + 1,
		'd+': dateOrigin.getDate(),
		'h+': dateOrigin.getHours(),
		'm+': dateOrigin.getMinutes(),
		's+': dateOrigin.getSeconds(),
		'q+': Math.floor((dateOrigin.getMonth() + 3) / 3),
		'S': dateOrigin.getMilliseconds()
	};

	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, String(dateOrigin.getFullYear()).substr(4 - RegExp.$1.length));
	}

	for (let k in o) {
		if (new RegExp('('+ k +')').test(fmt)) {
			let t = o[k];

			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? String(t) : (('00' + t).substr(('' + t).length)));
		}
	}
	return fmt;
};
