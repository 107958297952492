import React, {useCallback} from 'react';
import modalAction from '@actions/modalAction';
import {useDispatch, useI18n, useNavigate} from '@hooks';
import {ckGet, SERVICE_HOTLINE} from '@utils';
import routes from '@routes';
import ER_CODE_PNG from '@images/bar/er_code.webp';

export default function FootBar (){
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('FootBar');

	// Router.
	const navigate = useNavigate();

	// Redux related.
	const dispatch = useDispatch();

	const setSignInVisible = useCallback((visible: boolean) => dispatch(modalAction.setSignInVisible(visible)), [dispatch]);

	const setApplyTrialVisible = useCallback((visible: boolean) => dispatch(modalAction.setApplyTrialVisible(visible)), [dispatch]);

	// Page actions.
	const onNavClick = useCallback((path: string) => navigate(path), [navigate]);

	const onApplyTrialClick = useCallback(() => {
		let isSignedIn = ckGet('oos_token');
		if (!isSignedIn) {
			alert(i18nGen('doSignInFirst'));
			setSignInVisible(true)
		} else {
			setApplyTrialVisible(true);
		}
	}, [setSignInVisible, setApplyTrialVisible, i18nGen]);

	return (
		<div className="oos-foot-bar">
			<div className="foot-bar-content">
				<div className="nav-box">
					<div className="nav-section product">
						<div className="title">{i18n('product.title')}</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.UniIO)}
						>
							{i18n('product.uniio')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.OrcaFS)}
						>
							{i18n('product.orcafs')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.OrcaBD)}
						>
							{i18n('product.orcabd')}
						</div>
					</div>
					<div className="nav-section">
						<div className="title">{i18n('solution.title')}</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.HighPerformanceComputing)}
						>
							{i18n('solution.highPerformanceComputing')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.AI)}
						>
							{i18n('solution.ai')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.IoTPlatform)}
						>
							{i18n('solution.iotPlatform')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.IntelligentGarden)}
						>
							{i18n('solution.intelligentGarden')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.IntelligentCommunitySecurity)}
						>
							{i18n('solution.intelligentCommunitySecurity')}
						</div>
					</div>
					<div className="nav-section">
						<div className="title">{i18n('cooperation.title')}</div>
						<div
							className="item"
							onClick={onApplyTrialClick}
						>
							{i18nGen('applyTrial')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.Document)}
						>
							{i18n('cooperation.document')}
						</div>
					</div>
					<div className="nav-section">
						<div className="title">{i18n('information.title')}</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.FirmNews)}
						>
							{i18n('information.firmNews')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.AboutUs)}
						>
							{i18n('information.aboutUs')}
						</div>
						{/*
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.Leadership)}
						>
							{i18n('information.leadership')}
						</div>
						*/}
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.Honor)}
						>
							{i18n('information.honor')}
						</div>
						<div
							className="item"
							onClick={onNavClick.bind(null, routes.ContactUs)}
						>
							{i18n('information.contactUs')}
						</div>
					</div>
				</div>
				<div className="contact-box">
					<img className="er-code-img" alt="奥卡云二维码" src={ER_CODE_PNG} />
					<div className="contact-text-content">
						<div className="er-code-title">{i18n('weChat.title')}</div>
						<div className="er-code-desc">{i18n('weChat.follow')}</div>
						<div className="phone">{SERVICE_HOTLINE}</div>
						<div className="phone-time">{i18n('phoneTime')}</div>
						<div className="location">{i18n('location')}</div>
					</div>
				</div>
				<div className="copyright-and-record">
					<span className="copyright">
						{i18n('copyright')}<span className="divider">&nbsp;|&nbsp;</span>
					</span>
					<span className="record">
						<a target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61040402000225">陕公网安备61040402000225号</a>&nbsp;|&nbsp;
						<a target="_blank" rel="noopener noreferrer" href="http://www.beian.miit.gov.cn">陕ICP备18011301号</a>&nbsp;|&nbsp;
						<a target="_blank" rel="noopener noreferrer" href="/privacy_statement.html">{i18n('privacyStatement')}</a>
					</span>
				</div>
			</div>
		</div>
	);
}
