import {ActionCreatorSet} from '../interfaces';

export const productActionTypes = {
	SET_PRODUCT_LIST: '@PRODUCT/SET_PRODUCT_LIST',
	SET_AFTER_SALES_CASE_LIST: '@PRODUCT/SET_AFTER_SALES_CASE_LIST',
	SET_DOCUMENT_LIST: '@PRODUCT/SET_DOCUMENT_LIST',
	SET_DOCUMENT_DETAIL: '@PRODUCT/SET_DOCUMENT_DETAIL',
	SET_IMAGE_GALLERY_LIST: '@PRODUCT/SET_IMAGE_GALLERY_LIST',
	SET_IMAGE_GALLERY_DETAIL: '@PRODUCT/SET_IMAGE_GALLERY_DETAIL',
	SET_CERTIFICATE_LIST: '@PRODUCT/SET_CERTIFICATE_LIST',
};

const productAction: ActionCreatorSet = {
	setProductList: productList => ({
		type: productActionTypes.SET_PRODUCT_LIST,
		productList
	}),

	setAfterSalesCaseList: afterSalesCaseList => ({
		type: productActionTypes.SET_AFTER_SALES_CASE_LIST,
		afterSalesCaseList
	}),

	setDocumentList: documentList => ({
		type: productActionTypes.SET_DOCUMENT_LIST,
		documentList
	}),

	setDocumentDetail: documentDetail => ({
		type: productActionTypes.SET_DOCUMENT_DETAIL,
		documentDetail
	}),

	setImageGalleryList: imageGalleryList => ({
		type: productActionTypes.SET_IMAGE_GALLERY_LIST,
		imageGalleryList
	}),

	setImageGalleryDetail: imageGalleryDetail => ({
		type: productActionTypes.SET_IMAGE_GALLERY_DETAIL,
		imageGalleryDetail
	}),

	setCertificateList: certificateList => ({
		type: productActionTypes.SET_CERTIFICATE_LIST,
		certificateList
	}),
};

export default productAction;
