export interface HTTPWorkerError {
	url: string;
	code: string | number;
	msg: string;
}

export interface FetchOption {
	url: string;
	reqInit: RequestInit;
}

export interface TaskTimeout {
	time: number;
	text: string;
}

export interface TaskExtraArg {
	processor?: string;
	preArg?: any;
	dataMapKey?: string;
	timeout?: TaskTimeout;
	stream?: boolean;
}

export type HttpMethod =
	// Only the methods we will use.
	| 'GET'
	| 'POST'
	| 'PUT'
	| 'DELETE';

export function isHeadersRecord (header: any): header is Record<string, string> {
	return 'Content-Type' in header;
}

export interface FetchExceptionFromBrowser {
	msg: string
}

export function isFetchExceptionFromBrowser (e: any): e is FetchExceptionFromBrowser {
	return 'msg' in e;
}

export interface HttpREST {
	url: string;
	method: HttpMethod;
	reqInit: RequestInit;
	extraArg: TaskExtraArg;
	init(...conf: (string | Record<string, any>)[]): HttpREST;
	preprocess(processor: string, preArg?: any): HttpREST;
	equal(dataMapKey: string): HttpREST;
	timeout(conf: {time: number, text: string}): HttpREST | null;
	if(flag: boolean | string, func: (h: HttpREST) => HttpREST): HttpREST | null;
	asStream(): HttpREST;
	reset(): void;
	send(): Promise<any> | null;
}

export interface HttpRESTConstructor {
	new(method: HttpMethod): HttpREST;
}

export interface ThreadData {
	url: string;
	data?: any;
	code: string | number;
	msg: string
}

export interface ReqPromise {
	resolve(data: ThreadData['data']): void;
	reject(err: Omit<ThreadData, 'data'>): void;
}

export type DataPreprocessor = Record<string, (...args: any) => any>;
