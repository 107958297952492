const pureGetLocalIndexMap = {
	zhCN: 0,
	enUS: 1,
};

const genPureGet = (locale: 'zhCN' | 'enUS') => (arr: any[]) => arr[pureGetLocalIndexMap[locale]]

const zhCN = {
	pureGet: genPureGet('zhCN'),
	requestFailed: 'HTTP请求失败，请检查您的网络连接和集群状态！',
	ok: '确定',
	cancel: '取消',
	doSignInFirst: '您还未登录，请先登录！',
	signIn: '登录',
	signUp: '注册',
	signOut: '注销',
	applyTrial: '申请试用',
	applyTrialTips: '有想法，点击按钮，立即申请产品试用！',
	viewDetail: '了解详情',
	viewMore: '查看更多',
	viewDocument: '查看文档',
	downloadDocument: '下载文档',
	loading: '加载中...',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
