const zhCN = {
	documentTitle: '企业荣誉 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '企业荣誉',
		subTitle: '质量是企业生存的基础，创新是企业竞争的动力',
	},
	qualification: {
		title: '资质证书',
		subTitle: '企业在管理水平、技术水平、生产能力等方面的综合实力证明',
	},
	inventionPatent: {
		title: '发明专利证书',
		subTitle: '企业发明创造的新技术方案',
	},
	softwareCopyright: {
		title: '软件著作证书',
		subTitle: '企业高新技术的发展与员工才智的体现',
	},
	appearancePatent: {
		title: '外观设计专利证书',
		subTitle: '企业创造的工业应用新设计',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
