import React, {FC, useCallback} from 'react';
import {useI18n, useNavigate} from '@hooks';
import {ssSet} from '@utils';
import routes from '@routes';

const Case: FC = () => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('Home', 'case');

	// Router.
	const navigate = useNavigate();

	// Page actions.
	const onViewProductCaseClick = useCallback((path: string) => {
		ssSet('oos_productForwardSection', 'case');
		navigate(path);
	}, [navigate]);

	const onViewSolutionCaseClick = useCallback((path: string) => {
		ssSet('oos_solutionForwardSection', 'case');
		navigate(path);
	}, [navigate]);

	return (
		<>
			<div className="section-title home-case-title">
				<div className="title">{i18n('title')}</div>
				<div className="sub-title">{i18n('subTitle')}</div>
			</div>
			<div className="case">
				<div className="case-box">
					<div
						className="case-item"
						onClick={onViewProductCaseClick.bind(null, routes.UniIO)}
					>
						<div className="case-img intelligent-security-community" />
						<div className="case-content">
							<i className="angle top-left" />
							<i className="angle bottom-right" />
							<div className="case-title-wrap">
								<div className="case-title-text">{i18n('xiXianPoliceDepartment.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="case-text-content">{i18n('xiXianPoliceDepartment.introduction')}</div>
						</div>
					</div>
					<div
						className="case-item"
						onClick={onViewSolutionCaseClick.bind(null, routes.IntelligentCommunitySecurity)}
					>
						<div className="case-img intelligent-community-security" />
						<div className="case-content">
							<i className="angle top-left" />
							<i className="angle bottom-right" />
							<div className="case-title-wrap">
								<div className="case-title-text">{i18n('qinHanNewCityWeiLiuJiaYuanIntelligentCommunity.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="case-text-content">{i18n('qinHanNewCityWeiLiuJiaYuanIntelligentCommunity.introduction')}</div>
						</div>
					</div>
					<div
						className="case-item"
						onClick={onViewSolutionCaseClick.bind(null, routes.AllAreaTourism)}
					>
						<div className="case-img all-area-tourism" />
						<div className="case-content">
							<i className="angle top-left" />
							<i className="angle bottom-right" />
							<div className="case-title-wrap">
								<div className="case-title-text">{i18n('qinHanNewCityAllAreaTourism.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="case-text-content">{i18n('qinHanNewCityAllAreaTourism.introduction')}</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Case;
