import React, {FC, MouseEvent, useCallback, useMemo, useRef, useState} from 'react';
import modalAction from '@actions/modalAction';
import {useDispatch, useI18n} from '@hooks';
import {OOSModal} from '@components';
import {DOM, validateMobilePhone, validateTelephone} from '@utils';
import httpCaller from '@http/caller';
import {handleFetchExceptionMsg} from '@http/fetch/httpHelper';
import {CreateConsultationDto} from '@http/dto/consultation';

const ContactUs: FC = () => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('Home', 'contactUs');

	// Redux related.
	const dispatch = useDispatch();

	const setMaskVisible = useCallback((visible: boolean) => dispatch(modalAction.setMaskVisible(visible)), [dispatch]);

	// Internal states.
	const [loading, setLoading] = useState(false);
	const [sendOkVisible, setSendOkVisible] = useState(false);

	// Ref data.
	const fullnameInputRef = useRef<HTMLInputElement | null>(null);
	const phoneInputRef = useRef<HTMLInputElement | null>(null);
	const consultationInputRef = useRef<HTMLTextAreaElement | null>(null);
	const statementCheckboxRef = useRef<HTMLInputElement | null>(null);

	// Page actions.
	const onSubmitClick = useCallback(async (e: MouseEvent<HTMLDivElement>) => {
		// Prevent the form default behavior.
		e.preventDefault();

		// The form here is not a regular form as others in a Modal,
		// so we do not use the validation and state management of OOSForm here.
		if (!loading) {
			// Trim input values.
			let fullname = fullnameInputRef.current ? fullnameInputRef.current.value.trim() : '';
			let phone = phoneInputRef.current ? phoneInputRef.current.value.trim() : '';
			let consultation = consultationInputRef.current ? consultationInputRef.current.value.trim() : '';
			let readStatement = statementCheckboxRef.current ? statementCheckboxRef.current.checked : false;

			// Check form validation.
			if (!fullname) {
				return alert(i18n('validation.fullnameEmpty'));
			}
			if (!validateMobilePhone(phone) && !validateTelephone(phone)) {
				return alert(i18n('validation.phoneIncorrect'));
			}
			if (!consultation) {
				return alert(i18n('validation.consultationEmpty'));
			}
			if (consultation.length < 10) {
				return alert(i18n('validation.consultationIsShort'));
			}
			if (!readStatement) {
				return alert(i18n('validation.readPrivacyStatement'));
			}

			setLoading(true);

			let user: CreateConsultationDto = {
				fullname,
				phone,
				content: consultation,
				source: 'orcadt',
			};

			try {
				await httpCaller.createConsult(user);
				setLoading(false);
				setMaskVisible(true);
				setSendOkVisible(true);
				// Clear form.
				if (
					fullnameInputRef.current !== null &&
					phoneInputRef.current !== null &&
					consultationInputRef.current !== null &&
					statementCheckboxRef.current !== null
				) {
					fullnameInputRef.current.value = '';
					phoneInputRef.current.value = '';
					consultationInputRef.current.value = '';
					statementCheckboxRef.current.checked = false;
				}
			} catch (e) {
				setLoading(false);
				alert(i18n('actionFails') + handleFetchExceptionMsg(e));
			}
		}
	}, [loading, setMaskVisible, i18n]);

	const onHideSendOkClick = useCallback(() => {
		setMaskVisible(false);
		setSendOkVisible(false);
		// Reset html class, allow html content scrolling.
		const bodyDOM = DOM.getBody();
		DOM.removeClass(bodyDOM, 'scroll-disabled');
	}, [setMaskVisible]);

	// Memorized descendant components.
	const SendOkModalMemo = useMemo(() => (
		sendOkVisible ?
			(
				<OOSModal width={340}>
					<div className="oos-modal-success-tips-box">
						<div className="success-img" />
						<div className="success-title">{i18n('actionOk')}</div>
						<div className="success-desc">{i18n('actionOkTips')}</div>
						<div className="success-ok-button" onClick={onHideSendOkClick}>{i18nGen('ok')}</div>
					</div>
				</OOSModal>
			) :
			null
	), [sendOkVisible, onHideSendOkClick, i18n]);

	return (
		<div className="contact-us">
			<div className="section-title">
				<div className="title">{i18n('title')}</div>
				<div className="sub-title">{i18n('subTitle')}</div>
			</div>
			<div className="contact-us-content">
				{/* The form here is not a regular form as others in a Modal, so we do not use the OOSForm here. */}
				<div className="username-phone">
					<input ref={fullnameInputRef} type="text" autoComplete="off" placeholder={i18n('fullnamePlh')} />
					<input ref={phoneInputRef} type="text" autoComplete="off" placeholder={i18n('phonePlh')} />
				</div>
				<textarea className="consultation-input" ref={consultationInputRef} maxLength={200} placeholder={i18n('consultationPlh')} />
				<div className="confirm-privacy-statement">
					<input id="home-statement-checkbox" type="checkbox" ref={statementCheckboxRef} />
					<label htmlFor="home-statement-checkbox">
						<span className="read-statement">{i18n('readAndAgree')}</span>
						<a className="privacy-statement-name" target="_blank" rel="noopener noreferrer" href="/privacy_statement.html">{i18n('privacyStatementName')}</a>
						<span className="store-data">{i18n('dataStore')}</span>
					</label>
				</div>
				<div
					className={`submit-button ${loading ? 'disabled' : ''}`}
					onClick={onSubmitClick}
				>
					{loading ? i18n('submitting') : i18n('submit')}
				</div>
			</div>
			{SendOkModalMemo}
		</div>
	);
};

export default ContactUs;
