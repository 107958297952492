const zhCN = {
	documentTitle: '产品图库 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '产品图库',
		subTitle: '一站式的产品图片、网元图标等图片服务',
	},
	searchPlaceholder: '请输入图库名称进行搜索',
	productFilter: '产品：',
	name: '名称',
	authRequired: '该产品图库需要对应的用户权限才能浏览和下载',
	publishTime: '发布/更新时间',
	actions: '操作',
	viewImage: '查看图库',
	hasNoImage: '该图库暂无图片！',
	imageGalleryEmpty: '相关产品图库即将上线',
	imageGallerySearchEmpty: '暂无搜索结果',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
