const zhCN = {
	documentTitle: '企业资讯详情 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '企业资讯详情',
		subTitle: '关注精彩内容、了解企业动态',
	},
	publishTime: '发布时间：',
	viewCount: '浏览量：',
	relatedNews: '近期资讯',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
