const zhCN = {
	documentTitle: '重要活动安保解决方案  奥卡云数据 - 存储 物联网 解决方',
	banner: {
		title: '重要活动安保解决方案',
		subTitle: '重大活动安保系统为保障大型活动指挥调度的快速和准确，提供了安保资源的可视化展示与应用。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		frequentSafetyIncident: {
			title: '公共安全事件频发',
			desc: '这类大型活动普遍具有点多面广、组织繁杂、时间短期、情况多变、人员密集、物资集中、影响大、规格高等特点，稍有疏忽就容易引起公共安全意外事件。',
		},
		lackOfPrevention: {
			title: '问题繁杂，防控手段不足',
			desc: '各类大型活动举办期间，重大活动安保管理面临不少亟待处理的问题，需要处理的各类态势信息来源复杂，相互交织影响，需要梳理众多信息态势；各种防控手段全面上线，防控力量体系化不足需要整合各种防控手段；基于二维平面化部署，缺乏立体维度支撑，需要直观展示保障演练方案。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '重要活动安保解决方案介绍',
			desc: '重大活动安保系统为保障大型活动指挥调度的快速和准确，提供了安保资源的可视化展示与应用。根据大型活动安保（活动路线、安保防线、停车分流、火灾疏散、视频部署等）实战需求，结合大数据、云计算、物联网、生物识别、人工智能和移动互联网等技术，以及重大突发事件（应急处置预案，采用工作流技术，根据活动需求制定可视化的安保方案，在电子地图上标注警力、车辆、装备、物资、制高点、消防栓、摄像头、道路禁行等标识，对活动中发生的拥挤伤亡、爆炸火灾、抛撒煽动性传单等情况以及突发刑事案件、治安案件等都做到快速掌握情况、快速投入警力、快速采取措施。提高安保部门应急处置能力，实现因情施策、迅速控制。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		oneScreenControl: {
			title: '一屏掌握全局',
			desc: '基于“地理+大屏”概念，实现各安保层级，多种交互页面对感知数据，预警警情，指挥调度及处置反馈等信息态势全局掌控。',
		},
		practicalApplication: {
			title: '实战应用',
			desc: '基于“视频+业务”理念，运用大数据、视频分析、语义分析等主流技术，打造关系、轨迹、特征等维度的实战应用。',
		},
		multiLevelFusion: {
			title: '多层次融合',
			desc: '针对重大活动安保工作中“技防、人防、物防相结合”的智能安保体系建设。有效地协调指挥各层次、各区域、各部门职能人员。充分利用已有的基础设施资源、信息系统资源和数据资源等问题提供信息化手段支撑。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		qinHanNewCityPolice14Sport: {
			title: '秦汉新城公安分局重大赛事活动安保平台',
			introduction: '西咸新区秦汉新城承办了“中华人民共和国第十四届运动会”中小轮车比赛与马术比赛的大型活动，新城公安分局负责该大型活动的安全保卫工作，为确保“十四运秦汉赛区赛事活动”顺利进行，西安奥卡云公司作为本次赛事的技术支撑方为秦汉公安建设了重大赛事活动安保平台，对赛事进行全方位安防管控，形成集“人防、物防、技防”三防结合的安防体系建设，并配合赛事组织者、志愿者、协办单位等形成应急管理小组，对赛事进行全方位安保，最终使“十四运秦汉赛区赛事活动”没有发生安全事故，取得了圆满成功。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
