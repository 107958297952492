const zhCN = {
	documentTitle: '智慧安防解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '智慧安防解决方案',
		subTitle: '智慧安防技术，指的是服务的信息化、图象的传输和存储技术。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		hardToExpansion: {
			title: '视图容量大，节点扩展难',
			desc: '随着各类前端摄像头的图像高清化、数量指数化发展，现有传统存储的容量局限、扩展时效性以及资源规划复杂等问题暴露越发明显。',
		},
		lowEfficiencyOfSolveCase: {
			title: '案件侦破消耗警力，破案效率低',
			desc: '随着智能化、信息化的全面普及，各部门在日常工作中数据交叉共享愈发频繁，特别是在刑侦、经侦案件侦破过程中，办案人员跨地域，跨部门来回，反复调阅各类视图、文档数据成为案件能否快速、及时侦破重中之重。而传统存储的数据孤岛情况，严重拖慢、迟缓了案件的最终侦破。',
		},
		lowReliability: {
			title: '数据长时间安全可靠保存无法保证，设备管理维护复杂',
			desc: '智能安防要求部分重要数据长时间、安全可靠地保存。传统的存储随着数量的增加，一方面在数据保护方面对硬件要求以及与硬件的融合度要求越来越高；另一方面独立的管理维护方式随着设备数量的增加变得复杂而效率低下。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: 'OrcaBD智慧安防方案介绍',
			desc1: '智慧安防技术，指的是服务的信息化、图象的传输和存储技术。其随着科学技术的发展与进步以及二十一世纪信息技术的腾飞已迈入了一个全新的领域，智能化安防技术与计算机之间的界限正在逐步消失。随着高清化、物联化、智能化趋势推动，视图系统对存储设备的高可靠性、易扩展性、极速性能以及智管维提出了更高的要求。',
			desc2: 'OrcaBD采用去中心化的设计，通过软件定义存储技术，将物理上的存储资源融合为一个统一的存储池。OrcaBD分布式存储特性：',
			desc3: '支持单盘10TB容量，支持36盘位高密度主机，实现单主机360TB的大容量存储，提供EB级数据存储。',
			desc4: '数据弹性均匀分布在所有设备上。消除数据孤岛，实现数据透明共享。',
			desc5: '数据设备级冗余保护，支持副本、EC多种数据保护机制。保证数据安全、高可靠性存取。',
			desc6: '设备按需在线扩容，容量性能虽设备数量指数增长。实现前端业务无感知，业务流畅运行，极致体验。',
			desc7: '设备统一智能管理维护，容量极限提前预警，系统和硬盘7*24小时故障检测、智能生成分析报告。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		unifiedResources: {
			title: '统一资源',
			desc: '消除数据孤岛，数据透明共享',
		},
		easyToExpand: {
			title: '设备易扩展',
			desc: '容量性能指数级增长，提升业务流畅体验。',
		},
		intelligentManage: {
			title: '智能管维',
			desc: '缩减管理和维护成本，降低管理和维护复杂度。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '优质的方案实施与客户服务案例',
		intelligentLaboratory: {
			title: 'XXX大学智能化实验室项目',
			introduction: '2019年4月份，陕西省某市的XXX大学针对学校的智能化实验室发展现状和未来发展远景，决定面向外界采购相关设备以满足学校的人工智能发展。奥卡云在得知该校情况后，多次跟校方项目负责人进行沟通。对学校的智能化实验室项目有了深入了解，奥卡云从人工智能化现状、存在的问题，以及客户关注的方向，结合学校的现有资源及现状给出了自己的一整套解决方案。在整个项目的前期、实施、后期维护等全过程，奥卡云都做到想客户所想，做客户所做。充分发挥厂商的专业技术和过硬的产品质量，获得客户的高度认可。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
