import React, {FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import generalAction from '@actions/generalAction';
import modalAction from '@actions/modalAction';
import {useDispatch, useI18n, useSelector, useLocation, useNavigate, useScroll, useScrollToTop, useSearchKeywordMap} from '@hooks';
import ProductDropDownMenu from './ProductDropDownMenu';
import SolutionDropDownMenu from './SolutionDropDownMenu';
import PadAndPhDropDownMenu from './PadAndPhDropDownMenu';
import FloatBar from './FloatBar';
import {OOSModalMask} from '@components';
import SignUp from '@views/SignUp/SignUp';
import SignIn from '@views/SignIn/SignIn';
import ResetPassword from '@views/ResetPassword/ResetPassword';
import ApplyTrial from '@views/ApplyTrial/ApplyTrial';
import {ckGet, ckRemove, DOM, equals} from '@utils';
import httpCaller from '@http/caller';
import {SearchResult} from '@interfaces';
import {State} from '@redux/interfaces';
import routes, {routeToMenu} from 'routes';

interface SelectedState {
	activeSubmenu: string;
	homeBannerNewsCarouselActive: boolean;
	maskVisible: boolean;
	signUpVisible: boolean;
	signInVisible: boolean;
	resetPasswordVisible: boolean;
	applyTrialVisible: boolean;
	applyJobVisible: boolean;
	certificateDetailVisible: boolean;
}

const TopBar: FC = () => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('TopBar');

	// Router.
	const location = useLocation();
	const navigate = useNavigate();

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {activeSubmenu, homeBannerNewsCarouselActive}, modal: {maskVisible, signUpVisible, signInVisible, resetPasswordVisible, applyTrialVisible, applyJobVisible, certificateDetailVisible}} = state;
		return {activeSubmenu, homeBannerNewsCarouselActive, maskVisible, signUpVisible, signInVisible, resetPasswordVisible, applyTrialVisible, applyJobVisible, certificateDetailVisible};
	}, []);

	const {activeSubmenu, homeBannerNewsCarouselActive, maskVisible, signUpVisible, signInVisible, resetPasswordVisible, applyTrialVisible, applyJobVisible, certificateDetailVisible} = useSelector<State, SelectedState>(selectState, equals);

	const dispatch = useDispatch();

	const setSignUpVisible = useCallback((visible: boolean) => dispatch(modalAction.setSignUpVisible(visible)), [dispatch]);

	const setSignInVisible = useCallback((visible: boolean) => dispatch(modalAction.setSignInVisible(visible)), [dispatch]);

	const setResetPasswordVisible = useCallback((visible: boolean) => dispatch(modalAction.setResetPasswordVisible(visible)), [dispatch]);

	const setApplyTrialVisible = useCallback((visible: boolean) => dispatch(modalAction.setApplyTrialVisible(visible)), [dispatch]);

	const setActiveSubmenu = useCallback((activeSubmenu: string) => dispatch(generalAction.setActiveSubmenu(activeSubmenu)), [dispatch]);

	const setPadAndPhDropDownMenuActive = useCallback((padAndPhDropDownMenuActive: boolean) => dispatch(generalAction.setPadAndPhDropDownMenuActive(padAndPhDropDownMenuActive)), [dispatch]);

	// Internal states.
	const [activeMenu, setActiveMenu] = useState<string>('');
	const [activePage, setActivePage] = useState<string>('');
	const [searchActive, setSearchActive] = useState<boolean>(false);
	const [resultVisible, setResultVisible] = useState<boolean>(false);
	const [searchResultArr, setSearchResultArr] = useState<SearchResult[]>([]);
	const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
	const [floatBarVisible, setFloatBarVisible] = useState<boolean>(false);

	// Ref data.
	const searchInputRef = useRef<HTMLInputElement | null>(null);
	const expandMenuTimerRef = useRef<null | number>(null);

	// Memorized data.
	const keywordMapMemo = useSearchKeywordMap();

	// Page actions.
	const onScroll = useCallback(() => {
		let scrollTop = DOM.getScrollTop();
		// Update float bar visibility.
		if (scrollTop > 600) { // 600px is banner's height.
			setFloatBarVisible(true);
		} else {
			setFloatBarVisible(false);
		}
	}, []);

	const setActivePageAndMenuByPath = useCallback(() => {
		let pathname = location.pathname;
        // Remove sub-path for querying.
		let slashSplitStr = pathname.split('/');
        if (slashSplitStr.length > 2) {
        	pathname = '/' + slashSplitStr[1];
		}
        Object.keys(routeToMenu).forEach(menu => {
            let paths = routeToMenu[menu];
            if (paths.includes(pathname)) {
                setActiveMenu(menu);
                // Handle special routes.
                if (pathname === routes.DocumentDetail) {
                	pathname = routes.Document;
				}
                if (pathname === routes.ImageGalleryDetail) {
                	pathname = routes.ImageGallery;
				}
			 	if (pathname === routes.FirmNewsDetail) {
                	pathname = routes.FirmNews;
				}
                setActivePage(pathname);
            }
        });
    }, [location]);

	const onSubmenuMouseOver = useCallback((subMenu: string) => setActiveSubmenu(subMenu), [setActiveSubmenu]);

	const onSubmenuMouseOut = useCallback(() => setActiveSubmenu(''), [setActiveSubmenu]);

	const onForwardHomeClick = useCallback(() => {
		// Forward to the Home by the way of refreshing the page.
		// This is the workaround to temporarily fix 3D globe issue when the Canvas DOM is unmounted.
		if (
			(window.location.pathname !== routes.Home) &&
			(window.location.pathname !== (routes.Home + '/'))
		) {
			window.location.assign(routes.Home);
		}
	}, []);

	const onSubMenuClick = useCallback((path: string) => {
		if (location.pathname !== path) {
			navigate(path);
		}
	}, [location, navigate]);

	const onInputFocus = useCallback(() => {
		setSearchActive(true);
	}, []);

	const onInputChange = useCallback(() => {
		setResultVisible(false);
		setSearchResultArr([]);
	}, []);

	const onSearchClick = useCallback(() => {
		let text = searchInputRef.current ? searchInputRef.current.value.trim() : '';
		if (!text) {
			return alert(i18n('search.searchEmpty'));
		}
		text = text.toLowerCase();
		let newSearchResultArr: SearchResult[] = [];
		Object.keys(keywordMapMemo).forEach(keyword => {
			if (keyword.includes(text)) {
				newSearchResultArr.push(keywordMapMemo[keyword]);
			}
		});
		setSearchResultArr(newSearchResultArr);
		setResultVisible(true);
	}, [keywordMapMemo, i18n]);

	const onShowSearchClick = useCallback(() => {
		setSearchActive(true);
		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, []);

	const onInputKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key.toLowerCase() === 'enter') {
			onSearchClick();
		}
	}, [onSearchClick]);

	const onForwardContentClick = useCallback((path: string) => {
		setResultVisible(false);
		setSearchResultArr([]);
		onSubMenuClick(path);
	}, [onSubMenuClick]);

	const onShowSignUpClick = useCallback(() => setSignUpVisible(true), [setSignUpVisible]);

	const hideSignUp = useCallback(() => setSignUpVisible(false), [setSignUpVisible]);

	const onShowSignInClick = useCallback(() => setSignInVisible(true), [setSignInVisible]);

	const hideSignIn = useCallback(() => setSignInVisible(false), [setSignInVisible]);

	const onShowResetPasswordClick = useCallback(() => setResetPasswordVisible(true), [setResetPasswordVisible]);

	const hideResetPassword = useCallback(() => setResetPasswordVisible(false), [setResetPasswordVisible]);

	const onShowApplyTrialClick = useCallback(() => {
		if (isSignedIn) {
			setApplyTrialVisible(true);
		} else {
			alert(i18nGen('doSignInFirst'));
			setSignInVisible(true)
		}
	}, [setSignInVisible, setApplyTrialVisible, isSignedIn, i18nGen]);

	const hideApplyTrial = useCallback(() => setApplyTrialVisible(false), [setApplyTrialVisible]);

	const onSignOutClick = useCallback(async () => {
		await httpCaller.signOut();
		// Confirm singed in related cookies are cleared.
		ckRemove('oos_sessionID');
		ckRemove('oos_user');
		ckRemove('oos_token');
		window.location.reload();
	}, []);

	const onSignClick = useCallback(() => {
		if (!isSignedIn) {
			onShowSignInClick();
		} else {
			onSignOutClick();
		}
	}, [isSignedIn, onShowSignInClick, onSignOutClick]);

	const onExpandPadAndPhDropDownMenuClick = useCallback(() => {
		const bodyDOM = DOM.getBody();
		DOM.addClass(bodyDOM, 'scroll-disabled');
		expandMenuTimerRef.current = window.setTimeout(() => setPadAndPhDropDownMenuActive(true), bodyDOM.scrollTop ? 300 : 0);
	}, [setPadAndPhDropDownMenuActive]);

	// After rendering and re-rendering.
	useScroll(onScroll);

	useScrollToTop(); // Scroll to top when URL hash changes(page jumping through router).

 	useEffect(() => {
        // Immediately call it when component did-mount to set active items.
        setActivePageAndMenuByPath();

        // Bind a hash change event listener to set active items.
        window.addEventListener('hashchange', setActivePageAndMenuByPath);

        // Remove the listener at un-mount time.
        return () => {
            window.removeEventListener('hashchange', setActivePageAndMenuByPath);

			if (expandMenuTimerRef.current !== null) {
				window.clearTimeout(expandMenuTimerRef.current);
			}
        };
    }, [location, setActivePageAndMenuByPath]);

	useEffect(() => {
		const docDOM = DOM.getHTML();

		const onClick = (e: MouseEvent) => {
			// If we use click a DOM out of the .search-box element, then clear and
			// narrow the search input and hide the search result drop down box.
			if (e.target instanceof HTMLElement) {
				let clickedDOM = DOM.closest(e.target, '.oos-top-bar .search-box');
				if (!clickedDOM) {
					setSearchActive(false);
					if (searchInputRef.current !== null) {
						searchInputRef.current.value = '';
					}
					setResultVisible(false);
					setSearchResultArr([]);
				}
			}
		};

		docDOM.addEventListener('click', onClick);

		return () => {
			docDOM.removeEventListener('click', onClick);
		};
	}, [resultVisible]);

	useEffect(() => {
		setIsSignedIn(!!ckGet('oos_token'));
	}, []);

	// Memorized descendant components.
	const FloatBarMemo = useMemo(() => (<FloatBar visible={floatBarVisible} />), [floatBarVisible]);

	const OOSModalMaskMemo = useMemo(() => {
		const modalVisible = signUpVisible || signInVisible || resetPasswordVisible || applyTrialVisible || applyJobVisible || certificateDetailVisible;
		if (!modalVisible) {
			// Reset html class, allow html content scrolling.
			const bodyDOM = DOM.getBody();
			DOM.removeClass(bodyDOM, 'scroll-disabled');
		}
		// Not all modal that need mask is the signUp, signIn, etc, modal, some others like
		// contact us form tip in home page, need the mask too, so there is another flag in
		// redux that also can control the visibility of modal mask.
		return (<OOSModalMask visible={modalVisible || maskVisible} />);
	}, [maskVisible, signUpVisible, signInVisible, resetPasswordVisible, applyTrialVisible, applyJobVisible, certificateDetailVisible]);

	const SignUpMemo = useMemo(() => (<SignUp visible={signUpVisible} hide={hideSignUp} showSignIn={onShowSignInClick} />), [signUpVisible, hideSignUp, onShowSignInClick]);

	const SignInMemo = useMemo(() => (<SignIn visible={signInVisible} hide={hideSignIn} showSignUp={onShowSignUpClick} showResetPassword={onShowResetPasswordClick} />), [signInVisible, hideSignIn, onShowSignUpClick, onShowResetPasswordClick]);

	const ResetPasswordMemo = useMemo(() => (<ResetPassword visible={resetPasswordVisible} hide={hideResetPassword} showSignIn={onShowSignInClick} />), [resetPasswordVisible, hideResetPassword, onShowSignInClick]);

	const ApplyTrialMemo = useMemo(() => (<ApplyTrial visible={applyTrialVisible} hide={hideApplyTrial} />), [applyTrialVisible, hideApplyTrial]);

	return (
		<>
			<div className={`oos-top-bar ${homeBannerNewsCarouselActive ? 'news-carousel-active' : ''}`} id="oos-top-bar">
				<div className="top-bar-content">
					<div
						className="logo"
						// onClick={onSubMenuClick.bind(null, routes.Home)}
						onClick={onForwardHomeClick}
					/>
					<div className="menu-box">
						{/* Home */}
						<div className="menu">
							<span
								className={`menu-text ${activeMenu === 'Home' ? 'active' : ''}`}
								// onClick={onSubMenuClick.bind(null, routes.Home)}
								onClick={onForwardHomeClick}
							>
								{i18n('menu.home')}
							</span>
						</div>
						{/* Product */}
						<div
							className="menu"
							onMouseOver={onSubmenuMouseOver.bind(null, 'product')}
							onMouseOut={onSubmenuMouseOut}
						>
							<span className={`menu-text ${activeMenu === 'Product' ? 'active' : ''}`}>{i18n('menu.product')}</span>
							<ProductDropDownMenu visible={activeSubmenu === 'product'} />
						</div>
						{/* Solution */}
						<div
							className="menu"
							onMouseOver={onSubmenuMouseOver.bind(null, 'solution')}
							onMouseOut={onSubmenuMouseOut}
						>
							<span className={`menu-text ${activeMenu === 'Solution' ? 'active' : ''}`}>{i18n('menu.solution')}</span>
							<SolutionDropDownMenu visible={activeSubmenu === 'solution'} />
						</div>
						{/* Technical Support */}
						<div
							className="menu"
							onMouseOver={onSubmenuMouseOver.bind(null, 'technicalSupport')}
							onMouseOut={onSubmenuMouseOut}
						>
							<span className={`menu-text ${activeMenu === 'TechnicalSupport' ? 'active' : ''}`}>{i18n('menu.technicalSupport')}</span>
							<div className={`normal-drop-down-menu technical-support ${activeSubmenu === 'technicalSupport' ? 'active' : ''}`}>
								<div className="menu-title">{i18n('menu.technicalSupport')}</div>
								<div className="sub-menu-box">
									<div className="sub-menu-row">
										<div
											className={`sub-menu ${activePage === routes.Document ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.Document)}
										>
											{i18n('technicalSupport.document')}
										</div>
										<div
											className={`sub-menu ${activePage === routes.AfterSalesCase ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.AfterSalesCase)}
										>
											{i18n('technicalSupport.afterSalesCase')}
										</div>
										<div
											className={`sub-menu ${activePage === routes.ImageGallery ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.ImageGallery)}
										>
											{i18n('technicalSupport.imageGallery')}
										</div>
										<div
											className={`sub-menu ${activePage === routes.Guarantee ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.Guarantee)}
										>
											{i18n('technicalSupport.guarantee')}
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Firm News */}
						<div className="menu">
							<span
								className={`menu-text ${activeMenu === 'News' ? 'active' : ''}`}
								onClick={onSubMenuClick.bind(null, routes.FirmNews)}
							>
								{i18n('menu.firmNews')}
							</span>
						</div>
						{/* About */}
						<div
							className="menu"
							onMouseOver={onSubmenuMouseOver.bind(null, 'about')}
							onMouseOut={onSubmenuMouseOut}
						>
							<span className={`menu-text ${activeMenu === 'About' ? 'active' : ''}`}>{i18n('menu.about')}</span>
							<div className={`normal-drop-down-menu about ${activeSubmenu === 'about' ? 'active' : ''}`}>
								<div className="menu-title">{i18n('menu.about')}</div>
								<div className="sub-menu-box">
									<div className="sub-menu-row">
										<div
											className={`sub-menu ${activePage === routes.AboutUs ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.AboutUs)}
										>
											{i18n('about.aboutUs')}
										</div>

										<div
											className={`sub-menu ${activePage === routes.Honor ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.Honor)}
										>
											{i18n('about.honor')}
										</div>
										<div
											className={`sub-menu ${activePage === routes.Recruitment ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.Recruitment)}
										>
											{i18n('about.recruitment')}
										</div>
										<div
											className={`sub-menu ${activePage === routes.ContactUs ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.ContactUs)}
										>
											{i18n('about.contactUs')}
										</div>
									</div>
									<div className="sub-menu-row">
										{/*
										<div
											className={`sub-menu ${activePage === routes.Leadership ? 'active' : ''}`}
											onClick={onSubMenuClick.bind(null, routes.Leadership)}
										>
											{i18n('about.leadership')}
										</div>
										*/}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="button-box">
						<div
							className="button trial"
							onClick={onShowApplyTrialClick}
						>
							{i18nGen('applyTrial')}
						</div>
						{
							!isSignedIn ? (
									<div
										className="button sign-in"
										onClick={onShowSignInClick}
									>
										{i18nGen('signIn')}
									</div>
								) : (
									<div
										className="button sign-out"
										onClick={onSignOutClick}
									>
										{i18nGen('signOut')}
									</div>
								)
						}
						<i
							className="button-ph menu"
							onClick={onExpandPadAndPhDropDownMenuClick}
						/>
						<i
							className="button-ph sign"
							onClick={onSignClick}
						/>
					</div>
					<div className={`search-box ${searchActive ? 'active' : ''}`}>
						<input
							className="search-input"
							ref={searchInputRef}
							type="text"
							placeholder={i18n('search.plh')}
							autoComplete="off"
							onFocus={onInputFocus}
							onKeyDown={onInputKeyDown}
							onChange={onInputChange}
						/>
						<i className="search-icon" onClick={onSearchClick} />
						<i className="search-icon-ph" onClick={onShowSearchClick} />
						<div className={`result-drop-down-box ${resultVisible ? 'visible' : ''}`}>
							{
								resultVisible ?
									searchResultArr.length === 0 ?
										(<div className="result-item empty">{i18n('search.emptyText')}</div>) :
										searchResultArr.map(searchResult => (
											<div
												className="result-item"
												key={searchResult.name}
												onClick={onForwardContentClick.bind(null, searchResult.path)}
											>
												<span className="result-item-text">{searchResult.name}</span>
												<i className="oos-button-link-icon">→</i>
											</div>
										)) :
									null
							}
						</div>
					</div>
				</div>
				{/* Solution drop down menu will be mounted here through portal. */}
			</div>
			{FloatBarMemo}
			{OOSModalMaskMemo}
			{SignUpMemo}
			{SignInMemo}
			{ResetPasswordMemo}
			{ApplyTrialMemo}
			<PadAndPhDropDownMenu />
		</>
	);
};

export default TopBar;
