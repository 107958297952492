import React, {FC, KeyboardEvent, MouseEvent, useCallback, useRef, useState} from 'react';
import {useAccessStatistics, useI18n, useSelector} from '@hooks';
import {OOSModal} from '@components';
import {base64, ckGet, ckSet, equals, validateMobilePhone, validatePassword} from '@utils';
import httpCaller from '@http/caller';
import {handleFetchExceptionMsg} from '@http/fetch/httpHelper';
import {State} from '@redux/interfaces';
import {UserSignInDto} from '@http/dto/user';
import routes from '@routes';

interface SignInProps {
	visible: boolean;
	hide: Function;
	showSignUp: Function;
	showResetPassword: Function;
}

interface SelectedState {
	windowWidth: number;
}

const SignIn: FC<SignInProps> = ({
	visible: pVisible,
	hide: pHide,
	showSignUp: pShowSignUp,
	showResetPassword: pShowResetPassword
}) => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('SignIn');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {windowWidth}} = state;
		return {windowWidth};
	}, []);

	const {windowWidth} = useSelector<State, SelectedState>(selectState, equals);

	// Internal states.
	const [loading, setLoading] = useState(false);
	const [timestamp, setTimestamp] = useState(Date.now());
	const [showSuccess, setShowSuccess] = useState(false);

	// Ref data.
	const phoneInputRef = useRef<HTMLInputElement | null>(null);
	const passwordInputRef = useRef<HTMLInputElement | null>(null);
	const verificationCodeInputRef = useRef<HTMLInputElement | null>(null);

	// Page actions.
	const onHideClick = useCallback(() => pHide(), [pHide]);

	const onRefreshCodeClick = useCallback(() => setTimestamp(Date.now()), []);

	const onShowSignUpClick = useCallback(() => {
		pHide();
		pShowSignUp();
	}, [pHide, pShowSignUp]);

	const onShowResetPasswordClick = useCallback(() => {
		pHide();
		pShowResetPassword();
	}, [pHide, pShowResetPassword]);

	const onSubmitClick = useCallback(async (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLInputElement>) => {
		// Prevent the form default behavior.
		e.preventDefault();

		if (!loading) {
			// Trim input values.
			const phone = phoneInputRef.current ? phoneInputRef.current.value.trim() : '';
			const password = passwordInputRef.current ? passwordInputRef.current.value.trim() : '';
			const code = verificationCodeInputRef.current ? verificationCodeInputRef.current.value.trim() : '';

			// Check form validation.
			if (!validateMobilePhone(phone)) {
				return alert(i18n('validation.phoneIncorrect'));
			}
			if (!validatePassword(password)) {
				return alert(i18n('validation.passwordIncorrect'));
			}
			if (code.length === 0) {
				return alert(i18n('validation.verificationCodeIncorrect'));
			}

			setLoading(true);

			let user: UserSignInDto = {
				username: phone,
				password: base64(password),
				code,
				type: 'customer',  // For official website user.
			};

			try {
				const session = await httpCaller.signIn(user);
				const ckOpt = {expires: session.tokenExpiration / 86400};
				ckSet('oos_sessionID', session.id, ckOpt);
				ckSet('oos_user', session.user, ckOpt);
				ckSet('oos_token', session.token, ckOpt);
				setLoading(false);
				setShowSuccess(true);
			} catch (e) {
				setLoading(false);
				setTimestamp(Date.now());
				alert(i18n('actionFails') + handleFetchExceptionMsg(e));
			}
		}
	}, [loading, i18n]);

	const onInputKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key.toLowerCase() === 'enter') {
			onSubmitClick(e);
		}
	}, [onSubmitClick]);

	const onOkClick = useCallback(() => {
		if (!loading) {
			setShowSuccess(false);
			pHide();
			let isSignedIn = ckGet('oos_token');
			// If there is a signed-in user, means this hide action is triggered by sign
			// in action.
			if (isSignedIn) {
				// So a sample way to sync the sign in status is just reload the the page.
				window.location.reload();
			}
		}
	}, [pHide, loading]);

	// Access Statistics.
	useAccessStatistics(routes.SignIn, pVisible);

	return pVisible ? (
		<OOSModal
			width={
				windowWidth > 450 ?
					(!showSuccess ? 490 : 340) :
					(!showSuccess ? 340 : 300)
			}
		>
			{
				!showSuccess ? (
					<>
						<div className="oos-modal-hide-button-box">
							<div className="oos-modal-hide-button" onClick={onHideClick} />
						</div>
						<div className="oos-modal-title">{i18n('title')}</div>
						{
							pVisible && (
								<div className="oos-modal-form-content">
									<form autoComplete="off">
										<div className="oos-modal-form-item">
											<div className="label-img phone" />
											<input type="text" maxLength={11} ref={phoneInputRef} placeholder={i18n('phonePlh')} onKeyDown={onInputKeyDown} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img password" />
											<input type="password" ref={passwordInputRef} autoComplete="new-password" placeholder={i18n('passwordPlh')} onKeyDown={onInputKeyDown} />
										</div>
										<div className="oos-modal-form-item verification-code">
											<div className="label-img verification-code" />
											<input type="text" ref={verificationCodeInputRef} placeholder={i18n('verificationCodePlh')} onKeyDown={onInputKeyDown} />
										</div>
										<img
											className="verification-code-img"
											title={i18n('clickToRefresh')}
											alt="verification-code"
											src={`/api/v2/sessions/code?w=118&h=38&timestamp=${timestamp}`}
											onClick={onRefreshCodeClick}
										/>
										<div
											className={`submit-button ${loading ? 'disabled' : ''}`}
											onClick={onSubmitClick}
										>
											{!loading ? i18nGen('ok') : i18n('signingIn')}
										</div>
									</form>
								</div>
							)
						}
						<div className="oos-modal-form-switch-action">
							<span className="switch-tips">{i18n('hasNoAccount')}</span>
							<span
								className="switch-action-link"
								onClick={onShowSignUpClick}
							>
								{i18n('forwardSignUp')}
								<i className="oos-button-link-icon">→</i>
							</span>
							<span
								className="switch-action-link forget-password"
								onClick={onShowResetPasswordClick}
							>
								{i18n('forgetPassword')}
								<i className="oos-button-link-icon">→</i>
							</span>
						</div>
					</>
				) :
				(
					<div className="oos-modal-success-tips-box">
						<div className="success-img" />
						<div className="success-title">{i18n('actionOk')}</div>
						<div className="success-desc">{i18n('actionOkTips')}</div>
						<div className="success-ok-button" onClick={onOkClick}>{i18nGen('ok')}</div>
					</div>
				)
			}
		</OOSModal>
	) :
	null;
}

export default SignIn;
