// General.
import General from './General/General.i18n';

// Bar.
import ApplyTrialBar from './Bar/ApplyTrialBar.i18n';
import TopBar from './Bar/TopBar.i18n';
import FootBar from './Bar/FootBar.i18n';
import CookieConsent from './Bar/CookieConsent.i18n';

// Sign Up.
import SignUp from './SignUp/SignUp.i18n';

// Sign In.
import SignIn from './SignIn/SignIn.i18n'

// Reset Password.
import ResetPassword from './ResetPassword/ResetPassword.i18n'

// Apply Trial.
import ApplyTrial from './ApplyTrial/ApplyTrial.i18n';

// Home.
import Home from './Home/Home.i18n';

// Product.
import UniIO from './Product/UniIO.i18n';
import OrcaBD from './Product/OrcaBD.i18n';
import OrcaFS from './Product/OrcaFS.i18n';

// Solution.
// Storage solution.
// General category.
import BigData from './Solution/BigData.i18n';
import HighPerformanceComputing from './Solution/HighPerformanceComputing.i18n';
import CloudComputingPrivateCloud from './Solution/CloudComputingPrivateCloud.i18n';
import CloudNativeContainerStorage from './Solution/CloudNativeContainerStorage.i18n';
import DataBackup from './Solution/DataBackup.i18n';
// Industry category.
import NewEnergyVehicle from './Solution/NewEnergyVehicle.i18n';
import IntelligentCampus from './Solution/IntelligentCampus.i18n';
import IntelligentSecurity from './Solution/IntelligentSecurity.i18n';
import AI from './Solution/AI.i18n';
// IoT solution.
// Intelligent police category.
import BrightProject from './Solution/BrightProject.i18n';
import MajorEventSecurity from './Solution/MajorEventSecurity.i18n';
// Intelligent community category.
import IntelligentCommunity from './Solution/IntelligentCommunity.i18n';
import IntelligentCommunitySecurity from './Solution/IntelligentCommunitySecurity.i18n';
import IntelligentElevator from './Solution/IntelligentElevator.i18n';
import HighAltitudeThrowObject from './Solution/HighAltitudeThrowObject.i18n';
// Intelligent park category.
import IntelligentPark from './Solution/IntelligentPark.i18n';
// Intelligent scenic spot category.
import IntelligentScenicSpot from './Solution/IntelligentScenicSpot.i18n';
import AllAreaTourism from './Solution/AllAreaTourism.i18n';
// Carbon neutrality category.
import CarbonNeutrality from './Solution/CarbonNeutrality.i18n';
// Intelligent garden category.
import IntelligentGarden from './Solution/IntelligentGarden.i18n';
// Emergency category.
import EmergencyCommand from './Solution/EmergencyCommand.i18n';
import SafetyProductionEmergency from './Solution/SafetyProductionEmergency.i18n';
// General category.
import IoTPlatform from './Solution/IoTPlatform.i18n';

// Technical support.
import Document from './TechnicalSupport/Document.i18n';
import DocumentDetail from './TechnicalSupport/DocumentDetail.i18n';
import AfterSalesCase from './TechnicalSupport/AfterSalesCase.i18n';
import ImageGallery from './TechnicalSupport/ImageGallery.i18n';
import ImageGalleryDetail from './TechnicalSupport/ImageGalleryDetail.i18n';
import Guarantee from './TechnicalSupport/Guarantee.i18n';

// News.
import FirmNews from './FirmNews/FirmNews.i18n';
import FirmNewsDetail from './FirmNews/FirmNewsDetail.i18n';

// About.
import AboutUs from './About/AboutUs.i18n';
import Leadership from './About/Leadership.i18n';
import Honor from './About/Honor.i18n';
import Recruitment from './About/Recruitment.i18n';
import ApplyJob from './About/ApplyJob.i18n';
import ContactUs from './About/ContactUs.i18n';

// Not found.
import NotFound from './NotFound/NotFound.i18n';

type i18nMap = Record<string, Record<string, any>>;

const localMap: i18nMap = {
	// General.
	General,

	// Sign Up.
	SignUp,

	// Sign in.
	SignIn,

	// Reset Password.
	ResetPassword,

	// Apply Trial
	ApplyTrial,

	// Bar.
	ApplyTrialBar,
	TopBar,
	FootBar,
	CookieConsent,

	// Home.
	Home,

	// Product.
	UniIO,
	OrcaBD,
	OrcaFS,

	// Solution.
	// Storage solution.
	// General category.
	BigData,
	HighPerformanceComputing,
	CloudComputingPrivateCloud,
	CloudNativeContainerStorage,
	DataBackup,
	// Industry category.
	NewEnergyVehicle,
	IntelligentCampus,
	IntelligentSecurity,
	AI,
	// IoT solution.
	// Intelligent police category.
	BrightProject,
	MajorEventSecurity,
	// Intelligent community category.
	IntelligentCommunity,
	IntelligentCommunitySecurity,
	IntelligentElevator,
	HighAltitudeThrowObject,
	// Intelligent park category.
	IntelligentPark,
	// Intelligent scenic spot category.
	IntelligentScenicSpot,
	AllAreaTourism,
	// Carbon neutrality category.
	CarbonNeutrality,
	// Intelligent garden category.
	IntelligentGarden,
	// Emergency category.
	EmergencyCommand,
	SafetyProductionEmergency,
	// General category.
	IoTPlatform,

	// Technical support.
	Document,
	DocumentDetail,
	AfterSalesCase,
	ImageGallery,
	ImageGalleryDetail,
	Guarantee,

	// News.
	FirmNews,
	FirmNewsDetail,

	// About.
	AboutUs,
	Leadership,
	Honor,
	Recruitment,
	ApplyJob,
	ContactUs,

	// Not found.
	NotFound,
};

export default localMap;
