import {useEffect} from 'react';
import {base64} from '@utils';
import httpCaller from '@http/caller';

export default function useAccessStatistics (path = '', visible = true){
	useEffect(() => {
		if (visible) {
			httpCaller.reportAccess({page: base64(path)});
		}
	}, [path, visible]);
}
