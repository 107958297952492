const zhCN = {
	documentTitle: '碳中和解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '碳中和解决方案',
		subTitle: '某省电网公司"电碳"可视化监测平台采用大数据、物联网、碳排放计算模型等技术手段，结合国家政策与地方特点，构建覆盖某地区酒厂园区与某省国电全省内输变配送的碳排放、用户碳足迹、碳交易和碳吸收等综合维度的智能监测指挥平台。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		hardToOperate: {
			title: '设备运维难',
			desc: '终端设备品类众多，协议不同，与应用紧耦合，形成烟囱式的应用系统以及多个数据孤岛，无法进行智慧物联，万物互通。',
		},
		lowEfficiency: {
			title: '管理效率低',
			desc: '企业信息化能力弱，数字化水平低，管理粗放、运营效率低，人工管理依赖性强，成本高、投诉多。',
		},
		highCostAndPollution: {
			title: '能耗高、污染高',
			desc: '高耗能行业供给侧改革已开始，能效作为核心的衡量依据，传统节能减排缺乏数据支撑和经验，面临能耗“双控”风险。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '碳中和解决方案介绍',
			desc1: '2021年政府工作报告中明确提出要扎实做好“碳达峰”、“碳中和”各项工作，2021年3月15日，习近平主持召开中央财经委员会第九次会议，重点指出，“十四五”是碳达峰的关键期、窗口期，要构建清洁低碳安全高效的能源体系，控制化石能源总量，着力提高利用效能，实施可再生能源替代行动，加快推进碳排放权交易，积极发展绿色金融。要求各地政府部门、能源部门制定2030年前碳达峰行动方案。',
			desc2: '某省电网公司"电碳"可视化监测平台采用大数据、物联网、碳排放计算模型等技术手段，结合国家政策与地方特点，构建覆盖某地区酒厂园区与某省国电全省内输变配送的碳排放、用户碳足迹、碳交易和碳吸收等综合维度的智能监测指挥平台。同时，发挥数据驱动优势，构建 “碳中和”远景规划分析模型，从多角度提出切实可行的建议和实现途径，促进能源结构转型，优化重点行业生产用能结构，加快去碳化进度，促进碳中和的落地实施。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		energySaving: {
			title: '地区节能降耗',
			desc: '针对性实施低碳技术改造，显著提高园区能源基础设施的碳减排能力，完善园区碳盘查、碳排放实时监测分析、碳数据价值化，实现园区绿色低碳循环发展。',
		},
		improveEfficiency: {
			title: '运营效率提高',
			desc: '融合多种数据资源，智能监测生产、运输、销售、管理、废弃物处理等关键指标，全面实现产业能源数字化转型，提升产业管理水平。',
		},
		reformIndustry: {
			title: '助力产业改革',
			desc: '通过各系统的融合打通、各环节的效率提升，实现产业的智慧化、低碳化，整体降低产业的经营成本。',
		},
		economicTransformation: {
			title: '推动经济转型',
			desc: '碳市场是利用市场机制控制和减少温室气体排放、推动绿色低碳发展的重大制度创新。全国碳市场启动上线交易为“双碳”目标的达成提供了重要抓手。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		xxProvinceElectronicCompanyPlatform: {
			title: 'XX省电网有限责任公司“碳中和”平台',
			introduction: '碳中和后台管理系统，面向系统管理员，通过后台维护区域数据，配置指标项规则，事件和告警触发规则，支持生产和能耗数据导入。数据监测大屏，面向管理人员和决策者，通过大屏查看能耗和碳排量数据，分析趋势，通过GIS图属互查，直观地看到各个地区、区县以及下级单位的能耗和碳排量情况，对于碳中和要求的层层落实，提供管理抓手。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
