const zhCN = {
	documentTitle: '奥卡云数据 - 软件定义存储 分布式存储',
	tip: {
		title: '404 页面未找到',
		text: '您访问的页面不存在！',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
