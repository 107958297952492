const zhCN = {
	documentTitle: '智慧电梯解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '智慧电梯解决方案',
		subTitle: '建立电梯远程监控系统，对电梯安全运行实行网络监控管理。预防和减少事故，保障人民群众生命和财产安全。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		oldElevator: {
			title: '电梯老旧',
			desc: '电梯保有量持续增多，老龄化现象加剧，如何及时预警是一个日益突出的问题。',
		},
		hardToRescue: {
			title: '救援困难',
			desc: '困人救援过程不可视，无法了解现场情况。救援过程无法做到安全、可靠、高效。',
		},
		limitedHumanResource: {
			title: '人力有限',
			desc: '监管人力有限，如何高效实现电梯管理及维保监督工作，这个问题急需解决。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '智慧电梯解决方案介绍',
			desc1: '为了加强电梯安全管理，预防和减少事故，保障人民群众生命和财产安全， 2014年1月1日施行的《中华人民共和国特种设备安全法》、国务院《特种设备安全监察条例》以及《特种设备安全技术规范TSG_T5001-2009电 梯使用管理与维护保养规则》，对电梯生产、使用、检验检测、监督管理等法律责任做了明确规定，特别要求保持电梯紧急报警装置能够随时与外界实现有效联系，鼓励和支持建立电梯远程监控系统，对电梯安全运行实行网络监控管理。',
			desc2: '西安奥卡云数据科技公司根据国家相关标准量身定做的智慧电梯管理平台，利用现代物联网技术和信息技术，采用实现国际化通用标准协议，实现智能化、全网管、即时报警、双向视频通话、过程可溯，具有全高清处理能力，实时高清媒体发布能力，同时强化了对电梯设备，电梯使用单位及电梯维保人员的监控和管理能力，对保障电梯安全运行、减少电梯事故发生，提供了有力的技术保障。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		maintenanceSupervision: {
			title: '维保在线监管',
			desc: '设备健康动态体检，维保过程数字化，明确安全责任，安全在线监督。',
		},
		dataArchive: {
			title: '电梯数据档案',
			desc: '建立“全生命周期”数据档案，电梯运行数据、故障类型统计分析、制定维保计划、电梯健康评价。',
		},
		cutCost: {
			title: '降本增效',
			desc: '实时在线监控，节约人力，随手报修，提高处理效率，降低维护成本。',
		},
		emergencyAbility: {
			title: '应急保障能力',
			desc: '健全应急保障机制，维保企业监督，快速应对突发事件。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		ningXiaJingAo: {
			title: '宁夏京澳电梯有限公司智慧电梯项目',
			introduction: '为不同身份的用户定制化了了不同的操作端，以满足政府侧、物业公司、维保单位的监管和使用需求，平台建设内容包括3个端：电梯管理后台，物业APP，维保APP。通过加装在电梯轿厢中的传感器、视频监控摄像头等设备，实时监测电梯运行状态，有故障或预警消息产生时及时通知相关人员进行处理，同时支持电梯故障上报，维保人员执行维保任务，物业管理人员可通过管理后台和物业APP制定维保计划，审核维保执行情况，对维保人员支持考勤打卡和考勤信息统计。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
