import {localeActionTypes} from '@actions/localeAction';
import {Reducer} from '../interfaces';

const localeReducer: Reducer = (state, action) => {
    switch (action.type) {
        case localeActionTypes.SET_LOCALE:
			state = action.locale;
            return state;
            
        default:
            return state;
    }
};

export default localeReducer;
