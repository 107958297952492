const zhCN = {
	documentTitle: '领导团队 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '领导团队',
		subTitle: '奥卡云数据核心管理团队',
	},
	president: {
		name: '孔祥永',
		enName: 'David Kong',
		title: '奥卡云数据董事长',
		introduction: '孔祥永，奥卡云数据董事长，先后就职于Laird、Amphenol、CHT等国际大型外资公司，承担总经理、总监和亚洲区业务负责人等职务，涉及的领域有汽车部件、手机、导热、电磁屏蔽、个人护理等方面。具有二十年丰富的公司经营管理经验。',
	},
	ceo: {
		name: '张科',
		enName: 'Jacky Zhang',
		title: '奥卡云数据CEO',
		introduction: '张科，奥卡云数据CEO，曾就职于Veritas、Symantec、中国惠普、亚信等公司。25年IT行业经历，4年以上IT解决方案、IT咨询经历，15年存储系统产品研发、测试经验，参与过Veritas Cluster File System, Veritas Volume Manager等行业领先存储产品的研发、测试工作；曾任 Symantec/Veritas Chengdu 研发中心执行经理 （Senior Site Executive）。',
	},
	cto: {
		name: '约翰·卡尔迈克',
		enName: 'John Carmichael',
		title: '奥卡云数据CTO',
		introduction: 'John Carmichael，奥卡云数据CTO，曾是世界第三大软件公司 VERITAS SOFTWARE 的创始人之一，其研发的VxFS文件系统曾服务了全球500强企业级数据中心与数据库系统，在美国贝尔实验室任职期间负责UNIX内核系统架构设计。从事企业级软件设计开发27年，精通软件架构设计和数据处理机制。曾为IEEE和NASA专家组成员。2007年任职赛门铁克中国区软件总监，主导研制开发了EOS （比拟 Amazon S3） EOSJ（比拟 Hadoop HDFS）原型机，后被美国收回国内产品化。申请并拥有美国承认的专利十多项，同时主导并且编写了：X/OPEN DMAPI, File System extension for POSIX real time 标准。',
	},
	technicalDirector: {
		name: '刘露',
		enName: 'Lu Liu',
		title: '奥卡云数据技术总监',
		introduction: '刘露，奥卡云数据技术总监，15年存储系统研发设计经验，曾就职于Symantec、Veritas，参与Veritas Cluster File System, Veritas Volume Manager行业领先存储产品核心研发、全球客户紧急问题处理。获得西安D类技术人才，秦创原科研创新人才称号。拥有存储、重复数据删除等领域5项已授权发明专利。',
	},
	vicePresident: {
		name: '曹庭华',
		enName: 'Cao Tinghua',
		title: '奥卡云数据副总裁',
		introduction: '曹庭华，奥卡云数据副总裁，16年存储产品设计与开发经验，对存储Cache、RAID、分布式有丰富的实践经验，拥有5项存储专利。对存储创新产品有长期跟踪分析，深度分析了国外300余家存储、IoT、大数据、云计算初创企业。先后担任华三、华为存储系统工程师、全闪存阵列首席系统工程师。',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
