import {recruitmentActionTypes} from '@actions/recruitmentAction';
import {Reducer} from '../interfaces';

const recruitmentReducer: Reducer = (state, action) => {
    const {jobList} = action;

    switch (action.type) {
        case recruitmentActionTypes.SET_JOB_LIST:
            return Object.assign({}, state, {jobList});

        default:
            return state;
    }
};

export default recruitmentReducer;
