import React, {FC, useCallback, useEffect, useRef} from 'react';
import generalAction from '@actions/generalAction';
import {useDispatch, useI18n, useSelector} from '@hooks';
import {equals, lsSet} from '@utils';
import {State} from '@redux/interfaces';

interface CookieConsentProps {
	hide: () => void;
}

interface SelectedState {
	cookiePolicyShown: boolean;
}

const CookiePolicyBar: FC<CookieConsentProps> = ({
	hide: pHide,
}) => {
	// i18n.
	const i18n = useI18n('CookieConsent');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {cookiePolicyShown}} = state;
		return {cookiePolicyShown};
	}, []);

	const {cookiePolicyShown} = useSelector<State, SelectedState>(selectState, equals);

	const dispatch = useDispatch();

	const setCookiePolicyShown = useCallback((cookiePolicyShow: boolean) => dispatch(generalAction.setCookiePolicyShown(cookiePolicyShow)), [dispatch]);

	// Ref data.
	const cookieConsentDOMRef = useRef<HTMLDivElement | null>(null);
	const timerRef = useRef<number | null>(null);

	// Page actions.
	const onAcceptClick = useCallback(() => {
		if (!cookiePolicyShown) {
			lsSet('oos_cookiePolicyShown', true);
			setCookiePolicyShown(true);
		}
	}, [cookiePolicyShown, setCookiePolicyShown]);

	// After rendering and re-rendering.
	useEffect(() => {
		timerRef.current = window.setTimeout(() => {
			if (cookieConsentDOMRef.current !== null) {
				if (cookiePolicyShown) {
					cookieConsentDOMRef.current.className = 'oos-cookie-policy-bar hide';
					timerRef.current = window.setTimeout(() => pHide(), 300);
				} else {
					cookieConsentDOMRef.current.className = 'oos-cookie-policy-bar visible';
				}
			}
		}, 200);

		return () => {
			if (timerRef.current !== null) {
				window.clearTimeout(timerRef.current);
			}
		};
	}, [pHide, cookiePolicyShown]);

	return (
		<div
			className="oos-cookie-policy-bar"
			ref={cookieConsentDOMRef}
		>
			<div className="oos-cookie-policy-content">
				<div className="text-wrap">
					<div className="title">{i18n('title')}</div>
					<div className="desc">
						{i18n('desc1')}<a href="/privacy_statement.html" target="_blank">{i18n('privacyStatement')}</a>{i18n('desc2')}
					</div>
				</div>
				<div className="accept-button-wrap">
					<div
						className="accept-button"
						onClick={onAcceptClick}
					>
						{i18n('accept')}
					</div>
				</div>
			</div>
		</div>
	);
}

export default CookiePolicyBar;
