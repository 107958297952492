interface DOMType {
	getHTML: () => HTMLElement;
	getBody: () => HTMLElement;
	getWindowWidth: () => number;
	getDeviceMode: (windowWidth?: number) => 'pc' | 'pad' | 'phone';
	closest: (el: HTMLElement | null, selector: string) => HTMLElement | null;
	scrollTo: (top: number, target?: HTMLElement) => void;
	getScrollTop: (target?: HTMLElement) => number;
	containsClass: (el: HTMLElement, cls: string) => boolean;
	addClass: (el: HTMLElement, cls: string) => void;
	removeClass: (el: HTMLElement, cls: string) => void;
	toggleClass: (el: HTMLElement, cls: string) => void;
	replaceClass: (el: HTMLElement, cls: string, targetCls: string) => void;
}

const DOM: DOMType = {
	// Make a func to get doc for avoiding memory leak due to keep all doc DOM tree in memory.
	getHTML: () => window.document.documentElement,

	getBody: () => window.document.body,

	getWindowWidth: () => window.document.body.clientWidth,

	getDeviceMode: (windowWidth?: number) => {
		// Threshold of switching device width:
		//
		// 1920px - 1401px pc
		// 1400px - 1201px pad
		// 1200px - 768px ignored (stay in pad)
		// 768px - ~ phone
		//
		if (windowWidth === void 0) {
			windowWidth = window.document.body.clientWidth;
		}

		if (windowWidth > 1400) {
			return 'pc';
		} else if (windowWidth > 768) {
			return 'pad';
		} else {
			return 'phone';
		}
	},

	closest: (el, selector) => {
		while (el !== null) {
			if ( el.matches.call(el, selector)) {
				break;
			}
			// If no element is matched, the parentElement will finally be null(htmlDOM.parentElement).
			el = el.parentElement;
		}
		return el;
	},

	scrollTo: (top = 0, target = DOM.getHTML()) => {
		target.scrollTop = top;
	},

	getScrollTop: (target = DOM.getHTML()) => target.scrollTop,

	containsClass: (el, cls) => {
		return el.classList.contains(cls);
	},

	addClass: (el, cls) => {
		// The classList is a live DOMTokenList collection. More APIs see:
		// https://developer.mozilla.org/en-US/docs/Web/API/Element/classList
		// https://developer.mozilla.org/en-US/docs/Web/API/DOMTokenList
		el.classList.add(cls)
	},

	removeClass: (el, cls) => {
		el.classList.remove(cls);
	},

	toggleClass: (el, cls) => {
		el.classList.toggle(cls);
	},

	replaceClass: (el, cls, targetCls) => {
		el.classList.replace(cls, targetCls);
	},
};

export default DOM;
