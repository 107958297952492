const fixImageURL = (URL: string) => {
	URL += '/preview';
	if (URL.startsWith('https')) {
		return URL;
	} else {
		return `https://orcadt.com/${URL}`;
	}
};

export {
	fixImageURL,
};
