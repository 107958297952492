import state from '../states';
import {lsGet} from '@utils';
import combineReducersImmer from './combineReducerImmer';
import localeReducer from './localeReducer';
import generalReducer from './generalReducer';
import modalReducer from './modalReducer';
import newsReducer from './newsReducer';
import productReducer from './productReducer';
import recruitmentReducer from './recruitmentReducer';

// Correct the state with persistent data from localStorage.
state.locale = lsGet('oos_locale') || 'zh-CN';
state.general.cookiePolicyShown = !!lsGet('oos_cookiePolicyShown');

// Combine the reducers into a single reducer for calling createStore.
// This is based on immer instead of redux. So in a reducer we can directly
// change the state, do not need to use Object.assign any more. See the
// implement in combineReducersImmer.tsx for details.
export default combineReducersImmer({
	locale: (locale = state.locale, action) => localeReducer(locale, action),

	general: (general = state.general, action) => generalReducer(general, action),

	modal: (modal = state.modal, action) => modalReducer(modal, action),

	news: (news = state.news, action) => newsReducer(news, action),

	product: (modal = state.product, action) => productReducer(modal, action),

	recruitment: (recruitment = state.recruitment, action) => recruitmentReducer(recruitment, action),
});
