import {ActionCreatorSet} from '../interfaces';

export const generalActionTypes = {
	SET_COOKIE_POLICY_SHOWN: '@GENERAL/SET_COOKIE_POLICY_SHOWN',
	SET_WINDOW_WIDTH: '@GENERAL/SET_WINDOW_WIDTH',
	SET_DEVICE_MODE: '@GENERAL/SET_DEVICE_MODE',
	SET_WINDOW_WIDTH_AND_DEVICE_MODE: '@GENERAL/SET_WINDOW_WIDTH_AND_DEVICE_MODE',
	SET_USER: '@GENERAL/SET_USER',
	SET_ACTIVE_SUBMENU: '@GENERAL/SET_ACTIVE_SUBMENU',
	SET_ACTIVE_SOLUTION_CATEGORY: '@GENERAL/SET_ACTIVE_SOLUTION_CATEGORY',
	SET_ACTIVE_SOLUTION_SECONDARY_CATEGORY: '@GENERAL/SET_ACTIVE_SECONDARY_SOLUTION_CATEGORY',
	SET_PAD_AND_PHONE_DROP_DOWN_MENU_ACTIVE: '@GENERAL/SET_PAD_AND_PHONE_DROP_DOWN_MENU_ACTIVE',
	SET_HOME_BANNER_NEWS_CAROUSEL_ACTIVE: '@GENERAL/SET_HOME_BANNER_NEWS_CAROUSEL_ACTIVE',

};

const generalAction: ActionCreatorSet = {
	setCookiePolicyShown: cookiePolicyShown => ({
		type: generalActionTypes.SET_COOKIE_POLICY_SHOWN,
		cookiePolicyShown
	}),

	setWindowWidth: windowWidth => ({
		type: generalActionTypes.SET_WINDOW_WIDTH,
		windowWidth
	}),

	setDeviceMode: deviceMode => ({
		type: generalActionTypes.SET_DEVICE_MODE,
		deviceMode
	}),

	setWindowWidthAndDeviceMode: windowWidthAndDeviceMode => ({
		type: generalActionTypes.SET_WINDOW_WIDTH_AND_DEVICE_MODE,
		windowWidthAndDeviceMode
	}),

	setUser: user => ({
		type: generalActionTypes.SET_USER,
		user
	}),

	setActiveSubmenu: activeSubmenu => ({
		type: generalActionTypes.SET_ACTIVE_SUBMENU,
		activeSubmenu
	}),

	setActiveSolutionCategory: activeSolutionCategory => ({
		type: generalActionTypes.SET_ACTIVE_SOLUTION_CATEGORY,
		activeSolutionCategory
	}),

	setActiveSolutionSecondaryCategory: activeSolutionSecondaryCategory => ({
		type: generalActionTypes.SET_ACTIVE_SOLUTION_SECONDARY_CATEGORY,
		activeSolutionSecondaryCategory
	}),

	setPadAndPhDropDownMenuActive: padAndPhDropDownMenuActive => ({
		type: generalActionTypes.SET_PAD_AND_PHONE_DROP_DOWN_MENU_ACTIVE,
		padAndPhDropDownMenuActive
	}),

	setHomeBannerNewsCarouselActive: homeBannerNewsCarouselActive => ({
		type: generalActionTypes.SET_HOME_BANNER_NEWS_CAROUSEL_ACTIVE,
		homeBannerNewsCarouselActive
	}),
};

export default generalAction;
