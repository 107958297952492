const zhCN = {
	documentTitle: '智慧社区解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '智慧社区解决方案',
		subTitle: '奥卡云智慧社区解决方案是依靠国家相关政策、文件依据进行建设，社区生活作为社会、城市的最小元素体，直接折射出智慧城市、智慧社会科技化的先进性。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		lackOfOverallPlanning: {
			title: '缺乏整体规划',
			desc: '目前，大多数地方政府为响应政府号召，开展智慧城市、智慧社区建设活动。但现实生活中，某些社区项目高开低走，开始时高调宣传，最后却低调收场。还有部分社区建设未考虑到社区需求及后期运营问题，造成后期需要大力人力物力进行维护，造成资源浪费。总体而言，智慧社区还处于发展初期，各个不同服务模块之间的衔接和有效运营尚待提高。',
		},
		hardToIntegrate: {
			title: '社区资源、服务整合困难',
			desc: '智慧社区的理想状态，是让社区内的闲置资源、劳动力均能有效地被利用。但是现实执行却有一定难度。一是社区内的闲散人员可能经常居家不外出，或不经常接触社区的信息。要统计这类人群的信息是比较有难度的；二是社区居民基数庞大，有技术，有服务能力的人群隐藏在社区居民中，挖掘起来比较困难，将其整合起来更是一项复杂的工作。',
		},
		hardToDifference: {
			title: '社区服务差异化难以体现',
			desc: '当前各个城市的智慧社区服务主要类型有物业管理公司的服务、社区行政管理中心提供的公共服务以及一些社区提供的专项到家服务。这些服务虽然打着智慧社区的旗号，但是社区居民眼中却缺乏吸引力。社区服务的要突出差异性，更多的是要关注社区居民的重点需求（例如：家政、故障报修、文娱、幼儿托管等），提供个性化服务。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '智慧社区解决方案介绍',
			desc: '社区是城市的细胞，是城市的重要组成部分，也是对城市概念的继承、发展和实施。奥卡云智慧社区解决方案是依靠国家相关政策、文件依据进行建设，社区生活作为社会、城市的最小元素体，直接折射出智慧城市、智慧社会科技化的先进性。平台是基于智慧城市建设大框架新思路，依托大数据、物联网、云计算、AI等核心技术，汇聚政务、党政、医疗、物业、环境等信息资源，进行数据汇集、清洗、治理、挖掘，构建完整分析模型体系，形成“城市社区数据信息一本账”的概念，通过对社区安防体系建设、社区运营状态进行监测、分析预警及指挥控制，为城市在社区安防管控、民生福祉、创新驱动、污染防治、数据互通等方面提供全方位的数据依据与科技支撑，使政府决策高效化、管理精确化、发展科学化。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		ownerValue: {
			title: '业主价值',
			desc: '提供各类生活服务，提升用户体验；物业服务方便快捷，减少生活纠纷；联合传达组织社区活动，加强邻里关系促进小区和谐，为业主创造价值',
		},
		propertyValue: {
			title: '物业价值',
			desc: '节省人力物力，节省物业通知成本；提升业主生活品质，生活便利；给物业增加收益，移动端和设备端带来增值收益，为物业公司创造价值。',
		},
		realEstateProperty: {
			title: '开发商价值',
			desc: '提高地产开发商知名度，提高房产附加值；便利的用户体验，线上线下一体的互动商圈；智能化设备，保养简单节省人力，为地产开发商创造价值。',
		},
		governmentProperty: {
			title: '政府价值',
			desc: '物业智慧化管理减少生活矛盾，减少政府干预；社区大数据平台信息精确助力推动智慧化城市建设，为政府创造价值。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '优质的方案实施与客户服务案例',
		weiLiuJiaYuan: {
			title: '秦汉新城渭柳佳苑智慧社区',
			introduction: '渭柳佳苑社区是西咸新区秦汉新城最大的安居工程，社区面积大、人口多、流动人口频繁，人口居住的集中化、流动的多样化，给社区管理与治理方面出现一些新的问题，各类社会矛盾和冲突逐步增多。建设智慧社区平台从从“治安防控”、“社会管理”、“服务民生”三个维度出发，通过搭建统一的社区综合安防平台，使一线网格员、警务人员、物业管理人员实现了对实有人口、流动人口、重点关注人员、重点车辆的精确管理与管控，视频监控对整个社区全方位实时监控，实现了突发事件可视化指挥调度，提高处理效率。',
		},
		qinHanNewCity: {
			title: '秦汉新城智慧社区',
			introduction: '秦汉新城位于西咸新区的几何中心，是西咸新区五大功能组团的核心区域。总规划面积302平方公里，其中建设用地50平方公里，涉及80多个社区，约1200个小区（村），涉及人口35万人，辖区内地域广阔、社区面积大、种类多（拆迁村、待拆迁村、城改社区），社区面积大、人口多。秦汉新城智慧社区建设坚持“设施智能、服务便捷、管理精细、生态宜居”4个指引，围绕“重服务、优治理、惠民生”3个方面，按照“机构人员统一、场所标识统一、流程内容统一、信息系统统一、经费保障统一”等“五个统一”的要求，以信息技术为手段，以资源整合和服务应用为重点，以进一步提高秦汉新城社区管理服务综合水平、提升居民幸福感为目标。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
