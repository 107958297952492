import React, {FC, useCallback, useEffect, useState} from 'react';
import modalAction from '@actions/modalAction';
import {useDispatch, useI18n} from '@hooks';
import {ckGet} from '@utils';

const ApplyTrialBar: FC = () => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('ApplyTrialBar');

	// Redux related.
	const dispatch = useDispatch();

	const setSignInVisible = useCallback((visible: boolean) => dispatch(modalAction.setSignInVisible(visible)), [dispatch]);

	const setApplyTrialVisible = useCallback((visible: boolean) => dispatch(modalAction.setApplyTrialVisible(visible)), [dispatch]);

	// Internal states.
	const [isSignedIn, setIsSignedIn] = useState<boolean>(false);

	// Page actions.
	const onApplyTrialClick = useCallback(() => {
		if (!isSignedIn) {
			alert(i18nGen('doSignInFirst'));
			setSignInVisible(true)
		} else {
			setApplyTrialVisible(true);
		}
	}, [isSignedIn, setSignInVisible, setApplyTrialVisible, i18nGen]);

	useEffect(() => {
		setIsSignedIn(!!ckGet('oos_token'));
	}, []);

	return (
		<>
			<div className="oos-apply-trial">
				<div className="apply-trial-content">
					<div className="apply-trial-title">{i18n('title')}</div>
					<div
						className="apply-trial-button"
						onClick={onApplyTrialClick}
					>
						{i18nGen('applyTrial')}
						<i className="oos-button-link-icon">→</i>
					</div>
				</div>
			</div>
			<div className="oos-service">
				<div className="service-box">
					<div className="service-item">
						<i className="service-icon full-day" />
						<span className="service-text">{i18n('service.fullDay')}</span>
					</div>
					<div className="service-item">
						<i className="service-icon original-factory" />
						<span className="service-text">{i18n('service.originalFactory')}</span>
					</div>
					<div className="service-item">
						<i className="service-icon trial" />
						<span className="service-text">{i18n('service.trial')}</span>
					</div>
					<div className="service-item">
						<i className="service-icon all-period" />
						<span className="service-text">{i18n('service.allPeriod')}</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default ApplyTrialBar;
