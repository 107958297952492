import {stringify, ParsedUrlQueryInput} from 'querystring';
import {API_VERSION, ckGet, makeI18n} from '@utils';
import {FetchExceptionFromBrowser, isFetchExceptionFromBrowser, isHeadersRecord} from '../interface/fetch';

// The functions in this helper are used for generating HTTP
// URL and option of RESTful APIs, and some other work that are
// around HTTP progress.

const handleParam = (
    param: ParsedUrlQueryInput | string | null
) => {
    if (param !== null) {
        if (typeof param === 'string') {
            // Example: '/snapshot/snapshot_1', method: GET(single, list un-paginated, etc)|POST|DELETE.
            return '/' + param;
        } else {
            // Example: '/snapshot?page=1&limit=10', method: GET(list paginated, etc).
            return '?' + stringify(param);
        }
    } else {
        return '';
    }
};

const handleAction = (
    action: string | null
) => {
    if (action !== null) {
        // Example: '/volume/lun1/expand', method: PUT(update, expand, etc).
        return '/' + action;
    } else {
        return '';
    }
};

export const initSearchUrl = (
    url: string,
    param: string | ParsedUrlQueryInput
): string => {
    let paramSeg = '';

    if (param !== void 0) {
        paramSeg = handleParam(param);
    }

    return url + paramSeg;
}

export const initUrl = (
    url: string,
    param?: string | ParsedUrlQueryInput,
    action?: string
): string => {
    let paramSeg = '';
    let actionSeg = '';

    if (param !== void 0) {
        paramSeg = handleParam(param);
    }

    if (action !== void 0) {
        actionSeg = handleAction(action);
    }

    return (API_VERSION + url + paramSeg + actionSeg);
};

export const makeFetchReqInit = (
    reqInit: RequestInit
): RequestInit => {
    const token = ckGet('oos_token');
	const headers: Record<string, string> = {
        'Content-Type': 'application/json; charset=utf-8'
    };

    Object.assign(reqInit, {
        credentials: 'include',
        headers,
    });

    if (token && isHeadersRecord(reqInit.headers)) {
        // Add JWT Bearer token.
        // type HeadersInit = string[][] | Record<string, string> | Headers;
        // Need to attach a customize property to http header, so assert headers' type to Record<string, string>.
        reqInit.headers['authorization'] = `Bearer ${token}`;
    }

    return reqInit;
};

export const handleFetchExceptionMsg = (
    e: unknown | FetchExceptionFromBrowser
) => {
    // If HTTP through error occur due to some network errors of the browser
    // which are not caused by our API, should give out a clear prompt.
    let errorStr: string = '';
    if (!e) {
        errorStr = '';
    } else if (typeof e === 'string') {
        errorStr = e;
    } else if (e instanceof Error) {
        errorStr = e.message;
    } else if (isFetchExceptionFromBrowser(e)) {
        errorStr = e.msg;
    }

    try {
        if (/Failed to fetch/i.test(errorStr)) {
            // This is an network error thrown by browser.
            const i18nGen = makeI18n('General');
            return i18nGen('requestFailed');
        } else {
            return errorStr;
        }
    } catch (exception){
        return errorStr;
    }
};
