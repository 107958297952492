const zhCN = {
	product: {
		title: '产品',
		uniio: 'UniIO全闪存分布式存储',
		orcafs: 'OrcaFS分布式并行文件系统',
		orcabd: 'OrcaBD分布式存储',
		iot: '物联网平台',
	},
	solution: {
		title: '解决方案精选',
		highPerformanceComputing: '高性能计算',
		ai: '人工智能',
		iotPlatform: '物联网平台',
		intelligentGarden: '智慧园林',
		intelligentCommunitySecurity: '智慧社区安防',
	},
	cooperation: {
		title: '合作与资源',
		document: '产品文档',
	},
	information: {
		title: '企业信息',
		firmNews: '企业资讯',
		aboutUs: '关于奥卡云',
		leadership: '领导团队',
		honor: '企业荣誉',
		contactUs: '联系我们',
	},
	weChat: {
		title: '关注公众号',
		follow: '第一时间接收企业资讯和最新动态',
	},
	firmNewsRSSSubscribe: '企业资讯订阅',
	phoneTime: '周一至周五 09:00-18:00',
	location: '陕西省西咸新区秦汉新城秦汉文创大厦12F',
	copyright: 'Copyright ©2017-2023 西安奥卡云数据科技有限公司 版权所有',
	privacyStatement: '隐私声明',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
