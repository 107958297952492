import {DEFAULT_LOCALE, DOM} from '@utils';
import {Document} from '@http/interface/document';
import {ImageGallery} from '@http/interface/imageGallery';
import {FirmNews} from '@http/interface/firmNews';


const states: Record<string, any> = {
	// Locale environment.
	locale: DEFAULT_LOCALE,

	// General.
	general: {
		// Cookie policy shown.
		cookiePolicyShown: false,

		// Window width.
		windowWidth: DOM.getWindowWidth(),

		// Device mode.
		deviceMode: DOM.getDeviceMode(),

		// Signed-in user information.
		user: {},

		// Active top bar submenu.
		activeSubmenu: '',

		// Active top bar solution drop down menu category.
		activeSolutionCategory: 'storage',

		activeSolutionSecondaryCategory: 'general',

		// Pad and phone dropdown menu active.
		padAndPhDropDownMenuActive: false,

		// Is home banner news carousel active.
		homeBannerNewsCarouselActive: false,
	},

	// Modal visibility.
	modal: {
		maskVisible: false,
		signUpVisible: false,
		signInVisible: false,
		resetPasswordVisible: false,
		applyTrialVisible: false,
		applyJobVisible: false,
		certificateDetailVisible: false,
	},

	// Product.
	product: {
		productList: [],
		afterSalesCaseList: [],
		documentList: [],
		documentDetail: null as (Document | null),
		imageGalleryList: [],
		imageGalleryDetail: null as (ImageGallery | null),
		certificateList: [],
	},

	// News.
	news: {
		// Firm news.
		firmNewsList: [],
		firmNewsDetail: null as (FirmNews | null),
	},

	// Recruitment.
	recruitment: {
		jobList: [],
	},
};

export default states;
