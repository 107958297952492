import React, {FC, MouseEvent, useCallback, useRef, useState} from 'react';
import {useAccessStatistics, useI18n, useSelector} from '@hooks';
import {OOSModal} from '@components';
import {validateMobilePhone, validateEmail, equals} from '@utils';
import httpCaller from '@http/caller';
import {handleFetchExceptionMsg} from '@http/fetch/httpHelper';
import {State} from '@redux/interfaces';
import routes from '@routes';

interface ApplyTrialProps {
	visible: boolean;
	hide: Function;
}

interface SelectedState {
	windowWidth: number;
}

const ApplyTrial: FC<ApplyTrialProps> = ({
	visible: pVisible,
	hide: pHide
}) => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('ApplyTrial');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {windowWidth}} = state;
		return {windowWidth};
	}, []);

	const {windowWidth} = useSelector<State, SelectedState>(selectState, equals);

	// Internal states.
	const [loading, setLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);

	// Ref data.
	const fullnameInputRef = useRef<HTMLInputElement | null>(null);
	const phoneInputRef = useRef<HTMLInputElement | null>(null);
	const emailInputRef = useRef<HTMLInputElement | null>(null);
	const firmInputRef = useRef<HTMLInputElement | null>(null);
	const jobInputRef = useRef<HTMLInputElement | null>(null);
	const requirementsInputRef = useRef<HTMLTextAreaElement | null>(null);

	// Page actions.
	const onSubmitClick = useCallback(async (e: MouseEvent<HTMLDivElement>) => {
		// Prevent the form default behavior.
		e.preventDefault();

		if (!loading) {
			// Trim input values.
			const fullname = fullnameInputRef.current !== null ? fullnameInputRef.current.value.trim() : '';
			const phone = phoneInputRef.current !== null ? phoneInputRef.current.value.trim() : '';
			const email = emailInputRef.current !== null ? emailInputRef.current.value.trim() : '';
			const firm = firmInputRef.current !== null ? firmInputRef.current.value.trim() : '';
			const job = jobInputRef.current !== null ? jobInputRef.current.value.trim() : '';
			const requirements = requirementsInputRef.current !== null ? requirementsInputRef.current.value.trim() : '';

			// Check form validation.
			if (!fullname) {
				return window.alert(i18n('validation.fullnameEmpty'));
			}
			if (!validateMobilePhone(phone)) {
				return window.alert(i18n('validation.phoneIncorrect'));
			}
			if (!validateEmail(email)) {
				return window.alert(i18n('validation.emailIncorrect'));
			}

			setLoading(true);

			let trial = {
				fullname,
				phone,
				email,
				company: firm,
				position: job,
				content: requirements,
			};

			try {
				await httpCaller.createTrialApplication(trial);
				setLoading(false);
				setShowSuccess(true);
			} catch (e) {
				setLoading(false);
				window.alert(i18n('actionFails') + handleFetchExceptionMsg(e));
			}
		}
	}, [loading, i18n]);

	const onHideClick = useCallback(() => {
		if (!loading) {
			setShowSuccess(false);
			pHide();
		}
	}, [pHide, loading]);

	// Access Statistics.
	useAccessStatistics(routes.ApplyTrial, pVisible);

	return pVisible ? (
		<OOSModal
			width={
				windowWidth > 450 ?
					(!showSuccess ? 490 : 340) :
					(!showSuccess ? 340 : 300)
			}
		>
			{
				!showSuccess ? (
					<>
						<div className="oos-modal-hide-button-box">
							<div className="oos-modal-hide-button" onClick={onHideClick} />
						</div>
						<div className="oos-modal-title">{i18n('title')}</div>
						{
							pVisible && (
								<form autoComplete="off">
									<div className="oos-modal-form-content">
										<div className="oos-modal-form-item">
											<div className="label-img fullname" />
											<input type="text" maxLength={20} ref={fullnameInputRef} placeholder={i18n('fullnamePlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img phone" />
											<input type="text" maxLength={11} ref={phoneInputRef} placeholder={i18n('phonePlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img email" />
											<input type="text" maxLength={50} ref={emailInputRef} placeholder={i18n('emailPlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img firm" />
											<input type="text" maxLength={30} ref={firmInputRef} placeholder={i18n('firmPlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img job" />
											<input type="text" maxLength={30} ref={jobInputRef} placeholder={i18n('jobPlh')} />
										</div>
										<div className="oos-modal-form-item large">
											<textarea maxLength={200} ref={requirementsInputRef} placeholder={i18n('requirementsPlh')} />
										</div>
										<div
											className={`submit-button ${loading ? 'disabled' : ''}`}
											onClick={onSubmitClick}
										>
											{!loading ? i18nGen('ok') : i18n('signingUp')}
										</div>
									</div>
								</form>
							)
						}
					</>
				) :
				(
					<div className="oos-modal-success-tips-box">
						<div className="success-img" />
						<div className="success-title">{i18n('actionOk')}</div>
						<div className="success-desc">{i18n('actionOkTips')}</div>
						<div className="success-ok-button" onClick={onHideClick}>{i18nGen('ok')}</div>
					</div>
				)
			}
		</OOSModal>
	) :
	null;
}

export default ApplyTrial;
