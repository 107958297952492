const zhCN = {
	documentTitle: '企业招聘 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '企业招聘',
		subTitle: '真挚邀请富有激情的你加入我们',
	},
	teamBuilding: {
		title: '团队建设',
		subTitle: '团结合作、共创价值',
		desc: '奥卡云核心团队拥有多年的行业经验，拥有一支由多名国外专家组成的存储团队、国内一流存储团队、存储系统工程师组成的拥有积极向上、团结创新、吃苦耐劳精神的服务团队。奥卡云关注员工的发展与创新。有竞争力的薪酬只是基础性的回报，我们更注重于打造员工持股、年终分红、持续的意外激励等富有吸引力的团队共赢模式。',
	},
	benefit: {
		title: '员工福利',
		subTitle: '团建、下午茶、生活保障等各方面丰富的福利',
		insurance: '五险一金',
		afternoonTea: '下午茶',
		birthdayParty: '生日会',
		regularTeamBuilding: '定期团建',
		dormitory: '员工宿舍',
		restaurant: '员工餐厅',
	},
	recruitment: {
		title: '招聘信息',
		subTitle: '大量高质位量岗位热招中，诚邀您的加入',
		jobList: '职位列表',
		departmentFilter: '事业部：',
		all: '全部',
		storageDepartment : '存储事业部',
		iotDepartment: '物联网事业部',
		realEstateDepartment: '置业事业部',
		jobTitle: '职位名称',
		newestTag: '最新',
		jobAmount: '招聘人数',
		actions: '操作',
		close: '关闭详情',
		applyJob: '申请职位',
		noJob: '暂无招聘职位',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
