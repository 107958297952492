import {generalActionTypes} from '@actions/generalAction';
import {Reducer} from '../interfaces';

const generalReducer: Reducer = (state, action) => {
	switch (action.type) {
		case generalActionTypes.SET_COOKIE_POLICY_SHOWN:
			state.cookiePolicyShown = action.cookiePolicyShown;
			return state;

		case generalActionTypes.SET_WINDOW_WIDTH:
			state.windowWidth = action.windowWidth;
			return state;

		case generalActionTypes.SET_DEVICE_MODE:
			state.deviceMode = action.deviceMode;
			return state;

		case generalActionTypes.SET_WINDOW_WIDTH_AND_DEVICE_MODE:
			state.windowWidth = action.windowWidthAndDeviceMode.windowWidth;
			state.deviceMode = action.windowWidthAndDeviceMode.deviceMode;
			return state;

		case generalActionTypes.SET_USER:
			state.user = action.user;
			return state;

		case generalActionTypes.SET_ACTIVE_SUBMENU:
			state.activeSubmenu = action.activeSubmenu;
			return state;

		case generalActionTypes.SET_ACTIVE_SOLUTION_CATEGORY:
			state.activeSolutionCategory = action.activeSolutionCategory;
			return state;

		case generalActionTypes.SET_ACTIVE_SOLUTION_SECONDARY_CATEGORY:
			state.activeSolutionSecondaryCategory = action.activeSolutionSecondaryCategory;
			return state;

		case generalActionTypes.SET_PAD_AND_PHONE_DROP_DOWN_MENU_ACTIVE:
			state.padAndPhDropDownMenuActive = action.padAndPhDropDownMenuActive;
			return state;

		case generalActionTypes.SET_HOME_BANNER_NEWS_CAROUSEL_ACTIVE:
			state.homeBannerNewsCarouselActive = action.homeBannerNewsCarouselActive;
			return state;

		default:
			return state;
	}
};

export default generalReducer;
