const zhCN = {
	documentTitle: '产品文档中心 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '产品文档中心',
		subTitle: '汇聚产品相关的安装、配置、技术白皮书等文档',
	},
	searchPlaceholder: '请输入文档名称进行搜索',
	productFilter: '产品：',
	versionFilter: '版本：',
	basicInformation: '基本信息',
	installConfiguration: '安装配置',
	acceptanceTest: '验收测试',
	operationMaintenance: '操作维护',
	troubleshooting: '故障处理',
	referenceGuide: '参考指南',
	versionDocument: '版本文档',
	uniioDiagramTheory: '图说UniIO',
	techNotes: '技术说明',
	authRequired: '该产品文档需要对应的用户权限才能浏览和下载',
	publishTime: '发布/更新时间',
	actions: '操作',
	viewDocument: '查看文档',
	downloadDocument: '下载文档',
	documentEmpty: '相关文档即将上线',
	documentSearchEmpty: '暂无搜索结果',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
