import React, {ForwardRefRenderFunction, ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import {DOM} from '@utils';

interface OOSModalProps {
	children: ReactNode;
	// visible: boolean;
	width?: number | string;
	height?: number | string;
}

const OOSModal: ForwardRefRenderFunction<{}, OOSModalProps> = (
	{
		children: pChildren,
		// visible: pVisible,
		width: pWidth= '94%',
		height: pHeight= 'auto',
	},
	ref
) => {
	// Internal stats.
	const [top, setTop] = useState(0);

	// Page actions.
	const adjustTop = useCallback((bodyDOM = DOM.getBody()) => {
		DOM.addClass(bodyDOM, 'scroll-disabled');
		// We certainly know that the type of elements with 'oos-modal-content visible' class is HTMLElement not SVGElement.
		const modalContentDOM = document.getElementsByClassName('oos-modal-content')[0] as HTMLElement;
		if (modalContentDOM !== null) {
			const modalContentHeight = modalContentDOM.offsetHeight;
			const clientHeight = window.innerHeight;
			let newTop = 0;
			if (clientHeight > modalContentHeight) {
				newTop = (clientHeight - modalContentHeight) / 2;
			}
			setTop(newTop);
		}
	}, []);

	useEffect(() => {
		adjustTop();
	}, [adjustTop]);

	useImperativeHandle(ref, () => ({
		adjustTop: () => {
			adjustTop();
		},
	}));

	// Memorized data.
	const modalContentStyleMemo = useMemo(() => ({
		width: pWidth,
		height: pHeight,
		top: top,
	}), [pWidth, pHeight, top]);

	// Get portal source DOM.
	const rootDom = document.getElementById('oos-app-root');

	return rootDom !== null ? createPortal(
		(
			<div className="oos-modal">
				<div
					className="oos-modal-content"
					style={modalContentStyleMemo}
				>
					{pChildren}
				</div>
			</div>
		),
		rootDom,
    ) :
	null;
}

export default forwardRef(OOSModal);
