/**
 * This tool is based on localStorage API, supports single or batch set/get/remove
 * operations on one key or a keys array.
 */

export const lsGet = (key: string): any => {
    const val = window.localStorage.getItem(key);
    try {
        if (val === null) {
            return val;
        }
        return JSON.parse(val);
    } catch (e) {
        return val;
    }
};

export const lsSet = (key: string, val: any) => {
    window.localStorage.setItem(key, JSON.stringify(val));
};

export const lsRemove = (keys: string | string[]) => {
    if (!Array.isArray(keys)) {
        keys = [keys];
    }
    for (let key of keys) {
        window.localStorage.removeItem(key);
    }
};

export const lsClearAll = () => window.localStorage.clear();

export const lsGetWithDefault = <T,>(key: string, defaultVal: T): T => {
    let val = lsGet(key);
    if (val === null) {
        lsSet(key, defaultVal);
        return defaultVal;
    } else {
        return val;
    }
};
