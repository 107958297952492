import React, {FC, useCallback, useEffect, useRef, useState, useMemo} from 'react';
import generalAction from '@actions/generalAction';
import {useDispatch, useI18n, useInterval, useSelector, useNavigate} from '@hooks';
import Hammer from 'hammerjs';
import {OOSProductBannerAnim} from '@components';
// import BannerModel from './BannerModel';
// import BannerHotTab from './BannerHotTab';
import {equals, HOME_BANNER_CAROUSEL_ITEM_NUMBER} from '@utils';
import httpCaller from '@http/caller';
import {State} from '@redux/interfaces';
import {FirmNews} from '@http/interface/firmNews';
import {DeviceMode} from '@interfaces';
import routes from '@routes';

interface SelectedState {
	deviceMode: DeviceMode;
	firmNewsList: FirmNews[];
}

const Banner: FC = () => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('Home', 'banner');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {deviceMode}, news: {firmNewsList}} = state;
		return {deviceMode, firmNewsList};
	}, []);

	const {deviceMode, firmNewsList} = useSelector<State, SelectedState>(selectState, equals);

	const dispatch = useDispatch();

	const setHomeBannerNewsCarouselActive = useCallback((homeBannerNewsCarouselActive: boolean) => dispatch(generalAction.setHomeBannerNewsCarouselActive(homeBannerNewsCarouselActive)), [dispatch]);

	// Router.
	const navigate = useNavigate();

	// Internal states.
	const [activeIndex, setActiveIndex] = useState(1);
	const [running, setRunning] = useState(true);
	// const [referencePoint, setReferencePoint] = useState({refX: 0, refY: 0});
	// const [mouseMappingPoint, setMouseMappingPoint] = useState({mappingX: 0, mappingY: 0});

	// Ref data.
	const intervalTimeRef = useRef(8000);
	const currentIntervalStartTimeRef = useRef(0);
	const intervalLeftTimeRef = useRef(0);
	const carouselWrapDOMRef = useRef<HTMLDivElement | null>(null);
	const activeIndexRef = useRef(1);
	const hammerManagerRef = useRef<HammerManager | null>(null);
	const switchToPrevTimerRef = useRef<number | null>(null);
	const hammerManagerTimerRef = useRef<number | null>(null);

	// Page actions.
	const onCarouselWrapMouseEnter = useCallback(() => {
		if (deviceMode === 'pc') {
			if (running) {
				const offsetTime = Date.now() - currentIntervalStartTimeRef.current;
				if (intervalLeftTimeRef.current === 0) {
					intervalLeftTimeRef.current = intervalTimeRef.current - offsetTime;
				} else {
					intervalLeftTimeRef.current = intervalLeftTimeRef.current - offsetTime;
				}
				setRunning(false);
			}
		}
	}, [deviceMode, running]);

	const onCarouselWrapMouseLeave = useCallback(() => {
		if (deviceMode === 'pc') {
			if (!running) {
				currentIntervalStartTimeRef.current = Date.now();
				setRunning(true);
			}
		}
	}, [deviceMode, running]);
	
	const onSwitchToPrevClick = useCallback(() => {
		setRunning(false);
		let newActiveIndex = activeIndex - 1;
		if (newActiveIndex <= 0) {
			newActiveIndex = HOME_BANNER_CAROUSEL_ITEM_NUMBER;
		}
		setActiveIndex(newActiveIndex);
		setHomeBannerNewsCarouselActive(newActiveIndex === HOME_BANNER_CAROUSEL_ITEM_NUMBER);
		switchToPrevTimerRef.current = window.setTimeout(() => setRunning(true), 1000);
	}, [setHomeBannerNewsCarouselActive, activeIndex]);

	const onSwitchToNextClick = useCallback(() => {
		setRunning(false);
		let newActiveIndex = activeIndex + 1;
		if (newActiveIndex >= HOME_BANNER_CAROUSEL_ITEM_NUMBER + 1) {
			newActiveIndex = 1;
		}
		setActiveIndex(newActiveIndex);
		setHomeBannerNewsCarouselActive(newActiveIndex === HOME_BANNER_CAROUSEL_ITEM_NUMBER);
	}, [setHomeBannerNewsCarouselActive, activeIndex]);

	const onForwardProductClick = useCallback((path: string) => navigate(path), [navigate]);

	const onForwardNewsClick = useCallback((news: FirmNews | null) => {
		if (news !== null) {
			navigate(routes.FirmNewsDetail + `?id=${news.id}`);
		}
	}, [navigate]);

	useEffect(() => {
		// If current device mode is Pad or Phone, should enable gesture controller, and
		// the hover button controller will be hidden in related media query CSS style.
		if (deviceMode !== 'pc') {
			if (carouselWrapDOMRef.current !== null) {
				hammerManagerRef.current = new Hammer(carouselWrapDOMRef.current);
				hammerManagerRef.current.on('swiperight', () => {
					// Switch to previous.
					setRunning(false);
					let newActiveIndex = activeIndexRef.current - 1;
					// Lower limit.
					if (newActiveIndex <= 0) {
						newActiveIndex = HOME_BANNER_CAROUSEL_ITEM_NUMBER;
					}
					activeIndexRef.current = newActiveIndex;
					setActiveIndex(newActiveIndex);
					setHomeBannerNewsCarouselActive(newActiveIndex === HOME_BANNER_CAROUSEL_ITEM_NUMBER);
					hammerManagerTimerRef.current = window.setTimeout(() => setRunning(true), 16.67);
				});
				hammerManagerRef.current.on('swipeleft', () => {
					// Switch to next.
					setRunning(false);
					let newActiveIndex = activeIndexRef.current + 1;
					// Upper limit.
					if (newActiveIndex >= HOME_BANNER_CAROUSEL_ITEM_NUMBER + 1) {
						newActiveIndex = 1;
					}
					activeIndexRef.current = newActiveIndex;
					setActiveIndex(newActiveIndex);
					setHomeBannerNewsCarouselActive(newActiveIndex === HOME_BANNER_CAROUSEL_ITEM_NUMBER);
					hammerManagerTimerRef.current = window.setTimeout(() => setRunning(true), 16.67);
				});
			}
		}

		return () => {
			if (deviceMode !== 'pc') {
				if (hammerManagerRef.current !== null) {
					hammerManagerRef.current.destroy();
					hammerManagerRef.current = null;
				}
			}
		};
	}, [deviceMode, setHomeBannerNewsCarouselActive]);

	useEffect(() => {
		currentIntervalStartTimeRef.current = Date.now();

		httpCaller.getFirmNewsList();

		return () => {
			setHomeBannerNewsCarouselActive(false);
		};
	}, [setHomeBannerNewsCarouselActive]);

	useEffect(() => {
		return () => {
			if (switchToPrevTimerRef.current !== null) {
				window.clearTimeout(switchToPrevTimerRef.current);
			}
			if (hammerManagerTimerRef.current !== null) {
				window.clearTimeout(hammerManagerTimerRef.current);
			}
		};
	}, []);

	useInterval(() => {
		currentIntervalStartTimeRef.current = Date.now();
		let newActiveIndex = activeIndex + 1;
		// Upper limit.
		if (newActiveIndex >= HOME_BANNER_CAROUSEL_ITEM_NUMBER + 1) {
			newActiveIndex = 1;
		}
		intervalLeftTimeRef.current = 0;
		setActiveIndex(newActiveIndex);
		setHomeBannerNewsCarouselActive(newActiveIndex === HOME_BANNER_CAROUSEL_ITEM_NUMBER);
		activeIndexRef.current = newActiveIndex;
	}, running ?
		intervalLeftTimeRef.current !== 0 ?
			intervalLeftTimeRef.current :
			intervalTimeRef.current :
		null
	);

	// Memorized data.
	const latestNewsMemo = useMemo(() => {
		if (firmNewsList.length === 0) {
			return null;
		} else {
			const stickFirmNews = firmNewsList.find(firmNews => firmNews.stickInHomeBannerCarousel);
			if (stickFirmNews === void 0) {
				return firmNewsList[0];
			}
			return stickFirmNews;
		}
	}, [firmNewsList]);

	return (
		<div className={`banner ${activeIndex === 4 ? 'news-carousel-active' : ''}`}>
			<div
				className="banner-carousel-wrap"
				ref={carouselWrapDOMRef}
				onMouseEnter={onCarouselWrapMouseEnter}
				onMouseLeave={onCarouselWrapMouseLeave}
			>
				<div className="carousel">
					<i
						className="switch-button prev"
						onClick={onSwitchToPrevClick}
					/>
					<i
						className="switch-button next"
						onClick={onSwitchToNextClick}
					/>
					<div className={`carousel-item product ${activeIndex === 1 ? 'active' : ''}`}>
						<div className="carousel-product-desc">
							<div className="name">{i18n('uniio.name')}</div>
							<div className="desc">{i18n('uniio.desc')}</div>
							<div
								className="view-detail-button"
								 onClick={onForwardProductClick.bind(null, routes.UniIO)}
							>
								{i18nGen('viewDetail')}
								<i className="oos-button-link-icon">→</i>
							</div>
						</div>
						<OOSProductBannerAnim name="uniio" />
					</div>
					<div className={`carousel-item product ${activeIndex === 2 ? 'active' : ''}`}>
						<div className="carousel-product-desc">
							<div className="name">{i18n('orcafs.name')}</div>
							<div className="desc">{i18n('orcafs.desc')}</div>
							<div
								className="view-detail-button"
								onClick={onForwardProductClick.bind(null, routes.OrcaFS)}
							>
								{i18nGen('viewDetail')}
								<i className="oos-button-link-icon">→</i>
							</div>
						</div>
						<OOSProductBannerAnim name="orcafs" />
					</div>
					<div className={`carousel-item product ${activeIndex === 3 ? 'active' : ''}`}>
						<div className="carousel-product-desc">
							<div className="name">{i18n('orcabd.name')}</div>
							<div className="desc">{i18n('orcabd.desc')}</div>
							<div
								className="view-detail-button"
								onClick={onForwardProductClick.bind(null, routes.OrcaBD)}
							>
								{i18nGen('viewDetail')}
								<i className="oos-button-link-icon">→</i>
							</div>
						</div>
						<OOSProductBannerAnim name="orcabd" />
					</div>
					<div className={`carousel-item news ${activeIndex === 4 ? 'active' : ''}`}>
						<div className="carousel-news-bg" />
						<div className="carousel-news-desc">
							<div className="title">{latestNewsMemo !== null ? latestNewsMemo.title : i18nGen('loading')}</div>
							<div className="desc">{latestNewsMemo !== null ? latestNewsMemo.summary : i18nGen('loading')}</div>
							<div
								className="view-detail-button"
								onClick={onForwardNewsClick.bind(null, latestNewsMemo)}
							>
								{i18nGen('viewDetail')}
								<i className="oos-button-link-icon">→</i>
							</div>
						</div>
					</div>
					<div className={`pagination ${activeIndex === 4 ? 'news-carousel-active' : ''}`}>
						<i className={`pagination-item ${activeIndex === 1 ? 'active' : ''} ${activeIndex === 1 && !running ? 'paused' : ''}`} />
						<i className={`pagination-item ${activeIndex === 2 ? 'active' : ''} ${activeIndex === 2 && !running ? 'paused' : ''}`} />
						<i className={`pagination-item ${activeIndex === 3 ? 'active' : ''} ${activeIndex === 3 && !running ? 'paused' : ''}`} />
						<i className={`pagination-item ${activeIndex === 4 ? 'active' : ''} ${activeIndex === 4 && !running ? 'paused' : ''}`} />
					</div>
				</div>
				{/*
				<div className="carousel-3d-model-wrap">
					<div className="carousel-3d-model-bg-img" style={imgStyleMemo} />
					<div
						className="carousel-3d-model-content"
						onMouseMove={on3DModelContentMouseMove}
					>
						<BannerModel activeIndex={activeIndex} />
					</div>
				</div>
				*/}
			</div>
			{/*
			<BannerHotTab news={latestNewsMemo} />
			<div className="banner-bottom-bg-placeholder" />
			*/}
		</div>
	);
};

export default Banner;
