import {catchCallerErrorDecr, ckGet, dispatchActionDecr} from '@utils';
import {httpGet, httpPost, httpDelete} from './fetch';
import productAction from '@actions/productAction';
import newsAction from '@actions/newsAction';
import recruitmentAction from '@actions/recruitmentAction';
import {Document, Feedback} from './interface/document';
import {ExportAccessDto} from './dto/access';
import {UserSignUpDto, UserSignInDto, SendRestPasswordVerifyCodeDto, ResetPasswordDto} from './dto/user';
import {CreateTrailApplicationDto} from './dto/trailApplication';
import {CreateConsultationDto} from './dto/consultation';
import {DocumentQueryEntity} from './dto/document';
import {AfterSalesCaseQueryEntity} from './dto/afterSalesCase';
import {ImageGalleryQueryEntity} from './dto/imageGallery';

export class Caller {
	// Access Statistics.
	async reportAccess (access: ExportAccessDto){
		return httpPost.init('/statistics/submit', access).send();
	}

	// Sign Up.
	async signUp (user: UserSignUpDto){
		return httpPost.init('/customers/register', user).send();
	}

	// Sign In.
	async signIn (user: UserSignInDto){
		return httpPost.init('/sessions', user).send();
	}

	// Sign Out.
	async signOut (id = ckGet('oos_sessionID')){
		return httpDelete.init(`/sessions/${id}`).send();
	}

	// Send reset password verify code.
	async sendResetPasswordVerifyCode (user: SendRestPasswordVerifyCodeDto){
		return httpPost.init('/customers/forget-password', user).send();
	}

	// Reset Password.
	async resetPassword (user: ResetPasswordDto){
		return httpPost.init('/customers/change-password', user).send();
	}

	// Apply Trial.
	async createTrialApplication (trial: CreateTrailApplicationDto){
		return httpPost.init('/trial/apply', trial).send();
	}

	// Consult.
	async createConsult (consultation: CreateConsultationDto){
		return httpPost.init('/comments/submit', consultation).send();
	}

	// Technical Support.
	@dispatchActionDecr(productAction, 'setProductList')
	@catchCallerErrorDecr
	async getProductList (){
		return httpGet.init('/products')
			.preprocess('getProductList')
			.send();
	}

	@dispatchActionDecr(productAction, 'setDocumentList')
	@catchCallerErrorDecr
	async getDocumentList (query: DocumentQueryEntity){
		return httpGet.init('/documents', query).send();
	}

	@dispatchActionDecr(productAction, 'setDocumentDetail')
	@catchCallerErrorDecr
	async getDocumentDetail (id: string){
		return httpGet.init(`/documents/${id}`).send();
	}

	@catchCallerErrorDecr
	async getDocumentExcelJSON (id: string){
		return httpGet.init(`/documents/${id}/get-excel-json`).send();
	}

	@catchCallerErrorDecr
	async getDocumentExcelStream (id: string){
		return httpGet.init(`/documents/${id}/get-excel-stream`)
			.asStream()
			.send();
	}

	@catchCallerErrorDecr
	async increaseViewDocumentCount (document: Document){
		return httpPost.init(`/documents/${document.id}/view`).send();
	}

	@catchCallerErrorDecr
	async increaseDownloadDocumentCount (document: Document){
		return httpPost.init(`/documents/${document.id}/download`).send();
	}

	async createDocumentFeedBack (feedback: Feedback){
		return httpPost.init('/feedbacks/submit', feedback).send();
	}

	@dispatchActionDecr(productAction, 'setAfterSalesCaseList')
	@catchCallerErrorDecr
	async getAfterSalesCaseList (query: AfterSalesCaseQueryEntity){
		return httpGet.init('/after-sales-cases', query).send();
	}

	@dispatchActionDecr(productAction, 'setImageGalleryList')
	@catchCallerErrorDecr
	async getImageGalleryList (query: ImageGalleryQueryEntity){
		return httpGet.init('/image-galleries', query).send();
	}

	@dispatchActionDecr(productAction, 'setImageGalleryDetail')
	@catchCallerErrorDecr
	async getImageGalleryDetail (id: string){
		return httpGet.init(`/image-galleries/${id}`).send();
	}

	async getGuaranteesExpirationBySN (sn: string){
		return httpGet.init(`/guarantees/query?sn=${sn}`).send();
	}

	// News.
	// Firm news.
	@dispatchActionDecr(newsAction, 'setFirmNewsList')
	@catchCallerErrorDecr
	async getFirmNewsList (locale = 'zh-CN'){
		return httpGet.init('/news', {language: locale, companies: ['orcadt']}).send();
	}

	@dispatchActionDecr(newsAction, 'setFirmNewsDetail')
	@catchCallerErrorDecr
	async getFirmNewsDetail (id: string){
		return httpPost.init(`/news/${id}/view`).send();
	}

	// About.
	// Honor Certificate.
	@dispatchActionDecr(productAction, 'setCertificateList')
	@catchCallerErrorDecr
	async getCertificateList (){
		return httpGet.init('/certificates').send();
	}

	// Recruitment.
	@dispatchActionDecr(recruitmentAction, 'setJobList')
	@catchCallerErrorDecr
	async getJobList (){
		return httpGet.init('/jobs').send();
	}

	async applyJob (applyJob: FormData){
		return httpPost.init('/resumes/submit', applyJob).send();
	}
}

export default new Caller();
