const zhCN = {
	documentTitle: '产品保修状态查询 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '产品保修状态查询',
		subTitle: '提供产品的高效质保服务与质保信息查询',
	},
	guaranteeSearch: '保修期查询',
	search: {
		plh: '请输入产品序列号进行保修状态查询。',
		emptySN: '请输入正确的产品序列号进行查询！',
	},
	state: '产品保修状态：',
	notFound: '未查到',
	invalid: '已过期',
	valid: '有效',
	notFoundSN: '未查到该序列号所属产品的保修状态。',
	expired: '该产品的保修已过期，保修有效期截止日期为：',
	expirationDeadline: '产品保修有效期截止日期为：',
	customer: '产品所属客户为：',
	serviceStatusExplain: '保修状态说明',
	serviceStatus: '状态',
	explain: '说明',
	notFountExplain: '未查到该序列号所属产品的保修状态。请检查产品序列号输入是否正确，注意字母大小写及符号。',
	invalidExplain: '保修已过期，产品不能再享受合同中所规定的保修服务。',
	validExplain: '保修未过期，产品可以正常享受合同中所规定的保修服务。',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
