import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import store from '@store';
import '@styles';
import App from '@views/App/App';

const rootContainer = window.document.getElementById('oos-app-root');

const AppWithStore = (
	<Provider store={store}>
		<App />
	</Provider>
);

if (rootContainer !== null) {
	createRoot(rootContainer).render(AppWithStore);
}
