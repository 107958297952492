import {DataPreprocessor} from '../interface/fetch';
import {Product} from '../interface/product';

const getProductList = (
	productList: Product[],
) => {
	if (productList) {
		return productList.slice(0).sort((a: Product, b: Product) => {
			if (a.sequence < b.sequence ) return -1;
			if (a.sequence > b.sequence ) return 1;
			return 0;
		});
	}
};

const dataPreprocessor: DataPreprocessor = {
	getProductList,
};

export default dataPreprocessor;
