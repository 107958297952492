import React, {FC, useCallback, UIEvent} from 'react';
import {createPortal} from 'react-dom';

interface OOSModalMaskProps {
	visible: boolean;
}

const OOSModalMask: FC<OOSModalMaskProps> = ({
	visible: pVisible,
}) => {
	// Page actions.
	const onMaskScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	// Get portal source DOM.
	const rootDom = document.getElementById('oos-app-root');

	return rootDom !== null ? createPortal(
		(
			<div
				className={`oos-modal-mask ${pVisible ? 'visible' : ''}`}
				onScroll={onMaskScroll}
			/>
		), rootDom
	) :
	null;
};

export default OOSModalMask;
