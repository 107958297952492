const zhCN = {
	documentTitle: 'OrcaFS 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: 'OrcaFS分布式并行文件存储',
		subTitle: 'OrcaFS是一款面向高性能计算、大数据场景的分布式并行文件存储，采用分布式架构实现，支持灵活弹性扩展，满足不同业务的存储需求。',
	},
	introduction: {
		title: '产品介绍',
		subTitle: '产品的功能特性、定位与应用方向',
		name: 'OrcaFS分布式并行文件存储',
		desc1: 'OrcaFS分布式并行文件存储V3.0（简称“OrcaFS”）是一款面向高性能计算和大数据应用的存储产品，采用存储服务与元数据服务分离部署的方式，拥有独立的分布式文件系统缓存OrcaOND（OrcaFS On Demand）支持高效的突发缓存系统Burst Buﬀer，支持跨节点的副本和纠删技术，拥有快照、配额管理、域管理、在线升级等功能。',
		desc2: '支持国产私有网络协议，同时支持POSIX、CIFS、NFS、FTP、FTPS、HTTP等网络共享协议，提供非结构化海量数据的存储功能，以集群文件系统和集群NAS方式向用户提供服务。',
		scenario: {
			geneSequencing: '基因测序',
			materialScience: '材料科学',
			weatherForecasting: '气象预测',
			energyExploration: '能源勘探',
			remoteSensingSatellite: '遥感卫星',
			ai: '人工智能',
			machineLearning: '机器学习',
			dataAnalysis: '数据分析',
		},
		feature: {
			performance: {
				name: '性能优越',
				desc: 'OrcaFS基于RDMA网络协议构建文件系统网络框架，在Infiniband和100Gb网络环境下可提供上百GB/s的读写带宽和百万级的IOPS，支持上万个客户端并行访问的超大规模集群。',
			},
			protocol: {
				name: '多种协议',
				desc: 'OrcaFS支持POSIX、NFS、CIFS和HDFS等多种接口，满足高性能计算的并行访问和大数据HDFS的高效访问。',
			},
			verification: {
				name: '三重校验',
				desc: 'RAID-M元RAID支持同时故障三块盘，数据不丢失。OrcaFS支持节点内RAID保护策略，可以在三块盘同时故障的情况下，数据不丢失；同时采用副本和 RAID双重数据保护机制，确保业务永续。',
			},
			orcaond: {
				name: 'OrcaOND',
				desc: 'OrcaFS拥有独立的分布式文件系统缓存OrcaOND，针对HPC领域海量小文件和非对齐IO低延时高IOPS的要求、突发性IO浪涌的问题，OrcaOND可将大量应用程序数据缓存到NVMe SSD中，实现数据加速处理，极大提升数据的并行访问能力。',
			},
			capacity: {
				name: 'EB级容量+亿级文件',
				desc: 'OrcaFS支持元数据和存储独立横向扩展，容量和性能随节点数量增加线性增长，集群支持EB级容量和亿级文件数量。',
			},
			highReliability: {
				name: '高可靠',
				desc: '纠删+副本+RAID，确保数据安全可靠。OrcaFS采用冗余数据架构，支持节点间数据副本，在节点故障时可自动恢复；支持节点内多种RAID保护策略，可以在三块盘同时故障的情况下，数据不丢失；同时采用副本和 RAID双重数据保护机制，确保业务永续。',
			},
		},
	},
	scenario: {
		title: '典型应用场景',
		subTitle: '还原真实的客户使用场景',
		highPerformanceComputing: {
			name: '高性能计算',
			desc: '高性能计算是分布式存储的高价值应用场景，是在传统HPC向HPDA演进过程中，为应对海量数据高性能并行访问与计算的高吞吐、高IOPS、低时延、混合负载需求出现的应用场景。',
			ability1: '高带宽、高IOPS、低时延，一般要求RDMA网络。',
			ability2: '集群读写总带宽不低于30GB/s。',
			ability3: '支持多客户端并行访问。',
			ability4: '低成本、容量大、高可靠和高可用。',
		},
		bigDataAnalysis: {
			name: '大数据分析',
			desc: '通过Hadoop集群计算与存储分离部署，分布式存储对上层计算提供原生HDFS能力，按需配置计算能力与存储资源，提升资源利用率。帮助企业在大数据分析中实现海量数据价值二次变现。',
			ability1: '使用文件接口向GPU提供数据源，大文件小文件混合读写，高带低延时。',
			ability2: '可提供共享访问，编程接口对AI应用兼容。',
			ability3: '针对过期数据的归档淘汰至容量型存储池。',
		},
		nonLinearEditing: {
			name: '非线性编辑',
			desc: '当前影视内容制作主要采用非线性编辑为主，影视制作也已经从“真人出镜”到“真人+电脑合成”再到“纯电脑合成”，未来的电影制作逐步向“重工业”演进，需要大量的计算、存储和网络带宽资源。',
			ability1: '超高清的视频编辑要求存储容量大、高吞吐量、低时延能力。',
			ability2: '多路视频低时延稳定读写，存储系统应具备高可靠性。',
			ability3: '可提供共享访问，编程接口对AI应用兼容。',
			ability4: '存储系统可支持在线扩展、数据压缩。',
		},
		medicalImaging: {
			name: '医疗影像',
			desc: '在现代医疗中，PACS(Picture Archiving and Communications System)既图像存储与传输系统作为医疗影像业务的核心，是医院信息系统的重要组成部分。X光机、CT、核磁共振等医学影像技术，源源不断地产生医疗影像数据。',
			ability1: '高清的医疗影像数据，要求具备海量文件存储能力，且读写性能优异。',
			ability2: '合规要求，数据长期保存，存储系统应具备高稳定性和高可靠性。',
			ability3: '可提供共享访问，编程接口对AI应用兼容。',
		},
	},
	specification: {
		title: '技术规格',
		subTitle: '软硬件系统指标',
	},
	resource: {
		title: '相关资源',
		subTitle: '产品有关的文档、图库等资源',
		datasheet: 'Datasheet',
		document: '产品文档',
		imageGallery: '产品图库',
		clickToDownload: '点击下载',
		clickToForward: '点击跳转',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
