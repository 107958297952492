import React, {FC, useCallback, useEffect, useRef, useState} from 'react';

interface OOSDynamicIconProps {
	className?: string;
	size?: 'small' | 'normal';
	icon: string;
	static?: boolean;
	selfControl?: boolean;
	activeIcon?: string | null;
}

const OOSDynamicIcon: FC<OOSDynamicIconProps> = ({
	className: pClassName= '',
	size: pSize = 'small',
	icon: pIcon,
	static: pStatic = false,
	selfControl: pSelfControl= false,
	activeIcon: pActiveIcon
}) => {
	// Internal states.
	const [innerStatus, setInnerStatus] = useState<'active' | 'leave' | null>(null);

	// Page actions.
	const onMouseEnter = useCallback(() => setInnerStatus('active'), []);

	const onMouseLeave = useCallback(() => setInnerStatus('leave'), []);

	// Ref data.
	const timerRef = useRef<number | null>(null);

	// After rendering and re-rendering.
	useEffect(() => {
		if (pActiveIcon !== pIcon) {
			if (innerStatus === 'active') {
				setInnerStatus('leave');
				timerRef.current = window.setTimeout(() => {
					setInnerStatus(null);
				}, 500);
			}
		} else {
			setInnerStatus('active');
		}
	}, [pIcon, pActiveIcon, innerStatus]);

	useEffect(() => {
		return () => {
			if (timerRef.current !== null) {
				window.clearTimeout(timerRef.current);
			}
		};
	}, []);

	return (
		<i
			className={`oos-dynamic-icon ${pSize} ${pIcon} ${pStatic === false && innerStatus !== null ? innerStatus : ''} ${pClassName}`}
			{...(pStatic ? {} : (pSelfControl ? {onMouseEnter, onMouseLeave} : {}))}
		/>
	);
};

export default OOSDynamicIcon;
