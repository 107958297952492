const zhCN = {
	documentTitle: '产品文档详情 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '产品文档详情',
		subTitle: '浏览文档详细内容，并反馈文档意见',
	},
	document: {
		downloadDocument: '下载文档',
	},
	feedback: {
		title: '反馈文档意见',
		subTitle: '您的宝贵意见是我们改进的动力',
		contentPlh: '请简述您对该文档的反馈意见，请尽量注明文档章节或其他细节位置，长度为10-200个字',
		contactTips: '奥卡云公司可能会与您取得联系，以便帮助您尽快地解决问题，请填写您的联系信息。',
		contactPlh: '联系人',
		phonePlh: '电话号码',
		emailPlh: '邮箱',
		companyPlh: '公司或组织名称',
		readAndAgree: '我已阅读并同意',
		privacyStatementName: '《奥卡云官方网站隐私声明》，',
		dataStore: '知道并同意将涉及的个人数据存储在奥卡云的服务器上。',
		isProblemSolved: '该文档是否解决了您的问题：',
		yes: '是',
		no: '否',
		browseOnly: '仅浏览',
		submit: '提交',
		submitting: '提交中...',
		validation: {
			feedbackIsShort: '请输入您的反馈意见，长度为10-200字！',
			phoneIncorrect: '请输入正确的手机号或座机号（区号-号码）！',
			emailIncorrect: '请输入正确的邮箱地址！',
			isProblemEmpty: '请选择该文档是否解决了您的问题！',
			readPrivacyStatement: '请阅读并同意《奥卡云官方网站隐私声明》！',
		},
		actionOk: '文档反馈意见提交成功',
		actionOkTips: '感谢您提交文档反馈意见，我们的工作人员将仔细阅读意见的内容。',
		actionFails: '文档反馈意见提交失败，原因：',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
