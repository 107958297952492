const zhCN = {
	documentTitle: '产品图库详情 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '产品图库详情',
		subTitle: '获取对应产品的前视图、后视图、图标等图片',
	},
	gallery: {
		downloadAll: '下载全部',
		downloadOne: '下载',
		clickToShowLargerOne: '点击后在下方展示大图',
		clickToDownload: '点击下载',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
