const zhCN = {
	documentTitle: '企业资讯 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '企业资讯',
		subTitle: '第一时间了解企业最新资讯，掌握企业动态',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
