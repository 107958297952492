import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import generalAction from '@actions/generalAction';
import {useDispatch, useI18n, useSelector, useLocation, useNavigate} from '@hooks';
import {DOM, equals} from '@utils';
import {State} from '@redux/interfaces';
import routes from '@routes';
import MENU_CLOSE_PNG from '@images/bar/pad_and_ph_drop_down_menu_close.webp';

interface SelectedState {
	padAndPhDropDownMenuActive: boolean;
}

const DropdownMenu: FC = () => {
	// i18n.
	const i18n = useI18n('TopBar');

	// Router.
	const location = useLocation();
	const navigate = useNavigate();

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {padAndPhDropDownMenuActive}} = state;
		return {padAndPhDropDownMenuActive};
	}, []);

	const {padAndPhDropDownMenuActive} = useSelector<State, SelectedState>(selectState, equals);

	const dispatch = useDispatch();

	const setPadAndPhDropDownMenuActive = useCallback((padAndPhDropDownMenuActive: boolean) => dispatch(generalAction.setPadAndPhDropDownMenuActive(padAndPhDropDownMenuActive)), [dispatch]);

	// Internal states.
	const [closeBtnRock, setCloseBtnRock] = useState(false);
	const [activeSolutionCategory, setActiveSolutionCategory] = useState('');
	const [activePage, setActivePage] = useState('');

	// Ref data.
	const hideMenuTimerRef = useRef<number | null>(null);
	const storageSolutionPathArrRef = useRef<string[]>([
		routes.BigData,
		routes.HighPerformanceComputing,
		routes.CloudComputingPrivateCloud,
		routes.CloudNativeContainerStorage,
		routes.DataBackup,
		routes.NewEnergyVehicle,
		routes.IntelligentCampus,
		routes.IntelligentSecurity,
		routes.AI,
	]);
	const iotSolutionPathArrRef = useRef<string[]>([
		routes.BrightProject,
		routes.MajorEventSecurity,
		routes.IntelligentCommunity,
		routes.IntelligentCommunitySecurity,
		routes.IntelligentElevator,
		routes.HighAltitudeThrowObject,
		routes.IntelligentPark,
		routes.IntelligentScenicSpot,
		routes.AllAreaTourism,
		routes.CarbonNeutrality,
		routes.IntelligentGarden,
		routes.EmergencyCommand,
		routes.SafetyProductionEmergency,
		routes.IoTPlatform,
	]);

	// Page actions.
	const setActivePageAndMenuByPath = useCallback(() => {
        let pathname = location.pathname;
        // Remove sub-path for querying.
		let slashSplitStr = pathname.split('/');
        if (slashSplitStr.length > 2) {
        	pathname = '/' + slashSplitStr[1];
		}
        // Handle special routes.
		if (pathname === routes.DocumentDetail) {
			pathname = routes.Document;
		}
		if (pathname === routes.ImageGalleryDetail) {
			pathname = routes.ImageGallery;
		}
		if (pathname === routes.FirmNewsDetail) {
			pathname = routes.FirmNews;
		}
   		setActivePage(pathname);
		// If the path is for solution, should active the corresponding solution category.
		if (storageSolutionPathArrRef.current.includes(pathname)) {
			setActiveSolutionCategory('storage-solution');
		} else if (iotSolutionPathArrRef.current.includes(pathname)) {
			setActiveSolutionCategory('iot-solution');
		}
    }, [location]);

	const onSolutionCategoryClick = useCallback((solutionCategory: string) => {
		if (activeSolutionCategory === solutionCategory) {
			setActiveSolutionCategory('');
		} else {
			setActiveSolutionCategory(solutionCategory);
		}
	}, [activeSolutionCategory]);

	const onHideMenuClick = useCallback(() => {
		setCloseBtnRock(true);
		setPadAndPhDropDownMenuActive(false);
        const bodyDOM = DOM.getBody();
        DOM.removeClass(bodyDOM, 'scroll-disabled');
        hideMenuTimerRef.current = window.setTimeout(() => setCloseBtnRock(false), 500);
    }, [setPadAndPhDropDownMenuActive]);

	const onForwardPageClick = useCallback((path: string, isSolutionPath: boolean) => {
        if (location.pathname !== path){
            navigate(path);
            onHideMenuClick();
			if (!isSolutionPath) {
				setActiveSolutionCategory('');
			}
        }
	}, [location, navigate, onHideMenuClick]);

	// After rendering and re-rendering.
	useEffect(() => {
        // Immediately call it when component did-mount to set active items.
        setActivePageAndMenuByPath();

        // Bind a hash change event listener to set active items.
        window.addEventListener('hashchange', setActivePageAndMenuByPath);

        // Remove the listener at un-mount time.
        return () => {
            window.removeEventListener('hashchange', setActivePageAndMenuByPath);
        };
    }, [setActivePageAndMenuByPath]);

	useEffect(() => {
		return () => {
			if (hideMenuTimerRef.current !== null) {
				window.clearTimeout(hideMenuTimerRef.current);
			}
		};
	}, []);

	// Memorized data.
	const menusMemo = useMemo(() => ([
		{
			name: 'home',
			submenus: [
				{
					name: i18n('menu.home'),
					path: routes.Home,
				},
			]
		}, {
			name: 'product',
			submenus: [
				{
					name: i18n('product.uniio'),
					path: routes.UniIO,
				}, {
					name: i18n('product.orcafs'),
					path: routes.OrcaFS,
				}, {
					name: i18n('product.orcabd'),
					path: routes.OrcaBD,
				},
			]
		}, {
			name: 'storage-solution',
			submenus: [
				// Storage solution.
				// General category.
				{
					name: i18n('solution.bigData'),
					path: routes.BigData,
				}, {
					name: i18n('solution.highPerformanceComputing'),
					path: routes.HighPerformanceComputing,
				}, {
					name: i18n('solution.cloudComputingPrivateCloud'),
					path: routes.CloudComputingPrivateCloud,
				}, {
					name: i18n('solution.cloudNativeContainerStorage'),
					path: routes.CloudNativeContainerStorage,
				}, {
					name: i18n('solution.dataBackup'),
					path: routes.DataBackup,
				},
				// Industry category.
				{
					name: i18n('solution.newEnergyVehicle'),
					path: routes.NewEnergyVehicle,
				}, {
					name: i18n('solution.intelligentCampus'),
					path: routes.IntelligentCampus,
				}, {
					name: i18n('solution.intelligentSecurity'),
					path: routes.IntelligentSecurity,
				}, {
					name: i18n('solution.ai'),
					path: routes.AI,
				},
			]
		}, {
			name: 'iot-solution',
			submenus: [
				// IoT solution.
				// Intelligent campus category.
				{
					name: i18n('solution.intelligentCampus'),
					path: routes.IntelligentCampus,
				},
				// Intelligent police category.
				{
					name: i18n('solution.brightProject'),
					path: routes.BrightProject,
				}, {
					name: i18n('solution.majorEventSecurity'),
					path: routes.MajorEventSecurity,
				},
				// Intelligent community category.
				{
					name: i18n('solution.intelligentCommunity'),
					path: routes.IntelligentCommunity,
				}, {
					name: i18n('solution.intelligentCommunitySecurity'),
					path: routes.IntelligentCommunitySecurity,
				}, {
					name: i18n('solution.intelligentElevator'),
					path: routes.IntelligentElevator,
				}, {
					name: i18n('solution.highAltitudeThrowObject'),
					path: routes.HighAltitudeThrowObject,
				},
				// Intelligent park category.
				{
					name: i18n('solution.intelligentPark'),
					path: routes.IntelligentPark,
				},
				// Intelligent scenic spot category.
				{
					name: i18n('solution.intelligentScenicSpot'),
					path: routes.IntelligentScenicSpot,
				}, {
					name: i18n('solution.allAreaTourism'),
					path: routes.AllAreaTourism,
				},
				// Intelligent garden category.
				{
					name: i18n('solution.intelligentGarden'),
					path: routes.IntelligentGarden,
				},
				// Carbon neutrality category.
				{
					name: i18n('solution.carbonNeutrality'),
					path: routes.CarbonNeutrality,
				},
				// Emergency category.
				{
					name: i18n('solution.emergencyCommand'),
					path: routes.EmergencyCommand,
				}, {
					name: i18n('solution.safetyProductionEmergency'),
					path: routes.SafetyProductionEmergency,
				},
				// General category.
				 {
					name: i18n('solution.ai'),
					path: routes.AI,
				},
			]
		}, {
			name: 'technicalSupport',
			submenus: [
				{
					name: i18n('technicalSupport.document'),
					path: routes.Document,
				}, {
					name: i18n('technicalSupport.afterSalesCase'),
					path: routes.AfterSalesCase,
				}, {
					name: i18n('technicalSupport.imageGallery'),
					path: routes.ImageGallery,
				}, {
					name: i18n('technicalSupport.guarantee'),
					path: routes.Guarantee ,
				}
			]
		}, {
			name: 'news',
			submenus: [
				{
					name: i18n('menu.firmNews'),
					path: routes.FirmNews,
				},
			]
		}, {
			name: 'about',
			submenus: [
				{
					name: i18n('about.aboutUs'),
					path: routes.AboutUs,
				},/* {
					name: i18n('about.leadership'),
					path: routes.Leadership,
				}, */{
					name: i18n('about.honor'),
					path: routes.Honor,
				}, {
					name: i18n('about.recruitment'),
					path: routes.Recruitment,
				}, {
					name: i18n('about.contactUs'),
					path: routes.ContactUs,
				},
			]
		}
	]), [i18n]);

	return (
		<nav className={`oos-pad-and-ph-drop-down-menu ${padAndPhDropDownMenuActive ? 'active' : ''}`}>
			<div className="menu-nav-title">{i18n('padAndPhDropDownMenuTitle')}</div>
			<>
				{
					menusMemo.map(menu => (
						<div
							key={menu.name}
							className="menu-section"
						>
							{
								menu.name === 'storage-solution' ? (
										<div
											className={`menu-solution-category-name-ph ${menu.name === activeSolutionCategory ? 'active' : ''}`}
											onClick={onSolutionCategoryClick.bind(null, 'storage-solution')}
										>
											<span className="name-ph">{i18n('solutionCategory.storage')}</span>
											<i className="arrow-ph" />
										</div>
									) :
									null
							}
							{
								menu.name === 'iot-solution' ? (
										<div
											className={`menu-solution-category-name-ph ${menu.name === activeSolutionCategory ? 'active' : ''}`}
											onClick={onSolutionCategoryClick.bind(null, 'iot-solution')}
										>
											<span className="name-ph">{i18n('solutionCategory.iot')}</span>
											<i className="arrow-ph" />
										</div>
									) :
									null
							}
							<div className={`menu-box ${menu.name === 'storage-solution' || menu.name === 'iot-solution' ? 'solution' : ''} ${activeSolutionCategory === menu.name ? 'active' : ''}`}>
								{
									menu.submenus.map(submenu => (
										<span
											className={`menu-item ${submenu.path === activePage ? 'active' : ''}`}
											key={submenu.name}
											onClick={onForwardPageClick.bind(null, submenu.path, menu.name === 'storage-solution' || menu.name === 'iot-solution')}
										>
											{submenu.name}
										</span>
									))
								}
							</div>
						</div>
					))
				}
			</>
			<div className="close-button" onClick={onHideMenuClick}>
				<img className={`${closeBtnRock ? 'rock' : ''}`} alt="" src={MENU_CLOSE_PNG} />
			</div>
		</nav>
	);
}

export default DropdownMenu;
