const zhCN = {
	documentTitle: '雪亮工程解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '雪亮工程解决方案',
		subTitle: '“雪亮工程”是新形势下维护国家安全和社会稳定、预防和打击暴力恐怖犯罪的重要手段，是常态化、信息化条件下完善社会治安防控体系、深化平安中国建设的重要基础性工程。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		hardToConstruct: {
			title: '施工整合难度大',
			desc: '部分前端位置偏远，取电、布线难度都比较大，整个工程下来施工费占比高，能建设的内容相对较少。且雪亮工程是由政法委、公安局、综治办一起规划实施完成，对于有些已建设的系统需要整合，又要涉及到城建等单位，多单位协同合作导致雪亮工程实施缓慢。',
		},
		weakConsciousness: {
			title: '群防意识薄弱',
			desc: '对于部分还没有建立起群防意识的居民来说，他们对“雪亮工程”并不了解且不太关心，也缺少对相关设备的保护意识，导致工程失去了意义，并且后期运维成本高。除此之外，基层工作与现代化管理环境存在差距，人员数量配置不足，导致群防群控落地难；社区网格化管理与雪亮工程前端网格化建设与管理不匹配，不规范，导致存在监控死角。',
		},
		haveLeak: {
			title: '布点存在缺漏，视频解析有待提升',
			desc: '人脸大数据平台的布点尚有缺漏，针对人员布控和车辆布控等功能应用，图片/视频人像解析准确率不够，核心技术应用有待提升。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '雪亮工程解决方案介绍',
			desc1: '“雪亮工程”是新形势下维护国家安全和社会稳定、预防和打击暴力恐怖犯罪的重要手段，是常态化、信息化条件下完善社会治安防控体系、深化平安中国建设的重要基础性工程。以县、乡、村三级综治中心为指挥平台、以综治信息化为支撑、以网格化管理为基础、以公共安全视频监控联网应用为重点的“群众性治安防控工程”。',
			desc2: '奥卡数依靠多年平安城市、智慧城市的建设经验，针对“雪亮工程”的特点和需求，结合深度学习技术、大数据技术、信息共享技术推出完整解决方案，本方案提出密织防控网络、深度学习数据挖掘、强化系统联网和信息共享的建设思路，构建一个成熟、高效、先进的“雪亮工程”，打造共建、共治、共享的格局。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		rationalLayout: {
			title: '合理布局',
			desc: '通过在不同区域、场景，因地制宜地部署AI感知产品，实现全方位、全天候的多维信息感知网络。全面提升对于人、车、物等信息的采集能力，推进全域覆盖程度。',
		},
		informationSharing: {
			title: '信息共享',
			desc: '以信息资源交换共享平台为核心，以公安视频专网、政务外网、互联网为承载体，分级有效整合各类视频图像资源，促进点位互补、平台互通、网络互联，最大限度实现公共区域视频图像资源的联网整合。',
		},
		scientificOperation: {
			title: '科学运维',
			desc: '视频设备运行状态实时监测、视频质量诊断、设备故障事件第一时间主动告知，并能够及时、准确分析和定位故障根源，建立设备档案制度，规范化管理。',
		},
		systemSecurity: {
			title: '强化系统安全',
			desc: '遵循《信息安全等级保护管理办法》等国家相关规定，围绕网络安全传输、系统安全保障、重要信息安全管理等方面建设，将整个系统分为外部前端层、系统层、网络层、数据层和应用层，对各层采取不同的安全技术措施，构建“事前检测+事中防护+事后审计”安全防护体系，提升公共安全视频监控系统安全防护能力。',
		},
		aiAndBigData: {
			title: 'AI大数据应用',
			desc: '以AI及视频大数据应用为核心，进行视频图像结构化建设。结合深度字习算法，对海量视频中的人、车、活动目标进行结构化数据提取，变成有价值的结构化信息。从而提高系统分析研判能力，推动视频监控应用模式从事后被动处置向事前主动预防转变。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		qinHanNewCity: {
			title: '秦汉新城雪亮工程建设项目',
			introduction: '秦汉新城“雪亮工程”项目建设，共计需要建设前端1380路，前端类型有智能人脸抓拍相机、车辆智能分析相机、交通卡口相机、电子警察、全景AR相机，普通治安监控相机，主要布防在重点单位、人员密集区域、城市主要道路、公园景区、历史博物馆、事件易发道路要口，同时覆盖城镇公共区域、加大城乡结合部、农村公共区域、重点林区、以及省、县级行政区域重点界线界桩、界线以及进出要道、核心区域、重点地段等。通过统一的建设，既保证了新城城市综合治理的视频资源，也给公安系统提供了社会治安防控体系建设的视频资源，同时保证了公安系统的网络安全性，通过一次建设，多家共用，有效的避免各单位重复建设。通过实现视频资源统一调度，平台统一权限设置，做到视频资源精细化管理，权限收放自如。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
