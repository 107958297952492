import React, {lazy, FC, Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import generalAction from '@actions/generalAction';
import {useAccessStatistics, useDispatch} from '@hooks';
import {OOSPlaceholder} from '@components';
import {DOM} from '@utils';
import {WindowWidthAndDeviceMode} from '@interfaces';
import routes from '@routes';

// Bar.
import TopBar from '@views/Bar/TopBar';
import ApplyTrialBar from '@views/Bar/ApplyTrialBar';
import FootBar from '@views/Bar/FootBar';
import CookiePolicyBar from '@views/Bar/CookiePolicyBar';

// Home.
import Home from '@views/Home/Home';

// Product.
const UniIO = lazy(() => import('@views/Product/UniIO'));
const OrcaBD = lazy(() => import('@views/Product/OrcaBD'));
const OrcaFS = lazy(() => import('@views/Product/OrcaFS'));

// Solution.
// Storage solution.
// General category.
const BigData = lazy(() => import('@views/Solution/BigData'));
const HighPerformanceComputing = lazy(() => import('@views/Solution/HighPerformanceComputing'));
const CloudComputingPrivateCloud = lazy(() => import('@views/Solution/CloudComputingPrivateCloud'));
const CloudNativeContainerStorage = lazy(() => import('@views/Solution/CloudNativeContainerStorage'));
const DataBackup = lazy(() => import('@views/Solution/DataBackup'));
// Industry category.
const NewEnergyVehicle = lazy(() => import('@views/Solution/NewEnergyVehicle'));
const IntelligentCampus = lazy(() => import('@views/Solution/IntelligentCampus'));
const IntelligentSecurity = lazy(() => import('@views/Solution/IntelligentSecurity'));
const AI = lazy(() => import('@views/Solution/AI'));
// IoT solution.
// Intelligent police category.
const BrightProject = lazy(() => import('@views/Solution/BrightProject'));
const MajorEventSecurity = lazy(() => import('@views/Solution/MajorEventSecurity'));
// Intelligent community category.
const IntelligentCommunity = lazy(() => import('@views/Solution/IntelligentCommunity'));
const IntelligentCommunitySecurity = lazy(() => import('@views/Solution/IntelligentCommunitySecurity'));
const IntelligentElevator = lazy(() => import('@views/Solution/IntelligentElevator'));
const HighAltitudeThrowObject = lazy(() => import('@views/Solution/HighAltitudeThrowObject'));
// Intelligent park category.
const IntelligentPark = lazy(() => import('@views/Solution/IntelligentPark'));
// Intelligent scenic spot category.
const IntelligentScenicSpot = lazy(() => import('@views/Solution/IntelligentScenicSpot'));
const AllAreaTourism = lazy(() => import('@views/Solution/AllAreaTourism'));
// Carbon neutrality category.
const CarbonNeutrality = lazy(() => import('@views/Solution/CarbonNeutrality'));
// Intelligent garden category.
const IntelligentGarden = lazy(() => import('@views/Solution/IntelligentGarden'));
// Emergency category.
const EmergencyCommand = lazy(() => import('@views/Solution/EmergencyCommand'));
const SafetyProductionEmergency = lazy(() => import('@views/Solution/SafetyProductionEmergency'));
// General category.
const IoTPlatform = lazy(() => import('@views/Solution/IoTPlatform'));

// Technical support.
const Document = lazy(() => import('@views/TechnicalSupport/Document'));
const DocumentDetail = lazy(() => import('@views/TechnicalSupport/DocumentDetail'));
const AfterSalesCase = lazy(() => import('@views/TechnicalSupport/AfterSalesCase'));
const ImageGallery = lazy(() => import('@views/TechnicalSupport/ImageGallery'));
const ImageGalleryDetail = lazy(() => import('@views/TechnicalSupport/ImageGalleryDetail'));
const Guarantee = lazy(() => import('@views/TechnicalSupport/Guarantee'));

// News.
const FirmNews = lazy(() => import('@views/FirmNews/FirmNews'));
const FirmNewsDetail = lazy(() => import('@views/FirmNews/FirmNewsDetail'));

// About.
const AboutUs = lazy(() => import('@views/About/AboutUs'));
// const Leadership = lazy(() => import('@views/About/Leadership'));
const Honor = lazy(() => import('@views/About/Honor'));
const Recruitment = lazy(() => import('@views/About/Recruitment'));
const ContactUs = lazy(() => import('@views/About/ContactUs'));

// Not found.
const NotFound = lazy(() => import('@views/NotFound/NotFound'));

// Three.js demo.
const Mountain = lazy(() => import('@views/ThreeDemo/Mountain'));

const App: FC = () => {
	// Redux related.
	const dispatch = useDispatch();

	const setWindowWidthAndDeviceMode = useCallback((windowWidthAndDeviceMode: WindowWidthAndDeviceMode) => dispatch(generalAction.setWindowWidthAndDeviceMode(windowWidthAndDeviceMode)), [dispatch]);

	// Ref data.
	const windowWidthRef = useRef(0);

	// Internal states.
	const [cookiePolicyBarVisible, setCookiePolicyBarVisible] = useState(true);

	// Page actions.
	const hideCookieConsent = useCallback(() => setCookiePolicyBarVisible(false), []);

	// Monitor window size change.
	useEffect(() => {
		const resizeCallback = () => {
			const windowWidth = DOM.getWindowWidth();
			if (windowWidth !== windowWidthRef.current) {
				windowWidthRef.current = windowWidth;
				const deviceMode = DOM.getDeviceMode(windowWidth);
				setWindowWidthAndDeviceMode({
					windowWidth,
					deviceMode,
				});
			}
		}

		window.addEventListener('resize', resizeCallback);

		return () => {
			window.removeEventListener('resize', resizeCallback);
		};
	}, [setWindowWidthAndDeviceMode]);

	// Reference equivalent child components.
	const TopBarRef = useRef(<TopBar />);
	const ApplyTrialBarRef = useRef(<ApplyTrialBar />);
	const FootBarRef = useRef(<FootBar />);
	const OOSPlaceholderRef = useRef(<OOSPlaceholder />);

	// Access Statistics.
	useAccessStatistics('index');

	return (
		<BrowserRouter>
			{TopBarRef.current}
			<div className="oos-main-content">
				<Suspense fallback={OOSPlaceholderRef.current}>
					<Routes>
						<Route path={routes.Home} element={<Home />} />

						<Route path={routes.UniIO} element={<UniIO />} />
						<Route path={routes.OrcaFS} element={<OrcaFS />} />
						<Route path={routes.OrcaBD} element={<OrcaBD />} />

						{/* Storage solution. */}
						{/* General category. */}
						<Route path={routes.BigData} element={<BigData />} />
						<Route path={routes.HighPerformanceComputing} element={<HighPerformanceComputing />} />
						<Route path={routes.CloudComputingPrivateCloud} element={<CloudComputingPrivateCloud />} />
						<Route path={routes.CloudNativeContainerStorage} element={<CloudNativeContainerStorage />} />
						<Route path={routes.DataBackup} element={<DataBackup />} />
						{/* Industry category. */}
						<Route path={routes.NewEnergyVehicle} element={<NewEnergyVehicle />} />
						<Route path={routes.IntelligentCampus} element={<IntelligentCampus />} />
						<Route path={routes.IntelligentSecurity} element={<IntelligentSecurity />} />
						<Route path={routes.AI} element={<AI />} />
						{/* IoT solution. */}
						{/* Intelligent police category. */}
						<Route path={routes.BrightProject} element={<BrightProject />} />
						<Route path={routes.MajorEventSecurity} element={<MajorEventSecurity />} />
						{/* Intelligent community category. */}
						<Route path={routes.IntelligentCommunity} element={<IntelligentCommunity />} />
						<Route path={routes.IntelligentCommunitySecurity} element={<IntelligentCommunitySecurity />} />
						<Route path={routes.IntelligentElevator} element={<IntelligentElevator />} />
						<Route path={routes.HighAltitudeThrowObject} element={<HighAltitudeThrowObject />} />
						{/* Intelligent park category. */}
						<Route path={routes.IntelligentPark} element={<IntelligentPark />} />
						{/* Intelligent scenic spot category. */}
						<Route path={routes.IntelligentScenicSpot} element={<IntelligentScenicSpot />} />
						<Route path={routes.AllAreaTourism} element={<AllAreaTourism />} />
						{/* Carbon neutrality category. */}
						<Route path={routes.CarbonNeutrality} element={<CarbonNeutrality />} />
						{/* Intelligent garden category. */}
						<Route path={routes.IntelligentGarden} element={<IntelligentGarden />} />
						{/* Emergency category. */}
						<Route path={routes.EmergencyCommand} element={<EmergencyCommand />} />
						<Route path={routes.SafetyProductionEmergency} element={<SafetyProductionEmergency />} />
						{/* General category. */}
						<Route path={routes.IoTPlatform} element={<IoTPlatform />} />

						<Route path={routes.Document} element={<Document />} />
						<Route path={routes.DocumentDetail} element={<DocumentDetail />} />
						<Route path={routes.AfterSalesCase} element={<AfterSalesCase />} />
						<Route path={routes.ImageGallery} element={<ImageGallery />} />
						<Route path={routes.ImageGalleryDetail} element={<ImageGalleryDetail />} />
						<Route path={routes.Guarantee} element={<Guarantee />} />

						<Route path={routes.FirmNews} element={<FirmNews />} />
						<Route path={routes.FirmNewsDetail} element={<FirmNewsDetail />} />

						<Route path={routes.AboutUs} element={<AboutUs />} />
						{/* <Route path={routes.Leadership} element={<Leadership />} /> */}
						<Route path={routes.Honor} element={<Honor />} />
						<Route path={routes.Recruitment} element={<Recruitment />} />
						<Route path={routes.ContactUs} element={<ContactUs />} />

						{
							// Three.js demo.
							process.env.NODE_ENV === "development" && (
								<Route path={routes.Mountain} element={<Mountain />} />
							)
						}

						{/* Redirect root route to Home page. */}
						<Route path={routes.Root} element={<Navigate to={routes.Home} replace />} />

						{/* Redirect not matched route to NotFound page. */}
						{
							// In server side production env, there is a Nginx server before Nest.js.
							// The Nginx server is responsible for routing the static resources and
							// RESTfull APIs, it does not handle the latter, and route it to Nest.js.
							// If the Nginx server receives a http request url (without the prefix '/api/')
							// such as 'https://orcadt.com/abcdefg' from typing in browser address bar,
							// it will respond the index.html as content, and then the React app will
							// be created, then will route the url path '/abcdefg' to <NotFound />.
						}
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>
			</div>
			{ApplyTrialBarRef.current}
			{FootBarRef.current}
			{cookiePolicyBarVisible && (<CookiePolicyBar hide={hideCookieConsent} />)}
		</BrowserRouter>
	)
};

export default App;
