const zhCN = {
	documentTitle: 'OrcaBD 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: 'OrcaBD分布式存储',
		subTitle: 'OrcaBD是一款面向视频监控、容灾备份、数据归档等领域的分布式存储产品。',
	},
	introduction: {
		title: '产品介绍',
		subTitle: '产品的功能特性、定位与应用方向',
		name: 'OrcaBD分布式存储',
		desc1: 'OrcaBD采用全新的分布式软件架构，通过存储系统软件将服务器的本地存储资源构建为统一的资源池。支持融合存储访问协议、大容量、高性能、高可靠、智能运维等特性，通过Amazon S3/swift/iSCSI/FC等接口协议为用户提供块存储服务和对象存储服务。',
		desc2: 'OrcaBD通过存储系统软件将服务器的本地存储资源组织起来，构建全分布式存储池。通过iSCSI/FC和S3接口向上层应用提供块存储和对象存储服务，满足云资源池及数据库等场景的存储需求。通过横向扩展基础架构，可轻松实现单存储系统扩展至数千个存储节点，形成海量数据存储空间。系统支持EC、副本、快照等特性，端到端的保护业务数据可靠性，构建一个容量按需扩展、性能按需供给、服务按需定义的海量存储系统。',
		scenario: {
			videoMonitor: '视频监控',
			hybridCloudStorage: '混合云存储',
			dataArchiving: '数据归档',
			disasterRecoveryBackup: '容灾备份',
		},
		feature: {
			fusionArchitecture: {
				name: '融合架构',
				desc: 'OrcaBD通过业界标准传输协议（iSCSI协议、FC协议、S3协议）对上层提供块存储服务和对象存储服务。不仅支持Linux、Windows等主流操作系统客户端，同时也支持OpenStack等计算、大数据平台，满足用户不同负载应用的需求。',
			},
			intelligentLayering: {
				name: '智能分层',
				desc: '冷、热数据动态分析，智能分层。实时数据分析和智能分层，后台自动分析哪些是热点数据，热点数据自动搬迁，性能显著提升；I/0请求在后台不断被分析，顺序工作负载移动到硬盘层以获取最大吞吐量，随机工作负载转移到SSD层以获取最大I0PS支持。',
			},
			lowCost: {
				name: '低成本',
				desc: '支持通用X86架构服务器及国产服务器。OrcaBD支持自动精简配置，实现按需分配，能为应用提供比实际物理存储更多的虚拟存储资源。通过优化存储结构对数据进行压缩，减少业务数据实际占用的存储空间，显著提高存储空间利用率。',
			},
			easyToExpand: {
				name: '易扩展',
				desc: '横向扩展，最大支持4096个节点。OrcaBD通过分布式哈希算法，分布式智能Cache等关键技术，构建了整个存储系统高性能、高可靠的特点。集群节点支持大规模Scale-out，可弹性扩容至上千节点。提供容量性能线性增长能力，更快响应不断变化的业务需求。',
			},
			highReliability: {
				name: '高可靠',
				desc: 'OrcaBD支持多副本数据保护机制和纠删码。前者将不同副本放置在不同节点或机柜中，当一个存储节点或机柜故障，不会丢失数据，整个存储系统运行连续。纠删码应用EC技术实现节点间RAID功能，支持配置保护级别内的任意节点或任意硬盘故障而不影响数据完整性。',
			},
			easyToUse: {
				name: '简单易用',
				desc: '统一GUI，实时监控及故障预测。提供统一GUI管理，统一管理系统资源。实时检测系统及各个节点的资源、性能等；支持SSD寿命预测，减少SSD写入损；提供容量性能顸测等智能运维功能。',
			},
		},
	},
	scenario: {
		title: '典型应用场景',
		subTitle: '还原真实的客户使用场景',
		videoMonitor: {
			name: '视频监控',
			desc: '视频监控要求存储系统可以处理图像数据爆炸式增长，并且简单易用，数据安全性高。',
			ability1: '采用智能分层，冷、热数据动态分析。',
			ability2: '采用融合架构，支持块存储服务和对象存储服。',
			ability3: '统一的GUI，实时监控及故障预测。',
		},
		hybridCloudStorage: {
			name: '混合云存储',
			desc: '混合云存储要求私有云存储和公有云存储两者相互结合对外提供存储服务。',
			ability1: '将用户活动数据在私有云存储，将归档备份数据在公有云存储，针对企业混合云应用提供整体解決方案。',
			ability2: '采用融合架构，支持块存储服务和对象存储服务。',
			ability3: '采用融合架构，支持块存储服务和对象存储服务。',
		},
		dataArchiving: {
			name: '数据归档',
			desc: '数据归档要求处理文件繁多、数据规模较大的海量数据时存储系统的高效性和便捷性。',
			ability1: '采用融合架构，支持块存储服务和对象存储服务。',
			ability2: '采用智能分层，冷、热数据动态分析。',
			ability3: '通过自主研发的EC算法，采用小I/O聚合、大I/O直通等技木，实现业务均衡分布。',
			ability4: '采用高速SSD加速方案，将各个存储节点上的SSD组建成为一个共享的分布式快速存储池，供所有的业务共同使用，实现HDD主存的性能加速。',
		},
		disasterRecoveryBackup: {
			name: '容灾备份',
			desc: '容灾备份要求灾难发生后，存储系统数据的完整性和准确性。',
			ability1: '支持纠删码和副本冗余保护机制。',
			ability2: '本地、异地存储备份和云端备份方案，为用户规避灾难带来的数据丢失风险。',
			ability3: '统一的GUI，实时监控及故障预测。',
		},
	},
	specification: {
		title: '技术规格',
		subTitle: '软硬件系统指标',
	},
	resource: {
		title: '相关资源',
		subTitle: '产品有关的文档、图库等资源',
		datasheet: 'Datasheet',
		document: '产品文档',
		imageGallery: '产品图库',
		clickToDownload: '点击下载',
		clickToForward: '点击跳转',
	},
	case: {
		title: '产品案例',
		subTitle: '优质的产品实施与客户服务案例',
		xiBeiNongLinKeJiDaXue: {
			title: '西北农林科技大学智慧教室',
			introduction: '西北农林科技大学教学资源存储系统建设项目涉及多媒体教室升级改造，包括有智慧教室，手机互动型多媒体教室等。同时，为了满足学校常态化录播需求、多媒体教室摄像机和VGA采集软件的高效运行，本次项目采购分布式存储构建分布式集群、提供大容量、高性能需求。通过奥卡云OrcaBD分布式存储，搭建十几台分布式存储组成的集群，提供4PB的统一存储资源池。通过十几台分布式存储构建的高性能存储池，利用十几台分布式存储设备形成性能叠加效应，提供GB级高性能，满足日常录播、疫情期间直播需求、同时为智慧教室的各类软件提供高效的存储服务。融合架构，同时提供块存储服务和对象存储服务。块存储服务主要用来提供视频课程录制和播放数据存储；对象存储服务替换学校原有的网盘应用。',
			point1: '客户收益：',
			point2: '解决了单一存储服务的缺陷，实现多种存储服务融合提供多种应用接口的存储要求；简化管理维护过程，降低多品牌兼容问题。',
			point3: '提供高达4PB的统一资源池，数据存取统一，避免形成“数据孤岛”，降低数据管理难度',
			point4: 'GB级带宽响应，满足日常录播、直播、回放需求；解决上万同学同时访问前端应用造成的性能不足，长时间无响应问题；同时帮助老师随时、随地、快速存取课件和相关科研资料。在基础设施层为学校信息化建设和教学提供有力支撑保障。',
			point5: '分布式架构，横向无限制扩展，上层应用无感知扩展等，满足未来多年优质教学资源落盘和服务按需部署、快速交付、数据集中保护、业务不中断的服务要求。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
