import {MouseEvent, useCallback, useEffect, useRef, useState} from 'react';
import {ssGet, ssRemove} from '@utils';

type SectionNavTuple = [
	string,
	() => void,
	(
		title: string,
		e: MouseEvent<HTMLSpanElement> | null,
		noSmooth?: boolean
	) => void
];

export default function useSectionNav (
	sectionTitles: string[],
	navCheckKey: string | null = null,
	topOffset: number = 0,
): SectionNavTuple {
	const [activeNav, setActiveNav] = useState(sectionTitles[0]);

	const activeNavIndexRef = useRef(0);

	const scrollTimerRef = useRef<number | null>(null);

	const setActivNavAndIndex = useCallback((inViewPortTitleArr: [string, number][]) => {
		// The last one in array is the newest active one.
		const [title, index] = inViewPortTitleArr[inViewPortTitleArr.length - 1]
		setActiveNav(title);
		activeNavIndexRef.current = index;
	}, []);

	const getViewportHeight = useCallback(() => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight, []);

	const isDOMInViewport = useCallback((
		targetDOM: HTMLElement | Element,
		checkType: 'middle' | 'whole' = 'whole'
	) => {
		const viewportHeight = getViewportHeight();
		// Get the distance of the target DOM from the top of the viewport.
		const rect = targetDOM.getBoundingClientRect();
		if (checkType === 'middle') {
			let targetDOMTop = rect.top;
			if (targetDOMTop >= 0) {
				return targetDOMTop < (viewportHeight / 2 - topOffset);
			} else {
				return false;
			}
		} else {
			let targetDOMBottom = rect.bottom;
			if (targetDOMBottom >= 0) {
				return targetDOMBottom < viewportHeight;
			} else {
				return false;
			}
		}
	}, [getViewportHeight, topOffset]);

	const checkActiveNav = useCallback(() => {
		const inViewPortTitleArr: [string, number][] = [];
		// Traverse section title DOM.
		for (let i = 0; i < sectionTitles.length; i ++) {
			let title = sectionTitles[i];
			let titleDOM = document.getElementById(title);
			if (titleDOM !== null) {
				if (isDOMInViewport(titleDOM, 'middle')) {
					inViewPortTitleArr.push([title, i]);
				}
			}
		}

		if (inViewPortTitleArr.length > 0) {
			setActivNavAndIndex(inViewPortTitleArr);
		} else {
			// There is no section title shows in current view port, then check the active section content.
			// This case may happen when viewport only displays the section content, such as: scroll to the
			// bottom then reload page.
			// Traverse section content DOM.
			for (let i = 0; i < sectionTitles.length; i ++) {
				let title = sectionTitles[i];
				let sectionDOMArr = document.getElementsByClassName(title);
				if (sectionDOMArr.length !== 0) {
					const sectionDOM = sectionDOMArr[0];
					if (isDOMInViewport(sectionDOM, 'whole')) {
						inViewPortTitleArr.push([title, i]);
					}
				}
			}
			if (inViewPortTitleArr.length > 0) {
				setActivNavAndIndex(inViewPortTitleArr);
			}
		}
	}, [sectionTitles, setActivNavAndIndex, isDOMInViewport]);

	const navToSection = useCallback((
		title: string,
		e: MouseEvent<HTMLSpanElement> | null,
		noSmooth = false
	) => {
		let index = 0;
		for (let i = 0; i < sectionTitles.length; i ++) {
			if (title === sectionTitles[i]) {
				index = i;
				break;
			}
		}
		if (index === activeNavIndexRef.current) {
			return;
		}
		let diffIndex = index === 0 ? 1 : Math.abs(activeNavIndexRef.current - index);
		let scrollTime = 300 * diffIndex;
		scrollTimerRef.current = window.setTimeout(() => void setActiveNav(title), scrollTime);

		const targetDOM = window.document.getElementById(title);
		if (targetDOM !== null) {
			targetDOM.scrollIntoView({
				behavior: !noSmooth ? 'smooth' : 'auto',
				block: 'start',
				inline: 'nearest'
			});
		}
	}, [setActiveNav]);

	useEffect(() => {
		if (navCheckKey !== null) {
			const toSection = ssGet(navCheckKey);
			if (toSection) {
				ssRemove(navCheckKey);
				// Came from home case content, need to forward to case section.
				setTimeout(() => {
					// Delay for a while to ensure all contents are loaded and the height is right.
					navToSection(toSection, null, true);
				}, 50);
			}
		}
	}, [navCheckKey, navToSection]);

	useEffect(() => {
		if (scrollTimerRef.current !== null) {
			window.clearTimeout(scrollTimerRef.current);
		}
	}, []);

	return [activeNav, checkActiveNav, navToSection];
}
