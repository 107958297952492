const zhCN = {
	documentTitle: '云原生容器存储解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '云原生容器存储解决方案',
		subTitle: '容器云要求高可用、高可靠、容器粒度敏捷，数据持久、并且支持多应用同时访问数据，此外还要求存储系统更稳定，更快速，能够在私有云和公有云之间平滑跨越。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		lackOfFlexibility: {
			title: '存储设备种类多，硬件依赖度高，缺乏灵活性',
			desc: '存储设备种类繁多，各个产品服务接口不同，硬件依赖度高，运维复杂，灵活性比较低。',
		},
		lackOfSharing: {
			title: '业务调度调整频繁，资源缺乏透明共享',
			desc: '快速迭代和频繁发布，不同业务数据保存在不同的存储设备中，数据流动性差，造成资源大量浪费。',
		},
		noReliabilityGuarantee: {
			title: '采用开源软件做集群，存储可靠性难以保证',
			desc: '采用开源软件做集群，大规模商用时与硬件融合度不高，严重依赖人工开发运维，无法保证存储可靠性。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '云原生容器存储解决方案介绍',
			desc: '针对这些需求，奥卡云提出基于UniIO分布式全闪存的容器云解决方案，支持各种复杂的应用负载以及不同类型应用的存储访问需求，帮助用户从各种纷繁复杂的基础架构运维工作中解放出来，聚焦运行于业务本身的快速开发测试及发布。实现系统的自动化运维以及管理合规性，让企业数据中心从单纯的信息化工具逐渐转变成为更加灵活高效的业务应用平台，助力企业成长。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		highResourceUtilization: {
			title: '资源利用率高',
			desc: '统一建立资源池，为私有云数据中心获得弹性调度能力，提高存储资源利用率并简化管理。',
		},
		highSafety: {
			title: '高安全高可靠',
			desc: '多副本，提高数据安全性和可靠性。',
		},
		highPerformance: {
			title: '高性能低时延',
			desc: '采用分布式架构和全新SCM技术，确保易扩展的同时，提升存储性能、降低时延。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
