const zhCN = {
	documentTitle: '大数据解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '大数据解决方案',
		subTitle: '大数据解决方案是为大数据分析提供经济、高效、稳定的企业级存储服务。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		hardToExpansion: {
			title: '扩展难',
			desc: '数据存储方面，低成本可扩展问题',
		},
		hardToManage: {
			title: '管理困难',
			desc: '大规模小文件和大文件并存的情况下，难以进行高效存储访问管理。',
		},
		lowTransmissionEfficiency: {
			title: '数据传输效率低',
			desc: '保证在容错的同时，数据高效传输。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '大数据解决方案介绍',
			desc: 'OrcaFS支持云原生HDFS协议，无缝对接大数据平台，业务无须任何改造。计算和存储资源分离，用户可对存储集群进行弹性扩展，满足业务需求。优异的存储读写性能，使聚合带宽高达数10GB。适配通用硬件，软硬件松耦合，避免厂商绑定，灵活性大。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		easyToExpand: {
			title: '易扩展',
			desc: '全局统一命名空间和集群横向扩展能力，可轻松管理数百PB数据。',
		},
		lowCost: {
			title: '低成本',
			desc: '存储方案配置灵活，可对温热数据进行分层存储，降低存储成本。',
		},
		agilelyChoose: {
			title: '方案灵活',
			desc: '支持文件和对象不同类型大数据方案，供客户灵活选择。',
		},
		easyToManage: {
			title: '易管理',
			desc: '易于管理维护，无需中断业务即可轻松实现动态扩展。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
