import React, {FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useRef, useState} from 'react';
import {useAccessStatistics, useI18n, useInterval, useSelector} from '@hooks';
import {OOSModal} from '@components';
import {base64, validateMobilePhone, validateEmail, validatePassword, equals} from '@utils';
import httpCaller from '@http/caller';
import {handleFetchExceptionMsg} from '@http/fetch/httpHelper';
import {State} from '@redux/interfaces';
import routes from '@routes';

interface ResetPasswordProps {
	visible: boolean;
	hide: Function;
	showSignIn: Function;
}

interface SelectedState {
	windowWidth: number;
}

const ResetPassword: FC<ResetPasswordProps> = ({
	visible: pVisible,
	hide: pHide,
	showSignIn: pShowSignIn
}) => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('ResetPassword');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {windowWidth}} = state;
		return {windowWidth};
	}, []);

	const {windowWidth} = useSelector<State, SelectedState>(selectState, equals);

	// Internal states.
	const [loading, setLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [count, setCount] = useState(60);
	const [countingDown, setCountingDown] = useState(false);

	// Ref data.
	const phoneInputRef = useRef<HTMLInputElement | null>(null);
	const emailInputRef = useRef<HTMLInputElement | null>(null);
	const verifyCodeInputRef = useRef<HTMLInputElement | null>(null);
	const passwordInputRef = useRef<HTMLInputElement | null>(null);
	const rePasswordInputRef = useRef<HTMLInputElement | null>(null);

	// Page actions.
	useInterval(() => {
		setCount(prevCount => --prevCount);
	}, countingDown && (count > 0) ? 1000 : null);

	useEffect(() => {
		if (count === 0) {
			setCountingDown(false);
			setCount(60);
		}
	}, [count]);

	const onHideClick = useCallback(() => pHide(), [pHide]);

	const onSendVerifyCodeClick = useCallback(async () => {
		if (!countingDown) {
			// Trim input values.
			let phone = phoneInputRef.current ? phoneInputRef.current.value.trim() : '';
			let email = emailInputRef.current ? emailInputRef.current.value.trim() : '';

			// Check form validation.
			if (!validateMobilePhone(phone)) {
				return alert(i18n('validation.phoneIncorrect'));
			}
			if (!validateEmail(email)) {
				return alert(i18n('validation.emailIncorrect'));
			}

			try {
				await httpCaller.sendResetPasswordVerifyCode({phone, email});
				alert(i18n('sendVerifyCodeOk'));
				setCountingDown(true);
			} catch({msg}) {
				alert(i18n('sendVerifyCodeFails') + msg);
			}
		}
	}, [countingDown, i18n]);

	const onSubmitClick = useCallback(async (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLInputElement>) => {
		// Prevent the form default behavior.
		e.preventDefault();

		if (!loading) {
			// Trim input values.
			let phone = phoneInputRef.current ? phoneInputRef.current.value.trim() : '';
			let email = emailInputRef.current ? emailInputRef.current.value.trim() : '';
			let verifyCode = verifyCodeInputRef.current ? verifyCodeInputRef.current.value.trim() : '';
			let password = passwordInputRef.current ? passwordInputRef.current.value.trim() : '';
			let rePassword = rePasswordInputRef.current ? rePasswordInputRef.current.value.trim() : '';

			// Check form validation.
			if (!validateMobilePhone(phone)) {
				return alert(i18n('validation.phoneIncorrect'));
			}
			if (!validateEmail(email)) {
				return alert(i18n('validation.emailIncorrect'));
			}
			if (!verifyCode) {
				return alert(i18n('validation.verifyCodeIncorrect'));
			}
			if (validatePassword(password)) {
				return alert(i18n('validation.passwordIncorrect'));
			}
			if (validatePassword(rePassword)) {
				return alert(i18n('validation.rePasswordIncorrect'));
			}
			if (password !== rePassword) {
				return alert(i18n('validation.passwordReInputIncorrect'));
			}

			setLoading(true);

			let user = {
				phone,
				email,
				code: verifyCode,
				password: base64(password),
			};

			try {
				await httpCaller.resetPassword(user);
				setLoading(false);
				setShowSuccess(true);
			} catch (e) {
				setLoading(false);
				alert(i18n('actionFails') + handleFetchExceptionMsg(e));
			}
		}
	}, [loading, i18n]);

	const onInputKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key.toLowerCase() === 'enter') {
			onSubmitClick(e);
		}
	}, [onSubmitClick]);

	const onOkClick = useCallback(() => {
		if (!loading) {
			setShowSuccess(false);
			pHide();
			pShowSignIn();
		}
	}, [pHide, pShowSignIn, loading]);

	// Access Statistics.
	useAccessStatistics(routes.SignIn, pVisible);

	return pVisible ? (
		<OOSModal
			width={
				windowWidth > 450 ?
					(!showSuccess ? 490 : 340) :
					(!showSuccess ? 340 : 300)
			}
		>
			{
				!showSuccess ? (
					<>
						<div className="oos-modal-hide-button-box">
							<div className="oos-modal-hide-button" onClick={onHideClick} />
						</div>
						<div className="oos-modal-title">{i18n('title')}</div>
						{
							pVisible && (
								<form autoComplete="off">
									<div className="oos-modal-form-content">
										<div className="oos-modal-form-item">
											<div className="label-img phone" />
											<input type="text" ref={phoneInputRef} placeholder={i18n('phonePlh')} onKeyDown={onInputKeyDown} />
										</div>
										<div className="oos-modal-form-item send-verify-code">
											<div className="label-img email" />
											<input type="text" maxLength={50} ref={emailInputRef} placeholder={i18n('emailPlh')} />
										</div>
										<div
											className={`send-verify-code-button ${!countingDown ? '' : 'disabled'}`}
											onClick={onSendVerifyCodeClick}
										>
											{!countingDown ? i18n('sendVerifyCode') : count}
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img resume" />
											<input type="text" ref={verifyCodeInputRef} placeholder={i18n('verifyCodePlh')} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img password" />
											<input type="password" ref={passwordInputRef} autoComplete="new-password" placeholder={i18n('passwordPlh')} onKeyDown={onInputKeyDown} />
										</div>
										<div className="oos-modal-form-item">
											<div className="label-img password" />
											<input type="password" ref={rePasswordInputRef} autoComplete="new-password" placeholder={i18n('rePasswordPlh')} onKeyDown={onInputKeyDown} />
										</div>
										<div
											className={`submit-button ${loading ? 'disabled' : ''}`}
											onClick={onSubmitClick}
										>
											{!loading ? i18nGen('ok') : i18n('signingIn')}
										</div>
									</div>
								</form>
							)
						}
					</>
				) :
				(
					<div className="oos-modal-success-tips-box">
						<div className="success-img" />
						<div className="success-title">{i18n('actionOk')}</div>
						<div className="success-desc">{i18n('actionOkTips')}</div>
						<div className="success-ok-button" onClick={onOkClick}>{i18nGen('ok')}</div>
					</div>
				)
			}
		</OOSModal>
	) :
	null;
}

export default ResetPassword;
