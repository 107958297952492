const zhCN = {
	documentTitle: '安全生产应急监管平台解决方案  奥卡云数据 - 存储 物联网 解决方',
	banner: {
		title: '安全生产应急监管平台解决方案',
		subTitle: '安全生产应急监管平台是各级安监部门有效履行安全生产应急管理和救援职能的必要手段，能够让各级安监部门科学应对各类安全生产事故。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		noPreciseQuery: {
			title: '安全隐患无法精确查询',
			desc: '安全监管相关单位制定年度执法计划时，企业名单往往是根据抽查比例而随机产生的，这样的做法虽说可以达到一定的检查覆盖率，但还缺乏执法的针对性和精准性，一些真正存在隐患问题的企业往往容易成为“漏网之鱼”。',
		},
		limitedLawEnforcement: {
			title: '执法力量有限',
			desc: '因基层监管力量和队伍的限制，以往的安全生产监管，更多聚集于风险行业、重点企业和接报的事故，更多的集中在“事后”型执法，导致执法的效率和精准化程度不高。',
		},
		insufficientSupervision: {
			title: '监管力度不足，无法及时掌控',
			desc: '在监管方面，之前通过上门执法，仅检查现场隐患和台帐，检查后，对企业日常的动态隐患（如动火作业、人数管控、人车资质不符等）无法及时掌握和制止。',
		},
		lowResponsibility: {
			title: '企业责任落实不到位',
			desc: '企业主体责任的落实情况，落实程度，难以实时、全面掌握。即使能够掌握，也只是对个别企业掌握，无法掌握面上的情况。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '安全生产应急监管平解决方案介绍',
			desc1: '安全生产应急监管平台是各级安监部门有效履行安全生产应急管理和救援职能的必要手段，能够让各级安监部门科学应对各类安全生产事故。平台能够充分整合利用现有的网络资源、通讯资源、硬件资源、系统及数据资源，纵向构建上下互联互通的安全生产应急平台体系，横向构建与政府“综合应急平台”及其他部门“专项应急平台”的联动体系，真正实现“横向到边、纵向到底”的安全生产应急平台体系构建，为提升安全生产应急管理能力和有效应对各类安全生产事故救援能力提供领先的安全科技支撑。',
			desc2: '应急指挥调度系统集成语音、视频、数据调度功能，实现跨单位、跨部门之间的统一指挥协调，快速反应、统一应急、有效应对突发性安全事件。能够满足国家级、省级、市级、县级化工（工业）企业应急响应中心平台建设的需要。实现“平时”应急管理工作（实现安监局指挥中心对下属企业、单位的语音、视频、指令的通信调度），满足“战时”应急救援工作的需要。',
			desc3: '本平台作为物联网项目的典型应用，整体系统架构的设计思路是基于从数据的感知、传输处理、应用和展示着三个层次结构对系统进行设计。数据感知依靠前端的企业端数据采集软件实现对企业不同类型的传感器数据进行采集，然后采集的数据在物联网网关中进行初步的处理，然后再通过物联网关加密压缩后传输到中心数据库。在中心通过在GIS地图上或者其他功能对接入的数据进行处理和展示。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		comprehensiveSupervision: {
			title: '全方位监管',
			desc: '充分发掘企业安全生产过程中的风险点，防止安全事故隐患，形成事前预防，事中监测预警，事后救援的全方位监管模式。',
		},
		visualMonitoring: {
			title: '可视化监测',
			desc: '基于GIS地图平台网格化管理，可视化展示重大危险源监测监控情况及周边信息，直观全面、动态了解企业实时情况，实现救援指挥可视化，直观全面展现事故地点及周边情况，全面展示应急救援全过程管控。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		zhengZhouEmergencyDepartment: {
			title: '郑州市应急管理局安全生产企业综合监管平台',
			introduction: '郑州市应急管理局安全生产科通过安全生产企业综合监管平台项目的建设，建设内容包括安全生产感知数据汇聚 、安全生产风险综合监测、安全生产风险智能评估、安全生产风险精准预警和安全生产风险趋势预测等功能。通过快速有效地研判企业、行业、地区风险态势 ，为安全生产精准监管、靶向执法提供决策支撑，同时倒逼企业落实安全生产主体责任。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
