import React, {FC, useCallback, useEffect, useMemo, useRef} from 'react';
import {createPortal} from 'react-dom';
import generalAction from '@actions/generalAction';
import {useDispatch, useI18n, useSelector, useLocation, useNavigate, useSolutionMenuCategoryMap} from '@hooks';
import {equals} from '@utils';
import {State} from '@redux/interfaces';

interface SolutionDropDownMenuProps {
	visible: boolean;
}

interface SelectedState {
	activeSolutionCategory: 'storage' | 'iot';
	activeSolutionSecondaryCategory: string;
}

const SolutionDropDownMenu: FC<SolutionDropDownMenuProps> = ({
	visible: pVisible,
},) => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('TopBar');

	// Router.
	const location = useLocation();
	const navigate = useNavigate();

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {activeSolutionCategory, activeSolutionSecondaryCategory}} = state;
		return {activeSolutionCategory, activeSolutionSecondaryCategory };
	}, []);

	const {activeSolutionCategory, activeSolutionSecondaryCategory} = useSelector<State, SelectedState>(selectState, equals);

	const dispatch = useDispatch();

	const setActiveSolutionCategory = useCallback((activeSolutionCategory: string) => dispatch(generalAction.setActiveSolutionCategory(activeSolutionCategory)), [dispatch]);

	const setActiveSolutionSecondaryCategory = useCallback((activeSolutionSecondaryCategory: string) => dispatch(generalAction.setActiveSolutionSecondaryCategory(activeSolutionSecondaryCategory)), [dispatch]);

	const setActiveSubmenu = useCallback((activeSubmenu: string) => dispatch(generalAction.setActiveSubmenu(activeSubmenu)), [dispatch]);

	// Ref data.
	const topBarDOMRef = useRef<HTMLElement | null>(null);

	// Page actions.
	const categoryMenuMapMemo = useSolutionMenuCategoryMap();

	const onCategoryMouseOver = useCallback((category: 'storage' | 'iot') => {
		setActiveSolutionCategory(category);
		const categoryMenuArr = categoryMenuMapMemo[category];
		const firstSecondaryMenu = Object.keys(categoryMenuArr)[0];
		setActiveSolutionSecondaryCategory(firstSecondaryMenu);
	}, [setActiveSolutionCategory, setActiveSolutionSecondaryCategory, categoryMenuMapMemo]);

	const onSecondaryCategoryMouseOver = useCallback((secondaryCategory: string) => {
		setActiveSolutionSecondaryCategory(secondaryCategory);
	}, [setActiveSolutionSecondaryCategory]);

	const onSolutionMenuClick = useCallback((path: string) => {
		if (location.pathname !== path) {
			navigate(path);
			setActiveSubmenu('');
		}
	}, [location, navigate, setActiveSubmenu]);

	// Memorized data.
	const secondaryCategoryMenuMemo = useMemo(() => {
		const secondaryCategoryMenuArr = categoryMenuMapMemo[activeSolutionCategory];
		return Object.keys(secondaryCategoryMenuArr)
			.map((secondaryCategoryMenu) => (
				<div
					className={`secondary-category-menu-item ${activeSolutionSecondaryCategory === secondaryCategoryMenu ? 'active' : ''}`}
					key={`${activeSolutionCategory}-${secondaryCategoryMenu}`}
					onMouseOver={onSecondaryCategoryMouseOver.bind(null, secondaryCategoryMenu)}
				>
					<span className="menu-text">{i18n(`solutionSecondaryCategory.${secondaryCategoryMenu}`)}</span>
				</div>
			));
	}, [activeSolutionCategory, activeSolutionSecondaryCategory, categoryMenuMapMemo, onSecondaryCategoryMouseOver, i18n]);

	const secondaryCategoryContentMemo = useMemo(() => {
		const categoryMenuArr = categoryMenuMapMemo[activeSolutionCategory];
		const secondaryCategoryMenuArr = categoryMenuArr[activeSolutionSecondaryCategory];
		return secondaryCategoryMenuArr.map((solutionMenu) => (
			<div
				className="solution-section-item"
				key={solutionMenu.path}
				onClick={onSolutionMenuClick.bind(null, solutionMenu.path)}
			>
				<div className="title">{solutionMenu.title}</div>
				<div className="sub-title">{solutionMenu.subTitle}</div>
				<div className="view-detail-button">
					{i18nGen('viewDetail')}
					<i className="oos-button-link-icon">→</i>
				</div>
				<i className="big-arrow" />
				<i className={`bg ${solutionMenu.imgCls}`} />
			</div>
		));
	}, [activeSolutionCategory, activeSolutionSecondaryCategory, categoryMenuMapMemo, onSolutionMenuClick, i18nGen]);

	// After rendering and re-rendering.
	useEffect(() => {
		// Get portal container DOM.
		topBarDOMRef.current = document.getElementById('oos-top-bar');

		return () => {
			topBarDOMRef.current = null;
		};
	}, []);

	return topBarDOMRef.current ?
		createPortal((
			<div className={`solution-drop-down-menu ${pVisible ? 'active' : ''}`}>
				<div className="category-menu-box">
					<div
						className={`category-menu-item ${activeSolutionCategory === 'storage' ? 'active' : ''}`}
						onMouseOver={onCategoryMouseOver.bind(null, 'storage')}
					>
						<span className="menu-text">{i18n('solutionCategory.storage')}</span>
					</div>
					<div
						className={`category-menu-item ${activeSolutionCategory === 'iot' ? 'active' : ''}`}
						onMouseOver={onCategoryMouseOver.bind(null, 'iot')}
					>
						<span className="menu-text">{i18n('solutionCategory.iot')}</span>
					</div>
				</div>
				<div className="category-content">
					<div className="secondary-category-menu-box">{secondaryCategoryMenuMemo}</div>
					<div className="secondary-category-content-box">{secondaryCategoryContentMemo}</div>
				</div>
			</div>
		), topBarDOMRef.current) :
		null;
};

export default SolutionDropDownMenu
