import store from '@store';
import {logger} from '@utils';
import {ActionCreatorSet} from '@redux/interfaces';
import {HTTPWorkerError} from '@http/interface/fetch';
import {Caller} from '@http/caller';

// Consider to the point of 'this' in decorator, we must be cautious
// of the usage of normal function and arrow function.

// To decorate a class, then:
// 'target' refers to the class,
// 'key' refers to undefined,
// 'descriptor' refers to undefined.

// To decorate a member method of a class will like this:
// 'target' refers to the class itself,
// 'key' refers to the member property name,
// 'descriptor' refers to the member property method.

// Throttle a method of a class.
export function throttleDecr (time: number){
	// Decorate a member method of a class.
	const instanceMap = new Map();
	return function (
		target: Function,
		key: PropertyKey,
		descriptor: PropertyDescriptor
	){
		return Object.assign({}, descriptor, {
			value: function (...args: any[]){
				// If timer already existed, skip this function call.
				if (instanceMap.get(this)) {
					instanceMap.set(this, false);
					window.setTimeout(() => {
						descriptor.value.apply(this, args);
						instanceMap.set(this, true);
					}, time);
				}
			}
		});
	};
}

// Debounce a method of a class.
export function debounceDecr (time = 500){
	// Decorate a member method of a class.
	// We need the parameter time, so use a currying here.
	// this => undefined
	const instanceMap = new Map();
	return function (
		target: Function,
		key: PropertyKey,
		descriptor: PropertyDescriptor
	){
		return Object.assign({}, descriptor, {
			value: function (...args: any[]){
				// target => class extends from React origin Component
				// this => custom React component instance
				window.clearTimeout(instanceMap.get(this));
				// Some times we need to be waiting for the end of this call, so we need
				// to return a promise here to make this decorator asynchronous.
				return new Promise(resolve => {
					instanceMap.set(this, setTimeout(async () => {
						// Correct the context to custom React component instance.
						await descriptor.value.apply(this, args);
						instanceMap.delete(this);
						resolve(9);
					}, time));
				});
			}
		});
	};
}

// Catch fetch error decorator.
// For HTTP API caller using.
export function catchCallerErrorDecr (
	target: Caller,
	key: PropertyKey,
	descriptor: PropertyDescriptor
){
	// This decorator is used for HTTP APIs which is aimed at getting data only (GET Method),
	// especially getting data in background.
	// The other APIs which are used by CRUD operations will catch error by the action methods
	// themselves in specific business code segments in view components.
	return Object.assign({}, descriptor, {
		value: async function (...arg: any[]){
			try {
				return await descriptor.value.apply(this, arg);
			} catch (e) {
				if (process.env.NODE_ENV !== 'test') {
					// The e may come from raw HTTP error, or error in 'HttpWorkerBridge.js' and 'httpInMainThread.js'.
					logger.warn('HTTP Error[catchAPIError]: ' + ((e as HTTPWorkerError).msg || (e as HTTPWorkerError).url || e));
				} else {
					// Test env.
					if (!descriptor.value.toString().match('test_reject')) {
						logger.info(descriptor.value);
					}
				}
			}
		}
	});
}

// Receive latest data decorator.
// For HTTP API caller using.
export function receiveLatestDataDecr (
	target: Caller,
	key: PropertyKey,
	descriptor: PropertyDescriptor
){
	return Object.assign({}, descriptor, {
		value: function (
			this: any,
			...arg: any[]
		){
			const term = Date.now();
			this[key].newestTerm = term;
			const callback = async () => {
				let data = descriptor.value.apply(this, arg);
				if (callback.myTerm === this[key].newestTerm) {
					// We only take the data that is returned from the function
					// with the newestTerm.
					// If we setup 3 requests by the sequence: a, b, c.
					// But the response sequence is: a, c, b.
					// Then we will still take c's data, not the b's.
					return data;
				}
				// When we return undefined, the decorator 'dispatchActionDecr' below
				// will not go through redux process to avoid re-rendering.
				return (void 0);
			};
			callback.myTerm = term;
			return callback();
		}
	});
}

// Dispatch redux action decorator.
// For HTTP API caller using.
export function dispatchActionDecr (
	actionModule: ActionCreatorSet,
	action: string,
	// dataKeys: string[]
){
	return function (
		target: Caller,
		key: PropertyKey,
		descriptor: PropertyDescriptor
	){
		return Object.assign({}, descriptor, {
			value: async function (...arg: any[]){
				let data = await descriptor.value.apply(this, arg);
				// ***
				// When the data is "undefined", it means do not do any action in redux, the
				// concept works in the whole state management progress, do keep it in mind.
				// ***
				if (data !== (void 0) && data !== null) {
					store.dispatch(actionModule[action](data));
					/*
					// You can pass a action array.
					let rawData = Object.assign({}, data);
					// actionModule, action, dataKey
					let dispatchData = {};
					if (Array.isArray(dataKeys)) {
						dataKeys.forEach(dataKey => dispatchData[dataKey] = data[dataKey]);
					} else {
						dispatchData = data;
					}
					store.dispatch(actionModule[action](dispatchData));
					return rawData;
					*/
				}
				return data;
			}
		});
	};
}
