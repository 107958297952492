import {ActionCreatorSet} from '../interfaces';

export const newsActionTypes = {
	SET_FIRM_NEWS_LIST: '@NEWS_SET_FIRM_NEWS_LIST',
	SET_FIRM_NEWS_DETAIL: '@NEWS_SET_FIRM_NEWS_DETAIL',
};

const newsAction: ActionCreatorSet = {
	setFirmNewsList: firmNewsList => ({
		type: newsActionTypes.SET_FIRM_NEWS_LIST,
		firmNewsList
	}),

	setFirmNewsDetail: firmNewsDetail => ({
		type: newsActionTypes.SET_FIRM_NEWS_DETAIL,
		firmNewsDetail
	}),
};

export default newsAction;
