const zhCN = {
	documentTitle: '联系我们 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '联系我们',
		subTitle: '业务咨询、合作洽谈、产品试用，请联系我们',
	},
	contact: {
		title: '联系方式',
		subTitle: '电话号码、邮箱地址',
		phone: '联系电话',
		email: '邮箱地址',
	},
	address: {
		title: '公司地址',
		subTitle: '总部、研发中心与办事处地址',
		serviceHotLine: '服务热线：',
		TechnicalSupportHotLine: '技术支持：',
		postalCode: '邮政编码：',
		location: '地址：',
		xianHeadquarters: '奥卡云数据西安总部',
		xianHeadquartersLocation: '陕西省咸阳市渭城区窑店街道秦汉文创大厦12F',
		chengduRDCenter: '奥卡云数据成都研发中心',
		chengduRDCenterLocation: '四川省成都市高新区天府大道中段199号棕榈泉国际中心10F',
		bostonRDCenter: '奥卡云数据波士顿研发中心',
		bostonRDCenterLocation: '美国马萨诸塞州波士顿',
		changshaOffice: '奥卡云数据长沙办事处',
		changshaOfficeLocation: '湖南省长沙市开福区科大景园7栋302',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
