import {initSearchUrl, initUrl, makeFetchReqInit} from './httpHelper';
import httpInMainThread from './httpInMainThread';
import {NORMAL_TIMEOUT_TIME, ULTRA_TIMEOUT_TIME} from '@utils';
import {HttpMethod, HttpREST as HttpRESTType, HttpRESTConstructor, TaskExtraArg, ReqPromise, FetchOption} from '../interface/fetch';

const sendRequest = (
    url: string,
    reqInit: RequestInit,
    extraArg: TaskExtraArg,
) => new Promise(async (resolve, reject) => {
    const fetchOption: FetchOption = {
        url,
        reqInit: makeFetchReqInit(reqInit)
    };

    const reqPromise: ReqPromise = {resolve, reject};

	httpInMainThread(fetchOption, reqPromise, extraArg);
});

class HttpREST implements HttpRESTType {
    url = '';
    method: HttpMethod;
    reqInit: RequestInit;
    extraArg: TaskExtraArg = {};

    constructor (method: HttpMethod){
        this.method = method;
        this.reqInit = {method};
    }

    init (...conf: any[]){
        switch (this.method) {
            case 'GET':
                // conf: [url, target, param]
                this.url = initSearchUrl(initUrl(conf[0], conf[1]), conf[2]);
                break;

            case 'POST':
                // conf: [url, param]
                this.url = initUrl(conf[0]);
                Object.assign(this.reqInit, {body: JSON.stringify(conf[1])});
                break;

            case 'PUT':
                // conf: [url, target, action, param]
                this.url = initUrl(conf[0], conf[1], conf[2]);
                Object.assign(this.reqInit, {body: JSON.stringify(conf[3])});
                break;

            case 'DELETE':
                // conf: [url, target]
                this.url = initUrl(conf[0], conf[1]);
                break;
        }
        return this;
    }

    preprocess (
        processor: string,
        preArg?: any
    ){
        // Specify a data preprocess to process the raw data.
        this.extraArg = Object.assign(this.extraArg, {
			processor,
			preArg,

		});
        return this;
    }

    equal (dataMapKey: string){
        // Specify a data map key for data comparison in HTTP worker thread.
        // Note: this will only work in HTTP worker thread. Even if you have explicitly configured it,
        // it will not work in the way of fetch in main thread, because it will cost a lot of time that
        // will hung the main thread and cause the performance get worse.
        // ***
        // And even two calls' http api urls are the same one, but if their final redux state are different,
        // we should set two different dataMapKeys for LFUMap. If not if one call A is first done, the LFUMap
        // will set its value depend on the dataMapKey, and another call B done later without any data change,
        // then checking the dataMapKey, LFUMap will find it is equal to the previous one, so there is no
        // redux state mutation will happen, the B call's state will keep the previous one, that's not what
        // we expect. So one dataMapKey in LFUMap should always be related to one redux state, not one to many.
        // ***
        this.extraArg = Object.assign(this.extraArg, {dataMapKey});
        return this;
    }

    timeout (conf: {time: number, text?: string}){
        this.extraArg = Object.assign(this.extraArg, {timeout: conf});
        return this;
    }

    if (
        flag: boolean,
        func: (h: HttpRESTType) => HttpRESTType
    ){
        if (flag) {
            func(this);
        }
        return this;
    }

	asStream (){
		this.extraArg = Object.assign(this.extraArg, {stream: true});
		return this;
	}

    reset (){
        this.url = '';
        this.reqInit = {method: this.method};
        this.extraArg = {};
    }

    fixConf (){
        // Apply default timeout if it is not specified.
        if (!this.extraArg.timeout) {
            let timeoutConf;
            if (this.method === 'GET') {
                timeoutConf = {
					time: NORMAL_TIMEOUT_TIME,
				};
            } else {
                timeoutConf = {
					time: ULTRA_TIMEOUT_TIME,
				};
            }
            this.timeout(timeoutConf);
        }
    }

    send (): Promise<any> | null {
        this.fixConf();
        const dataPromise = sendRequest(this.url, this.reqInit, this.extraArg);
        this.reset();
        return dataPromise;
    }
}

const createHttpRest = (HttpREST: HttpRESTConstructor, method: HttpMethod) => new HttpREST(method);

export const httpGet = createHttpRest(HttpREST, 'GET');

export const httpPost = createHttpRest(HttpREST, 'POST');

export const httpPut = createHttpRest(HttpREST, 'PUT');

export const httpDelete = createHttpRest(HttpREST, 'DELETE');
