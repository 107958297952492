export function shallowEqual (
	objA: unknown,
	objB: unknown
){
	if (Object.is(objA, objB)) {
		return true;
	}

	if (typeof objA !== 'object' || !objA || typeof objB !== 'object' || !objB) {
		return false;
	}

	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);

	// The two data's key number is not the same.
	if (keysA.length !== keysB.length) {
		return false;
	}

	// Then we can assert objA and objB is typeof plain object.
	let objAImp: Record<string, unknown> = {...objA};
	let objBImp: Record<string, unknown> = {...objB};

	// Check if A's keys are different from B.
	let i = 0;
	let len = keysA.length;
	for (; i < len; i ++) {
		let key = keysA[i];

		if (!Object.prototype.hasOwnProperty.call(objB, key)) {
			return false;
		}

		if (!Object.is(objAImp[key], objBImp[key])) {
			return false;
		}
	}

	return true;
}

// console.log(shallowEqual({a: 1, x: {d: 1}, f: [{x: 1}, {d: 1}]}, {a: 1, x: {d: 1}, f: [{x: 1}, {d: 1}]}));
// console.log(shallowEqual({a: 1,}, {a: 1,}));
