import React, {FC, useCallback, useMemo, useState} from 'react';
import {useI18n, useSelector, useNavigate} from '@hooks';
import {OOSDynamicIcon} from '@components';
import {equals} from '@utils';
import {DeviceMode} from '@interfaces';
import {State} from '@redux/interfaces';
import routes from '@routes';

interface SelectedState {
	deviceMode: DeviceMode;
}

const Product: FC = () => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('Home', 'product');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {deviceMode}} = state;
		return {deviceMode};
	}, []);

	const {deviceMode} = useSelector<State, SelectedState>(selectState, equals);

	// Router.
	const navigate = useNavigate();

	// Internal states.
	const [activeIndex, setActiveIndex] = useState('uniio');
	const [activeFeatureIcon, setActiveFeatureIcon] = useState<string | null>(null);

	// Page actions.
	const onTabChange = useCallback((activeIndex: string) => setActiveIndex(activeIndex), []);

	const onSectionChange = useCallback((newActiveIndex: string) => {
		if (newActiveIndex === activeIndex) {
			newActiveIndex = '';
		}
		setActiveIndex(newActiveIndex);
	}, [activeIndex]);

	const onViewDetailClick = useCallback((path: string) => navigate(path), [navigate]);

	const onFeatureMouseEnter = useCallback((activeFeature: string) => setActiveFeatureIcon(activeFeature), []);

	const onFeatureMouseLeave = useCallback(() => setActiveFeatureIcon(null), []);

	// Memorized descendant components.
	const ProductPCAndPadMemo = useMemo(() => (
		<div className="product">
			<div className="product-title-tab-wrap">
				<div className="product-title-tab">
					<div
						className={`product-name-tab-item ${activeIndex === 'uniio' ? ' active' : ''}`}
						onClick={onTabChange.bind(null, 'uniio')}
					>
						<div className="title-text">{i18n('uniio.name')}</div>
					</div>
					<div
						className={`product-name-tab-item ${activeIndex === 'orcafs' ? ' active' : ''}`}
						onClick={onTabChange.bind(null, 'orcafs')}
					>
						<div className="title-text">{i18n('orcafs.name')}</div>
					</div>
					<div
						className={`product-name-tab-item ${activeIndex === 'orcabd' ? ' active' : ''}`}
						onClick={onTabChange.bind(null, 'orcabd')}
					>
						<div className="title-text">{i18n('orcabd.name')}</div>
					</div>
				</div>
			</div>
			<div className="product-content-wrap">
				<div className="product-content-box">
					<div className={`product-content ${activeIndex === 'uniio' ? ' active' : ''}`}>
						<div className="overview-content">
							<div className="name">{i18n('uniio.name')}</div>
							<div className="desc">{i18n('uniio.desc')}</div>
							<div className="scenario-box">
								<div className="scenario-item">{i18n('uniio.scenario.virtualization')}</div>
								<div className="scenario-item">{i18n('uniio.scenario.containerCloud')}</div>
								<div className="scenario-item">{i18n('uniio.scenario.cloudComputing')}</div>
								<div className="scenario-item">{i18n('uniio.scenario.privateCloud')}</div>
								<div className="scenario-item">{i18n('uniio.scenario.fiveG')}</div>
								<div className="scenario-item">{i18n('uniio.scenario.database')}</div>
							</div>
							<div
								className="view-detail-button"
								onClick={onViewDetailClick.bind(null, routes.UniIO)}
							>
								{i18nGen('viewDetail')}
								<i className="oos-button-link-icon">→</i>
							</div>
						</div>
						<div className="feature-box">
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'deduplication')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="deduplication" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('uniio.feature.deduplication.name')}</div>
									<div className="desc">{i18n('uniio.feature.deduplication.desc')}</div>
								</div>
							</div>
							{/*
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'qos')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="qos" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('uniio.feature.qos.name')}</div>
									<div className="desc">{i18n('uniio.feature.qos.desc')}</div>
								</div>
							</div>
							*/}
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'scale')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="scale" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('uniio.feature.scale.name')}</div>
									<div className="desc">{i18n('uniio.feature.scale.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'remote-replication')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="remote-replication" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('uniio.feature.remoteReplication.name')}</div>
									<div className="desc">{i18n('uniio.feature.remoteReplication.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'performance-uniio')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="performance-uniio" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('uniio.feature.performance.name')}</div>
									<div className="desc">{i18n('uniio.feature.performance.desc')}</div>
								</div>
							</div>
							{/*
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'low-carbon')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="low-carbon" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('uniio.feature.lowCarbon.name')}</div>
									<div className="desc">{i18n('uniio.feature.lowCarbon.desc')}</div>
								</div>
							</div>
							*/}
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'localization')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="localization" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('uniio.feature.localization.name')}</div>
									<div className="desc">{i18n('uniio.feature.localization.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'cloud')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="cloud" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('uniio.feature.cloud.name')}</div>
									<div className="desc">{i18n('uniio.feature.cloud.desc')}</div>
								</div>
							</div>
						</div>
					</div>
					<div className={`product-content ${activeIndex === 'orcafs' ? ' active' : ''}`}>
						<div className="overview-content">
							<div className="name">{i18n('orcafs.name')}</div>
							<div className="desc">{i18n('orcafs.desc')}</div>
							<div className="scenario-box">
								<div className="scenario-item">{i18n('orcafs.scenario.geneSequencing')}</div>
								<div className="scenario-item">{i18n('orcafs.scenario.materialScience')}</div>
								<div className="scenario-item">{i18n('orcafs.scenario.weatherForecasting')}</div>
								<div className="scenario-item">{i18n('orcafs.scenario.energyExploration')}</div>
								<div className="scenario-item">{i18n('orcafs.scenario.remoteSensingSatellite')}</div>
								<div className="scenario-item">{i18n('orcafs.scenario.ai')}</div>
								<div className="scenario-item">{i18n('orcafs.scenario.machineLearning')}</div>
								<div className="scenario-item">{i18n('orcafs.scenario.dataAnalysis')}</div>
							</div>
							<div
								className="view-detail-button"
								onClick={onViewDetailClick.bind(null, routes.OrcaFS)}
							>
								{i18nGen('viewDetail')}
								<i className="oos-button-link-icon">→</i>
							</div>
						</div>
						<div className="feature-box">
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'performance-orcafs')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="performance-orcafs" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcafs.feature.performance.name')}</div>
									<div className="desc">{i18n('orcafs.feature.performance.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'protocol')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="protocol" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcafs.feature.protocol.name')}</div>
									<div className="desc">{i18n('orcafs.feature.protocol.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'verification')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="verification" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcafs.feature.verification.name')}</div>
									<div className="desc">{i18n('orcafs.feature.verification.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'orcaond')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="orcaond" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcafs.feature.orcaond.name')}</div>
									<div className="desc">{i18n('orcafs.feature.orcaond.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'capacity')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="capacity" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcafs.feature.capacity.name')}</div>
									<div className="desc">{i18n('orcafs.feature.capacity.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'high-reliability-orcafs')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="high-reliability-orcafs" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcafs.feature.highReliability.name')}</div>
									<div className="desc">{i18n('orcafs.feature.highReliability.desc')}</div>
								</div>
							</div>
						</div>
					</div>
					<div className={`product-content ${activeIndex === 'orcabd' ? ' active' : ''}`}>
						<div className="overview-content">
							<div className="name">{i18n('orcabd.name')}</div>
							<div className="desc">{i18n('orcabd.desc')}</div>
							<div className="scenario-box">
								<div className="scenario-item">{i18n('orcabd.scenario.videoMonitor')}</div>
								<div className="scenario-item">{i18n('orcabd.scenario.hybridCloudStorage')}</div>
								<div className="scenario-item">{i18n('orcabd.scenario.dataArchiving')}</div>
								<div className="scenario-item">{i18n('orcabd.scenario.disasterRecoveryBackup')}</div>
							</div>
							<div
								className="view-detail-button"
								onClick={onViewDetailClick.bind(null, routes.OrcaBD)}
							>
								{i18nGen('viewDetail')}
								<i className="oos-button-link-icon">→</i>
							</div>
						</div>
						<div className="feature-box">
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'fusion-architecture')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="fusion-architecture" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcabd.feature.fusionArchitecture.name')}</div>
									<div className="desc">{i18n('orcabd.feature.fusionArchitecture.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'intelligent-layering')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="intelligent-layering" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcabd.feature.intelligentLayering.name')}</div>
									<div className="desc">{i18n('orcabd.feature.intelligentLayering.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'low-cost')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="low-cost" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcabd.feature.lowCost.name')}</div>
									<div className="desc">{i18n('orcabd.feature.lowCost.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'easy-to-expand-orcabd')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="easy-to-expand-orcabd" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcabd.feature.easyToExpand.name')}</div>
									<div className="desc">{i18n('orcabd.feature.easyToExpand.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'high-reliability-orcabd')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="high-reliability-orcabd" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcabd.feature.highReliability.name')}</div>
									<div className="desc">{i18n('orcabd.feature.highReliability.desc')}</div>
								</div>
							</div>
							<div
								className="feature"
								onMouseEnter={onFeatureMouseEnter.bind(null, 'easy-to-use-orcabd')}
								onMouseLeave={onFeatureMouseLeave}
							>
								<OOSDynamicIcon className="feature-icon" icon="easy-to-use-orcabd" activeIcon={activeFeatureIcon} />
								<div className="feature-text-content">
									<div className="title">{i18n('orcabd.feature.easyToUse.name')}</div>
									<div className="desc">{i18n('orcabd.feature.easyToUse.desc')}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="product-content-active-bg bg" />
				<div className="product-content-active-bg main-bg" />
			</div>
		</div>
	), [activeIndex, activeFeatureIcon, onTabChange, onFeatureMouseEnter, onFeatureMouseLeave, onViewDetailClick, i18nGen, i18n]);

	const ProductPhoneMemo = useMemo(() => (
		<div className="product-ph">
			<div className="product-content-wrap-ph">
				<div
					className={`product-name-ph ${activeIndex === 'uniio' ? 'active' : ''}`}
					onClick={onSectionChange.bind(null, 'uniio')}
				>
					<span className="name-ph">{i18n('uniio.name')}</span>
					<i className="arrow-ph" />
				</div>
				<div className={`product-content-ph ${activeIndex === 'uniio' ? 'active' : ''}`}>
					<div className="overview-content-ph">
						<div className="desc-ph">{i18n('uniio.desc')}</div>
						<div className="scenario-box-ph">
							<div className="scenario-item-ph">{i18n('uniio.scenario.virtualization')}</div>
							<div className="scenario-item-ph">{i18n('uniio.scenario.containerCloud')}</div>
							<div className="scenario-item-ph">{i18n('uniio.scenario.cloudComputing')}</div>
							<div className="scenario-item-ph">{i18n('uniio.scenario.fiveG')}</div>
							<div className="scenario-item-ph">{i18n('uniio.scenario.privateCloud')}</div>
							<div className="scenario-item-ph">{i18n('uniio.scenario.database')}</div>
						</div>
					</div>
					<div className="feature-box-ph">
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="deduplication" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('uniio.feature.deduplication.name')}</div>
								<div className="desc-ph">{i18n('uniio.feature.deduplication.desc')}</div>
							</div>
						</div>
						{/*
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="qos" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('uniio.feature.qos.name')}</div>
								<div className="desc-ph">{i18n('uniio.feature.qos.desc')}</div>
							</div>
						</div>
						*/}
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="scale" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('uniio.feature.scale.name')}</div>
								<div className="desc-ph">{i18n('uniio.feature.scale.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="remote-replication" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('uniio.feature.remoteReplication.name')}</div>
								<div className="desc-ph">{i18n('uniio.feature.remoteReplication.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="performance-uniio" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('uniio.feature.performance.name')}</div>
								<div className="desc-ph">{i18n('uniio.feature.performance.desc')}</div>
							</div>
						</div>
						{/*
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="low-carbon" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('uniio.feature.lowCarbon.name')}</div>
								<div className="desc-ph">{i18n('uniio.feature.lowCarbon.desc')}</div>
							</div>
						</div>
						*/}
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="localization" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('uniio.feature.localization.name')}</div>
								<div className="desc-ph">{i18n('uniio.feature.localization.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="cloud" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('uniio.feature.cloud.name')}</div>
								<div className="desc-ph">{i18n('uniio.feature.cloud.desc')}</div>
							</div>
						</div>
					</div>
					<div className="button-box-ph">
						<div
							className="view-detail-button-ph"
							onClick={onViewDetailClick.bind(null, routes.UniIO)}
						>
							{i18nGen('viewDetail')}
							<i className="oos-button-link-icon ph">→</i>
						</div>
					</div>
				</div>
			</div>
			<div className="product-content-wrap-ph">
				<div
					className={`product-name-ph ${activeIndex === 'orcafs' ? 'active' : ''}`}
					onClick={onSectionChange.bind(null, 'orcafs')}
				>
					<span className="name-ph">{i18n('orcafs.name')}</span>
					<i className="arrow-ph" />
				</div>
				<div className={`product-content-ph ${activeIndex === 'orcafs' ? 'active' : ''}`}>
					<div className="overview-content-ph">
						<div className="desc-ph">{i18n('orcafs.desc')}</div>
						<div className="scenario-box-ph">
							<div className="scenario-item-ph">{i18n('orcafs.scenario.geneSequencing')}</div>
							<div className="scenario-item-ph">{i18n('orcafs.scenario.materialScience')}</div>
							<div className="scenario-item-ph">{i18n('orcafs.scenario.weatherForecasting')}</div>
							<div className="scenario-item-ph">{i18n('orcafs.scenario.energyExploration')}</div>
							<div className="scenario-item-ph">{i18n('orcafs.scenario.remoteSensingSatellite')}</div>
							<div className="scenario-item-ph">{i18n('orcafs.scenario.ai')}</div>
							<div className="scenario-item-ph">{i18n('orcafs.scenario.machineLearning')}</div>
							<div className="scenario-item-ph">{i18n('orcafs.scenario.dataAnalysis')}</div>
						</div>
					</div>
					<div className="feature-box-ph">
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="performance-orcafs" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcafs.feature.performance.name')}</div>
								<div className="desc-ph">{i18n('orcafs.feature.performance.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="protocol" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcafs.feature.protocol.name')}</div>
								<div className="desc-ph">{i18n('orcafs.feature.protocol.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="verification" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcafs.feature.verification.name')}</div>
								<div className="desc-ph">{i18n('orcafs.feature.verification.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="orcaond" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcafs.feature.orcaond.name')}</div>
								<div className="desc-ph">{i18n('orcafs.feature.orcaond.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="capacity" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcafs.feature.capacity.name')}</div>
								<div className="desc-ph">{i18n('orcafs.feature.capacity.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="high-reliability-orcafs" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcafs.feature.highReliability.name')}</div>
								<div className="desc-ph">{i18n('orcafs.feature.highReliability.desc')}</div>
							</div>
						</div>
					</div>
					<div className="button-box-ph">
						<div
							className="view-detail-button-ph"
							onClick={onViewDetailClick.bind(null, routes.OrcaFS)}
						>
							{i18nGen('viewDetail')}
							<i className="oos-button-link-icon ph">→</i>
						</div>
					</div>
				</div>
			</div>
			<div className="product-content-wrap-ph">
				<div
					className={`product-name-ph ${activeIndex === 'orcabd' ? 'active' : ''}`}
					onClick={onSectionChange.bind(null, 'orcabd')}
				>
					<span className="name-ph">{i18n('orcabd.name')}</span>
					<i className="arrow-ph" />
				</div>
				<div className={`product-content-ph ${activeIndex === 'orcabd' ? 'active' : ''}`}>
					<div className="overview-content-ph">
						<div className="desc-ph">{i18n('orcabd.desc')}</div>
						<div className="scenario-box-ph">
							<div className="scenario-item-ph">{i18n('orcabd.scenario.videoMonitor')}</div>
							<div className="scenario-item-ph">{i18n('orcabd.scenario.hybridCloudStorage')}</div>
							<div className="scenario-item-ph">{i18n('orcabd.scenario.dataArchiving')}</div>
							<div className="scenario-item-ph">{i18n('orcabd.scenario.disasterRecoveryBackup')}</div>
						</div>
					</div>
					<div className="feature-box-ph">
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="fusion-architecture" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcabd.feature.fusionArchitecture.name')}</div>
								<div className="desc-ph">{i18n('orcabd.feature.fusionArchitecture.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="intelligent-layering" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcabd.feature.intelligentLayering.name')}</div>
								<div className="desc-ph">{i18n('orcabd.feature.intelligentLayering.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="low-cost" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcabd.feature.lowCost.name')}</div>
								<div className="desc-ph">{i18n('orcabd.feature.lowCost.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="easy-to-expand-orcabd" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcabd.feature.easyToExpand.name')}</div>
								<div className="desc-ph">{i18n('orcabd.feature.easyToExpand.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="high-reliability-orcabd" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcabd.feature.highReliability.name')}</div>
								<div className="desc-ph">{i18n('orcabd.feature.highReliability.desc')}</div>
							</div>
						</div>
						<div className="feature-ph">
							<OOSDynamicIcon className="feature-icon-ph" icon="easy-to-use-orcabd" static />
							<div className="feature-text-content-ph">
								<div className="title-ph">{i18n('orcabd.feature.easyToUse.name')}</div>
								<div className="desc-ph">{i18n('orcabd.feature.easyToUse.desc')}</div>
							</div>
						</div>
					</div>
					<div className="button-box-ph">
						<div
							className="view-detail-button-ph"
							onClick={onViewDetailClick.bind(null, routes.OrcaBD)}
						>
							{i18nGen('viewDetail')}
							<i className="oos-button-link-icon ph">→</i>
						</div>
					</div>
				</div>
			</div>
		</div>)
	, [activeIndex, onSectionChange, onViewDetailClick, i18nGen, i18n]);

	const ProductMemo = useMemo(() => deviceMode !== 'phone' ? ProductPCAndPadMemo : ProductPhoneMemo, [deviceMode, ProductPCAndPadMemo, ProductPhoneMemo]);

	return (
		<>
			<div className="section-title home-product-title">
				<div className="title">{i18n('title')}</div>
				<div className="sub-title">{i18n('subTitle')}</div>
			</div>
			{ProductMemo}
		</>
	);
};

export default Product;
