const zhCN = {
	documentTitle: 'OrcaBackup数据备份解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: 'OrcaBackup数据备份解决方案',
		subTitle: '数据从产生到存储的整个过程，涉及到多种客户端，操作系统、数据库、中间件以及后端基础设施。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		tooMuchData: {
			title: '数据类型多，数据量大',
			desc: '各种前端服务和应用实时产生大量的结构化、非结构化和半结构化数据。如何将不同类型的数据和这些大容量的数据进行快速备份，成为客户在选择备份软件时首要考虑的问题。',
		},
		tooManyClients: {
			title: '客户端多，数据库类型多',
			desc: '现在企业中，客户端涉及windows操作系统、Linux操作系统以及Unix操作系统及每种操作系统下的不同版本，各种客户端，不同的业务，使用的数据库不尽相同，涉及MSSQL、Oracle、MySQL等数据库，针对不同操作系统的系统级备份和不同数据库的数据库级备份也成为客户在选择备份软件过程中考虑因素之一。',
		},
		manyBackupTypes: {
			title: '针对不同业务类型、灵活的备份方式',
			desc: '不同的业务类型，数据主要程度、产生时间、产生的数据量也会不同，如何为不同业务灵活选择不同的备份时间、备份方式成为客户在考虑备份软件的灵活性、全面性方面影响因素之一。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: 'OrcaBackup数据备份解决方案介绍',
			desc1: '在信息化、物联网大数据时代，数据变得越发庞大的同时、也越发的充满价值。谁能充分挖掘数据并快速将其转化为商业和社会价值，谁就能获得数据所带来的巨大红利。然而现实是企业时刻都要面对由于系统崩溃、硬盘损伤、病毒侵害、人为误操作、地震、洪灾等软灾难和自然灾难的发生，任何一种灾难的发生都会造成重要数据丢失，从而使重要的实时业务陷于瘫痪，所造成的损失难以估计。因此在享受数据带来的源源不断的财富的同时，如何保证数据安全成为各大领域关注的重中之重。',
			desc2: '企业生产运行过程中，数据从产生到存储的整个过程，涉及到多种客户端，操作系统、数据库、中间件以及后端基础设施，以及大量重要的、一般的、保密的、公开的数据，时刻进行着数据密级分明、下发有序、存取流畅的操作。',
			desc3: '整个过程中，OrcaBackup数据备份软件采用企业级IT架构，在针对企业海量各类型数据安全可靠的备份的同时，针对系统级、数据库级数据也可提供自由灵活，多种备份方式自由选择的数据备份解决方案。为客户的业务数据保驾护航。针对较差的软硬件和网络环境，具有良好的适应性；针对客户数据安全需求，可有效的保持业务连续和数据安全，并且支持性能扩展和容量扩充，能够为PB级别海量数据提供保护。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		flexibilityOperation: {
			title: '数据按需备份',
			desc: '提升数据安全的同时，降低数据丢失风险。',
		},
		overallBackup: {
			title: '多层级备份',
			desc: '数据按操作系统级、数据库级备份，更加全面、更加完善，可靠性更高。',
		},
		easyToUse: {
			title: '简单易操作',
			desc: '降低人工维护带来的复杂度和风险，降低人工成本。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '优质的方案实施与客户服务案例',
		intelligentLaboratory: {
			title: 'XXX大学智能化实验室项目',
			introduction: '2019年4月份，陕西省某市的XXX大学针对学校的智能化实验室发展现状和未来发展远景，决定面向外界采购相关设备以满足学校的人工智能发展。奥卡云在得知该校情况后，多次跟校方项目负责人进行沟通。对学校的智能化实验室项目有了深入了解，奥卡云从人工智能化现状、存在的问题，以及客户关注的方向，结合学校的现有资源及现状给出了自己的一整套解决方案。在整个项目的前期、实施、后期维护等全过程，奥卡云都做到想客户所想，做客户所做。充分发挥厂商的专业技术和过硬的产品质量，获得客户的高度认可。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
