import {ActionCreatorSet} from '../interfaces';

export const modalActionTypes = {
	SET_MASK_VISIBLE: '@MODAL/SET_MASK_VISIBLE',
	SET_SIGN_UP_VISIBLE: '@MODAL/SET_SIGN_UP_VISIBLE',
	SET_SIGN_IN_VISIBLE: '@MODAL/SET_SIGN_IN_VISIBLE',
	SET_RESET_PASSWORD_VISIBLE: '@MODAL/SET_RESET_PASSWORD_VISIBLE',
	SET_APPLY_TRIAL_VISIBLE: '@MODAL/SET_APPLY_TRIAL_VISIBLE',
	SET_APPLY_JOB_VISIBLE: '@MODAL/SET_APPLY_JOB_VISIBLE',
	SET_CERTIFICATE_DETAIL_VISIBLE: '@MODAL/SET_CERTIFICATE_DETAIL_VISIBLE',
};

const modalAction: ActionCreatorSet = {
	setMaskVisible: maskVisible => ({
		type: modalActionTypes.SET_MASK_VISIBLE,
		maskVisible
	}),

	setSignUpVisible: signUpVisible => ({
		type: modalActionTypes.SET_SIGN_UP_VISIBLE,
		signUpVisible
	}),

	setSignInVisible: signInVisible => ({
		type: modalActionTypes.SET_SIGN_IN_VISIBLE,
		signInVisible
	}),

	setResetPasswordVisible: resetPasswordVisible => ({
		type: modalActionTypes.SET_RESET_PASSWORD_VISIBLE,
		resetPasswordVisible
	}),

	setApplyTrialVisible: applyTrialVisible => ({
		type: modalActionTypes.SET_APPLY_TRIAL_VISIBLE,
		applyTrialVisible
	}),

	setApplyJobVisible: applyJobVisible => ({
		type: modalActionTypes.SET_APPLY_JOB_VISIBLE,
		applyJobVisible
	}),

	setCertificateDetailVisible: certificateDetailVisible => ({
		type: modalActionTypes.SET_CERTIFICATE_DETAIL_VISIBLE,
		certificateDetailVisible
	}),
};

export default modalAction;
