const zhCN = {
	title: 'Cookie政策',
	desc1: '我们一直在努力改善您在我们网站上的体验，其中涉及使用Cookie保存登录信息等行为。请参阅我们的',
	privacyStatement: '隐私声明',
	desc2: '，点击"我接受"表示同意并继续浏览。',
	accept: '我接受',
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
