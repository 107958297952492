import React, {FC, useMemo, useState, useCallback} from 'react';
import {useI18n, useInterval, useSelector, useScroll, useTimeout} from '@hooks';
import SourceGlobe from './SourceGlobe';
import {equals} from '@utils';
import {DeviceMode} from '@interfaces';
import {State} from '@redux/interfaces';

interface SelectedState {
	deviceMode: DeviceMode;
}

const Source: FC = () => {
	// i18n.
	const i18n = useI18n('Home', 'source');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {deviceMode}} = state;
		return {deviceMode};
	}, []);

	const {deviceMode} = useSelector<State, SelectedState>(selectState, equals);

	// Interval states.
	const [activeIndex, setActiveIndex] = useState(1);
	const [waiting, setWaiting] = useState(true);

	const onGlobeReady = useCallback(() => setWaiting(false), []);

	// After rendering and re-rendering.
	useInterval(() => {
		let newActiveIndex = activeIndex + 1;
		if (newActiveIndex >= 5) {
			newActiveIndex = 1;
		}
		setActiveIndex(newActiveIndex);
	}, 10000);

	// Memorized descendant components.
	const SourceGlobeMemo = useMemo(() => (<SourceGlobe onGlobeReady={onGlobeReady} />), [onGlobeReady]);

	const SourceGlobeWithPlaceholderMemo = useMemo(() => (
		<>
			<div className={`source-globe-static-img-ph ${deviceMode === 'phone' ? '' : 'hide'}`} />
			<div className={`source-globe-content ${deviceMode !== 'phone' ? '' : 'hide'}`}>
				<div className={`source-globe-placeholder-wrap ${waiting ? '' : 'hide'}`}>
					<div className="source-globe-placeholder" />
				</div>
				{SourceGlobeMemo}
			</div>
		</>
	), [SourceGlobeMemo, deviceMode, waiting]);

	const IndustryTrendMemo = useMemo(() => (
		<div className="industry-trend-wrap">
			<div className="industry-trend-carousel">
				<div className="industry-trend-opinion" />
				<div className={`industry-trend-carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
					<div className="industry-trend-img-wrap">
						<div className="industry-trend-img distributed-storage" />
					</div>
					<div className="industry-trend-content">
						<div className="title">{i18n('industryTrends.distributedStorage.title')}</div>
						<div className="text">{i18n('industryTrends.distributedStorage.desc')}</div>
						<i className="quote top-left" />
						<i className="quote bottom-right" />
					</div>
				</div>
				<div className={`industry-trend-carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
					<div className="industry-trend-img-wrap">
						<div className="industry-trend-img iot" />
					</div>
					<div className="industry-trend-content">
						<div className="title">{i18n('industryTrends.iot.title')}</div>
						<div className="text">{i18n('industryTrends.iot.desc')}</div>
						<i className="quote top-left" />
						<i className="quote bottom-right" />
					</div>
				</div>
				<div className={`industry-trend-carousel-item ${activeIndex === 3 ? 'active' : ''}`}>
					<div className="industry-trend-img-wrap">
						<div className="industry-trend-img edge-computing" />
					</div>
					<div className="industry-trend-content">
						<div className="title">{i18n('industryTrends.edgeComputing.title')}</div>
						<div className="text">{i18n('industryTrends.edgeComputing.desc')}</div>
						<i className="quote top-left" />
						<i className="quote bottom-right" />
					</div>
				</div>
				<div className={`industry-trend-carousel-item ${activeIndex === 4 ? 'active' : ''}`}>
					<div className="industry-trend-img-wrap">
						<div className="industry-trend-img virtualization" />
					</div>
					<div className="industry-trend-content">
						<div className="title">{i18n('industryTrends.virtualization.title')}</div>
						<div className="text">{i18n('industryTrends.virtualization.desc')}</div>
						<i className="quote top-left" />
						<i className="quote bottom-right" />
					</div>
				</div>
				<div className="pagination">
					<i className={`pagination-item ${activeIndex === 1 ? 'active' : ''}`} />
					<i className={`pagination-item ${activeIndex === 2 ? 'active' : ''}`} />
					<i className={`pagination-item ${activeIndex === 3 ? 'active' : ''}`} />
					<i className={`pagination-item ${activeIndex === 4 ? 'active' : ''}`} />
				</div>
			</div>
		</div>
	), [i18n, activeIndex]);

	return (
		<>
			<div className="section-title">
				<div className="title">{i18n('title')}</div>
				<div className="sub-title">{i18n('subTitle')}</div>
			</div>
			<div className="source">
				<div className="source-globe-wrap">{SourceGlobeWithPlaceholderMemo}</div>
				<div className="source-globe-legend-wrap">
					<div className="source-globe-legend-content">
						<div className="legend-title">{i18n('legendTitle')}</div>
						<div className="legend-item opened">{i18n('opened')}</div>
						<div className="legend-item opening">{i18n('opening')}</div>
						<div className="legend-item to-be-opened">{i18n('toBeOpened')}</div>
					</div>
				</div>
				<div className="source-content-wrap">
					<div className="overview-text-content-box">
						<div className="overview-text-content-item">
							<div className="item-number">3</div>
							<div className="item-text">{i18n('rdCenter')}</div>
						</div>
						<div className="overview-text-content-item">
							<div className="item-number">150</div>
							<div className="item-text">{i18n('rdPeople')}</div>
						</div>
						<div className="overview-text-content-item">
							<div className="item-number">136</div>
							<div className="item-text">{i18n('case')}</div>
						</div>
						<div className="overview-text-content-item">
							<div className="item-number">96</div>
							<div className="item-text">{i18n('partner')}</div>
						</div>
					</div>
					{IndustryTrendMemo}
				</div>
			</div>
		</>
	);
};

export default Source;
