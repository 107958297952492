import React, {FC, useCallback, useState} from 'react';
import generalAction from '@actions/generalAction';
import {useDispatch, useI18n, useNavigate, useSelector} from '@hooks';
import {OOSDynamicIcon} from '@components';
import {DOM, equals} from '@utils';
import {DeviceMode} from '@interfaces';
import {State} from '@redux/interfaces';
import routes from '@routes';

interface SelectedState {
	deviceMode: DeviceMode;
}

const Solution: FC = () => {
	// i18n.
	const i18nGen = useI18n('General');
	const i18n = useI18n('Home', 'solution');

	// Redux related.
	const selectState = useCallback((state: State) => {
		const {general: {deviceMode}} = state;
		return {deviceMode};
	}, []);

	const {deviceMode} = useSelector<State, SelectedState>(selectState, equals);

	const dispatch = useDispatch();

	const setActiveSubmenu = useCallback((activeSubmenu: string) => dispatch(generalAction.setActiveSubmenu(activeSubmenu)), [dispatch]);

	const setActiveSolutionCategory = useCallback((activeSolutionCategory: string) => dispatch(generalAction.setActiveSolutionCategory(activeSolutionCategory)), [dispatch]);

	const setActiveSolutionSecondaryCategory = useCallback((activeSolutionSecondaryCategory: string) => dispatch(generalAction.setActiveSolutionSecondaryCategory(activeSolutionSecondaryCategory)), [dispatch]);

	// Router.
	const navigate = useNavigate();

	// Internal states.
	const [activeSolutionCategoryIcon, setActiveSolutionCategoryIcon] = useState<string | null>(null);

	// Page actions.
	const onSolutionCategoryMouseEnter = useCallback((icon: string) => setActiveSolutionCategoryIcon(icon), []);

	const onSolutionCategoryMouseLeave = useCallback(() => setActiveSolutionCategoryIcon(null), []);

	const onSolutionCategoryClick = useCallback((category: string, secondaryCategory: string) => {
		setActiveSubmenu('solution');
		setActiveSolutionCategory(category);
		setActiveSolutionSecondaryCategory(secondaryCategory);
		DOM.scrollTo(0);
	}, [setActiveSubmenu, setActiveSolutionCategory, setActiveSolutionSecondaryCategory]);

	const onSolutionClick = useCallback((path: string) => navigate(path), [navigate]);

	return (
		<>
			<div className="section-title">
				<div className="title">{i18n('title')}</div>
				<div className="sub-title">{i18n('subTitle')}</div>
			</div>
			<div className="solution">
				<div className="solution-content">
					<div className="solution-overview">
						<div className="solution-overview-text">{i18n('overviewText')}</div>
					</div>
					<div className="solution-category-box">
						<div
							className="solution-category-item"
							onMouseEnter={onSolutionCategoryMouseEnter.bind(null, 'general-category')}
							onMouseLeave={onSolutionCategoryMouseLeave}
							onClick={onSolutionCategoryClick.bind(null, 'storage','general')}
						>
							<OOSDynamicIcon className="solution-category-icon" icon="general-category" activeIcon={activeSolutionCategoryIcon} static={deviceMode !== 'pc'} />
							<div className="solution-category-title-content">
								<div className="title">{i18n('general.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="solution-category-text-content">
								<i className="angle top-left" />
								<i className="angle bottom-right" />
								<div className="solution-category-text-content-text">
									{i18n('general.desc')}
								</div>
							</div>
						</div>
						<div
							className="solution-category-item"
							onMouseEnter={onSolutionCategoryMouseEnter.bind(null, 'intelligent-campus-category')}
							onMouseLeave={onSolutionCategoryMouseLeave}
							onClick={onSolutionClick.bind(null, routes.IntelligentCampus)}
						>
							<OOSDynamicIcon className="solution-category-icon" icon="intelligent-campus-category" activeIcon={activeSolutionCategoryIcon} static={deviceMode !== 'pc'} />
							<div className="solution-category-title-content">
								<div className="title">{i18n('intelligentCampus.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="solution-category-text-content">
								<i className="angle top-left" />
								<i className="angle bottom-right" />
								<div className="solution-category-text-content-text">
									{i18n('intelligentCampus.desc')}
								</div>
							</div>
						</div>
						<div
							className="solution-category-item"
							onMouseEnter={onSolutionCategoryMouseEnter.bind(null, 'intelligent-police-category')}
							onMouseLeave={onSolutionCategoryMouseLeave}
							onClick={onSolutionCategoryClick.bind(null, 'iot','intelligentPolice')}
						>
							<OOSDynamicIcon className="solution-category-icon" icon="intelligent-police-category" activeIcon={activeSolutionCategoryIcon} static={deviceMode !== 'pc'} />
							<div className="solution-category-title-content">
								<div className="title">{i18n('intelligentPolice.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="solution-category-text-content">
								<i className="angle top-left" />
								<i className="angle bottom-right" />
								<div className="solution-category-text-content-text">
									{i18n('intelligentPolice.desc')}
								</div>
							</div>
						</div>
						<div
							className="solution-category-item"
							onMouseEnter={onSolutionCategoryMouseEnter.bind(null, 'intelligent-community-category')}
							onMouseLeave={onSolutionCategoryMouseLeave}
							onClick={onSolutionCategoryClick.bind(null, 'iot','intelligentCommunity')}
						>
							<OOSDynamicIcon className="solution-category-icon" icon="intelligent-community-category" activeIcon={activeSolutionCategoryIcon} static={deviceMode !== 'pc'} />
							<div className="solution-category-title-content">
								<div className="title">{i18n('intelligentCommunity.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="solution-category-text-content">
								<i className="angle top-left" />
								<i className="angle bottom-right" />
								<div className="solution-category-text-content-text">
									{i18n('intelligentCommunity.desc')}
								</div>
							</div>
						</div>
						<div
							className="solution-category-item"
							onMouseEnter={onSolutionCategoryMouseEnter.bind(null, 'intelligent-scenic-spot-category')}
							onMouseLeave={onSolutionCategoryMouseLeave}
							onClick={onSolutionCategoryClick.bind(null, 'iot','intelligentScenicSpot')}
						>
							<OOSDynamicIcon className="solution-category-icon" icon="intelligent-scenic-spot-category" activeIcon={activeSolutionCategoryIcon} static={deviceMode !== 'pc'} />
							<div className="solution-category-title-content">
								<div className="title">{i18n('intelligentScenicSpot.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="solution-category-text-content">
								<i className="angle top-left" />
								<i className="angle bottom-right" />
								<div className="solution-category-text-content-text">
									{i18n('intelligentScenicSpot.desc')}
								</div>
							</div>
						</div>
						<div
							className="solution-category-item"
							onMouseEnter={onSolutionCategoryMouseEnter.bind(null, 'emergency-category')}
							onMouseLeave={onSolutionCategoryMouseLeave}
							onClick={onSolutionCategoryClick.bind(null, 'iot','emergency')}
						>
							<OOSDynamicIcon className="solution-category-icon" icon="emergency-category" activeIcon={activeSolutionCategoryIcon} static={deviceMode !== 'pc'} />
							<div className="solution-category-title-content">
								<div className="title">{i18n('emergency.name')}</div>
								<div className="view-detail-button">
									{i18nGen('viewDetail')}
									<i className="oos-button-link-icon">→</i>
								</div>
							</div>
							<div className="solution-category-text-content">
								<i className="angle top-left" />
								<i className="angle bottom-right" />
								<div className="solution-category-text-content-text">
									{i18n('emergency.desc')}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="solution-content-active-bg" />
			</div>
		</>
	);
};

export default Solution;
