import React, {FC, useEffect} from 'react';
import {useTimeout} from '@hooks';
import {useFrame} from '@react-three/fiber';
import ThreeGlobe from 'three-globe/dist/three-globe.js';
import City from '@3DSources/city_geo.json';
import GLOBE_TEXTURE_PNG from '@3DSources/globe_texture.webp';

interface Arc {
	startLat: number;
	startLng: number;
	endLat: number;
	endLng: number;
	color: string;
}

interface Ring {
	lat: number;
	lng: number;
	color: string;
}

interface Point {
	lat: number;
	lng: number;
	color: string;
}

const globeConf = {
	waitForGlobeReady: true,
	animateIn: false,
};

const ARC_AND_RING_COLOR = '#8d9dba';
const OPENING_POINT_COLOR = '#2a86f8';
const OPENED_POINT_COLOR = '#23815d';
const TO_BE_OPENED_POINT_COLOR = '#3c1d14';

const ARC_DASH_ANIMATE_TIME = 2500;

const arcs: Arc[] = [
	{
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.ShangHai.lat,
		endLng: City.ShangHai.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.ChengDu.lat,
		startLng: City.ChengDu.lng,
		endLat: City.XiAn.lat,
		endLng: City.XiAn.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.Boston.lat,
		startLng: City.Boston.lng,
		endLat: City.XiAn.lat,
		endLng: City.XiAn.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.SanJose.lat,
		startLng: City.SanJose.lng,
		endLat: City.XiAn.lat,
		endLng: City.XiAn.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.ScottsValley.lat,
		startLng: City.ScottsValley.lng,
		endLat: City.XiAn.lat,
		endLng: City.XiAn.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.ChangSha.lat,
		startLng: City.ChangSha.lng,
		endLat: City.XiAn.lat,
		endLng: City.XiAn.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.YinChuan.lat,
		endLng: City.YinChuan.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.WuXi.lat,
		endLng: City.WuXi.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.Beijing.lat,
		endLng: City.Beijing.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.TianJin.lat,
		endLng: City.TianJin.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.Beijing.lat,
		startLng: City.Beijing.lng,
		endLat: City.ShangHai.lat,
		endLng: City.ShangHai.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.ShenZhen.lat,
		startLng: City.ShenZhen.lng,
		endLat: City.ChangSha.lat,
		endLng: City.ChangSha.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.ChengDu.lat,
		startLng: City.ChengDu.lng,
		endLat: City.HongKong.lat,
		endLng: City.HongKong.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.LanZhou.lat,
		endLng: City.LanZhou.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.YunCheng.lat,
		endLng: City.YunCheng.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.HanDan.lat,
		endLng: City.HanDan.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.XiAn.lat,
		startLng: City.XiAn.lng,
		endLat: City.TaoYuan.lat,
		endLng: City.TaoYuan.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		startLat: City.TaiBei.lat,
		startLng: City.TaiBei.lng,
		endLat: City.HongKong.lat,
		endLng: City.HongKong.lng,
		color: ARC_AND_RING_COLOR,
	},
];

const rings: Ring[] = [
	{
		lat: City.XiAn.lat,
		lng: City.XiAn.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.ChengDu.lat,
		lng: City.ChengDu.lng,
		color: ARC_AND_RING_COLOR,
	},  {
		lat: City.ChangSha.lat,
		lng: City.ChangSha.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.ShangHai.lat,
		lng: City.ShangHai.lng,
		color: ARC_AND_RING_COLOR,
	},  {
		lat: City.WuXi.lat,
		lng: City.WuXi.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.Boston.lat,
		lng: City.Boston.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.SanJose.lat,
		lng: City.SanJose.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.ScottsValley.lat,
		lng: City.ScottsValley.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.TianJin.lat,
		lng: City.TianJin.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.YunCheng.lat,
		lng: City.YunCheng.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.TaiYuan.lat,
		lng: City.TaiYuan.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.LanZhou.lat,
		lng: City.LanZhou.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.YinChuan.lat,
		lng: City.YinChuan.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.YangLing.lat,
		lng: City.YangLing.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.HanDan.lat,
		lng: City.HanDan.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.ShenZhen.lat,
		lng: City.ShenZhen.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.TaiBei.lat,
		lng: City.TaiBei.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.TaoYuan.lat,
		lng: City.TaoYuan.lng,
		color: ARC_AND_RING_COLOR,
	}, {
		lat: City.HongKong.lat,
		lng: City.HongKong.lng,
		color: ARC_AND_RING_COLOR,
	},
];

const points: Point[] = [
	{
		lat: City.XiAn.lat,
		lng: City.XiAn.lng,
		color: OPENED_POINT_COLOR,
	}, {
		lat: City.Beijing.lat,
		lng: City.Beijing.lng,
		color: OPENED_POINT_COLOR,
	}, {
		lat: City.ShenZhen.lat,
		lng: City.ShenZhen.lng,
		color: OPENED_POINT_COLOR,
	}, {
		lat: City.ChengDu.lat,
		lng: City.ChengDu.lng,
		color: OPENED_POINT_COLOR,
	}, {
		lat: City.ShangHai.lat,
		lng: City.ShangHai.lng,
		color: OPENED_POINT_COLOR,
	}, {
		lat: City.WuLuMuQi.lat,
		lng: City.WuLuMuQi.lng,
		color: OPENING_POINT_COLOR,
	}, {
		lat: City.HuHeHaoTe.lat,
		lng: City.HuHeHaoTe.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.HaErBin.lat,
		lng: City.HaErBin.lng,
		color: OPENING_POINT_COLOR,
	}, {
		lat: City.WuHan.lat,
		lng: City.WuHan.lng,
		color: OPENING_POINT_COLOR,
	}, {
		lat: City.QingDao.lat,
		lng: City.QingDao.lng,
		color: OPENING_POINT_COLOR,
	}, {
		lat: City.KunMing.lat,
		lng: City.KunMing.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.FuZhou.lat,
		lng: City.FuZhou.lng,
		color: OPENING_POINT_COLOR,
	}, {
		lat: City.LaSa.lat,
		lng: City.LaSa.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.London.lat,
		lng: City.London.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.Paris.lat,
		lng: City.Paris.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.Berlin.lat,
		lng: City.Berlin.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.Moscow.lat,
		lng: City.Moscow.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.Toronto.lat,
		lng: City.Toronto.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.Boston.lat,
		lng: City.Boston.lng,
		color: OPENED_POINT_COLOR,
	}, {
		lat: City.Santiago.lat,
		lng: City.Santiago.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.SaoPaulo.lat,
		lng: City.SaoPaulo.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}, {
		lat: City.Auckland.lat,
		lng: City.Auckland.lng,
		color: TO_BE_OPENED_POINT_COLOR,
	}
];

const arcs1 = [arcs[0], arcs[5], arcs[10], arcs[15]];
const arcs2 = [arcs[1], arcs[6], arcs[11],];
const arcs3 = [arcs[2], arcs[7], arcs[12], arcs[16]];
const arcs4 = [arcs[3], arcs[8], arcs[13],];
const arcs5 = [arcs[4], arcs[9], arcs[14], arcs[17]];

const rings1 = [rings[0], rings[5], rings[10], rings[15],];
const rings2 = [rings[1], rings[6], rings[11], rings[16],];
const rings3 = [rings[2], rings[7], rings[12], rings[17],];
const rings4 = [rings[3], rings[8], rings[13],];
const rings5 = [rings[4], rings[9], rings[14], rings[18]];

interface GlobeProps {
	onGlobeReady: () => void;
}

const Globe: FC<GlobeProps> = ({
	onGlobeReady: pOnGlobeReady
}) => {
	const globe = new ThreeGlobe(globeConf)
		.globeImageUrl(GLOBE_TEXTURE_PNG)
		.showAtmosphere(false)
		.arcsData(arcs1)
		.arcAltitude(0.2)
		.arcStroke(0.15)
		.arcColor('color')
		.arcDashLength(1)
		.arcDashGap(2)
		.arcDashInitialGap(0)
		.arcDashAnimateTime(ARC_DASH_ANIMATE_TIME)
		.ringsData(rings1)
		.ringColor('color')
		.ringAltitude(0)
		.ringMaxRadius(0.6)
		.ringPropagationSpeed(0.4)
		.ringRepeatPeriod(1500)
		.pointsData(points)
		.pointRadius(0.2)
		.pointAltitude(0)
		.pointColor('color')
		.onGlobeReady(pOnGlobeReady)
	;

	useTimeout(() => {
		globe.arcsData([...globe.arcsData(), ...arcs2]);
		globe.ringsData([...globe.ringsData(), ...rings2]);
	}, ARC_DASH_ANIMATE_TIME * 2);

	useTimeout(() => {
		globe.arcsData([...globe.arcsData(), ...arcs3]);
		globe.ringsData([...globe.ringsData(), ...rings3]);
	}, ARC_DASH_ANIMATE_TIME * 3);

	useTimeout(() => {
		globe.arcsData([...globe.arcsData(), ...arcs4]);
		globe.ringsData([...globe.ringsData(), ...rings4]);
	}, ARC_DASH_ANIMATE_TIME * 4);

	useTimeout(() => {
		globe.arcsData([...globe.arcsData(), ...arcs5]);
		globe.ringsData([...globe.ringsData(), ...rings5]);
	}, ARC_DASH_ANIMATE_TIME * 5);

	useFrame(() => void(globe.rotation.y += 0.0002));

	return (<primitive object={globe} />);
};

export default Globe;
