import {useMemo} from 'react';
import {useI18n} from '@hooks';
import {SearchResult} from '@interfaces';
import routes from '@routes';

export default function useSearchKeywordMap (){
	const i18n = useI18n('TopBar');

	return useMemo<Record<string, SearchResult>>(() => (
		{
			// Product.
			'orcastor uniio分布式全闪存块存储文件存储': {
				name: i18n('search.content.uniio'),
				path: routes.UniIO,
			},
			'orcabd分布式块存储对象存储': {
				name: i18n('search.content.orcabd'),
				path: routes.OrcaBD,
			},
			'orcafs分布式并行文件存储文件系统': {
				name: i18n('search.content.orcafs'),
				path: routes.OrcaFS,
			},

			// Storage solution.
			// General category.
			'大数据解决方案': {
				name: i18n('search.content.bigData'),
				path: routes.BigData,
			},
			'高性能计算': {
				name: i18n('search.content.highPerformanceComputing'),
				path: routes.HighPerformanceComputing,
			},
			'云计算/私有云': {
				name: i18n('search.content.cloudComputingPrivateCloud'),
				path: routes.CloudComputingPrivateCloud,
			},
			'云原生容器存储': {
				name: i18n('search.content.cloudNativeContainerStorage'),
				path: routes.CloudNativeContainerStorage,
			},
			'orcabackup数据备份解决方案': {
				name: i18n('search.content.dataBackup'),
				path: routes.DataBackup,
			},
			// Industry category.
			'新能源汽车': {
				name: i18n('search.content.newEnergyVehicle'),
				path: routes.NewEnergyVehicle,
			},
			'智慧校园解决方案': {
				name: i18n('search.content.intelligentCampus'),
				path: routes.IntelligentCampus,
			},
			'智慧安防解决方案': {
				name: i18n('search.content.intelligentSecurity'),
				path: routes.IntelligentSecurity,
			},
			'ai人工智能解决方案': {
				name: i18n('search.content.ai'),
				path: routes.AI,
			},

			// IoT solution.
			// Intelligent police category.
			'雪亮工程解决方案': {
				name: i18n('search.content.brightProject'),
				path: routes.MajorEventSecurity,
			},
			'重大活动安保解决方案': {
				name: i18n('search.content.majorEventSecurity'),
				path: routes.MajorEventSecurity,
			},
			// Intelligent community category.
			'智慧社区解决方案': {
				name: i18n('search.content.intelligentCommunity'),
				path: routes.IntelligentCommunity,
			},
			'智慧社区安防解决方案': {
				name: i18n('search.content.intelligentCommunitySecurity'),
				path: routes.IntelligentCommunitySecurity,
			},
			'智慧电梯解决方案': {
				name: i18n('search.content.intelligentElevator'),
				path: routes.IntelligentElevator,
			},
			'高空抛物解决方案': {
				name: i18n('search.content.highAltitudeThrowObject'),
				path: routes.HighAltitudeThrowObject,
			},
			// Intelligent park category.
			'智慧园区解决方案': {
				name: i18n('search.content.intelligentPark'),
				path: routes.IntelligentPark,
			},
			// Intelligent scenic spot category.
			'智慧景区解决方案': {
				name: i18n('search.content.intelligentScenicSpot'),
				path: routes.IntelligentScenicSpot,
			},
			'全域旅游解决方案': {
				name: i18n('search.content.allAreaTourism'),
				path: routes.AllAreaTourism,
			},
			// Intelligent garden category.
			'智慧园林解决方案': {
				name: i18n('search.content.intelligentGarden'),
				path: routes.IntelligentGarden,
			},
			// Carbon neutrality category.
			'碳中和解决方案': {
				name: i18n('search.content.carbonNeutrality'),
				path: routes.CarbonNeutrality,
			},
			// Emergency category.
			'应急指挥解决方案': {
				name: i18n('search.content.emergencyCommand'),
				path: routes.EmergencyCommand,
			},
			'安全生产应急监管平台解决方案': {
				name: i18n('search.content.safetyProductionEmergency'),
				path: routes.SafetyProductionEmergency,
			},
			// General category.
			'iot物联网平台解决方案': {
				name: i18n('search.content.iotPlatform'),
				path: routes.IoTPlatform,
			},
		}
	), [i18n]);
}
