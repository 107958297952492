const zhCN = {
	documentTitle: '智慧校园解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '智慧校园解决方案',
		subTitle: '智慧校园是依托数据时代的数据资源、结合信息化和智能化的万物互联平台。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		hardToStoreData: {
			title: '数据多样性存储难、设备种类多管理难',
			desc: '随着智慧校园普及，数据量指数增加，针对传统存储来说，结构化和非结构化等各类数据需要存储到不同的设备上，校园一卡通存储、平安校园各类视图数据存储。对智慧校园发展是一大限制。',
		},
		poorHDExperience: {
			title: '高清视图业务体验差',
			desc: '智慧校园中，高清视图系统、虚拟实验室、智能教室、远程教学等都对后端存储的性能提出更高的要求。',
		},
		lowReliability: {
			title: '数据安全可靠性低，数据丢失风险高',
			desc: '智慧校园中视图数据的重复调阅普遍、数据重要级别高、数据保存时间长。而传统存储随着设备数量增加、其硬件兼容性和融合度变得愈发困难。导致数据可靠性、安全性也随之降低。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '智慧校园解决方案介绍',
			desc1: '智慧校园是依托数据时代的数据资源、结合信息化和智能化的万物互联平台。充分调动教育资源、使教育资源不局限于地理位置上的限制。通过各种媒介充分利用教育资源，服务师生、服务社会，并协助学校智能管理学校事务。智慧校园包括：平安校园建设、学生日常行为预警、校园一卡通、电子图书馆、虚拟实验室、远程教学、教学视频共享、智慧课堂、师生档案管理等。涉及海量结构和非结构化数据处理、存储，对后端存储有更高的依赖和要求。',
			desc2: '依托OrcaBD分布式存储，OrcaBD采用去中心化的设计，通过软件定义存储技术，将物理上的存储资源融合为一个统一的存储池。OrcaBD分布式存储特性：',
			desc3: '分布式架构，提高存储整体性能，满足校园各类高清教学视频播放、虚拟实验室试验等。',
			desc4: '支持单盘10TB容量，支持36盘位高密度主机，实现单主机360TB的大容量存储。提供大容量，减少设备数量、减少机房空间占用。',
			desc5: '数据设备级冗余保护，支持副本、EC多种数据保护机制。保证数据安全、高可靠性存取。',
			desc6: '设备按需在线扩容，容量性能虽设备数量指数增长。实现前端业务无感知，业务流畅运行，极致体验。',
			desc7: '设备统一智能管理维护，容量极限提前预警，系统和硬盘7*24小时故障检测、智能生成分析报告。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		easyToManage: {
			title: '大容量易管理',
			desc: '减少设备数量、设备空间占用量，降低管理维护成本。',
		},
		scaleOut: {
			title: '高性能易扩展',
			desc: '在线按需扩展、容量与性能线性增加。',
		},
		highReliability: {
			title: '高可靠更安全',
			desc: '增加数据企业级安全可靠保护能力，降低数据丢失风险。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
