const zhCN = {
	documentTitle: '智慧景区解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '智慧景区解决方案',
		subTitle: '奥卡云智慧景区解决方案以高清监控、智慧物联、人工智能等新兴信息技术为支撑，对旅游景区的综合安防管理系统进行布局。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		hardToMonitor: {
			title: '视频监控不到位',
			desc: '景区内监控盲区多，监控范围遮挡严重，监控范围光线较差，且监控设备分布比较分散，与控制中心的距离较远，影响监控图像质量，历史录像保存时间短，难以追溯。',
		},
		lowEfficiency: {
			title: '效率低、安防管控弱',
			desc: '景区通行效率低，导致游客排队等候时间过长，并且针对人员的安防管控缺乏有效手段。',
		},
		hardToManageCar: {
			title: '缺乏对车辆的有效管理',
			desc: '景区车辆缺少有效监管，来往车辆较多，易发生交通事故，且对出入车辆不留痕，难以核查或进行事后追溯。',
		},
		hardToAnalyseCustomer: {
			title: '客流管控弱',
			desc: '景区客流量管控缺乏直接有效的手段，通常比较滞后，客流统计不及时，客流分布不明确，疏散方式不明确，若发生事故，人员安全将受到严重威胁。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '智慧景区解决方案介绍',
			desc1: '2015年国家旅游局发布5A、4A级景区智能化建设要求，到2020年实现全覆盖。国家十三五规划纲要中明确地提出大力发展旅游产业，意味着在接下来的几年中乃至长期，旅游产业的发展将作为国家重要的发展战略。',
			desc2: '奥卡云智慧景区解决方案，以高清监控、智慧物联、人工智能等新兴信息技术为支撑，对旅游景区的综合安防管理系统进行布局，实现视频监控、人脸识别、客流量统计、森林防火、车辆管理系统等各系统的多系统之间的综合化管理，减少一线工作人员工作量，景区通行高效便捷，为景区管理者提供管理抓手。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		softwareDefinedScenic: {
			title: '场景软件定义',
			desc: '前端摄像机可以根据场景的需求灵活更换算法。',
		},
		highlyIntegrated: {
			title: '应用高度集成',
			desc: '平台集成了各个安防子系统的应用功能，如视频、客流分析、报警、预警、运维、车辆管理等。',
		},
		unifiedManagement: {
			title: '综合统一管理',
			desc: '跨系统、跨平台互连通用，实现多个系统业务的综合管理。',
		},
		cloudFlexible: {
			title: '云化弹性共享',
			desc: '基础资源、平台层以及数据应用层，均依托云计算设计思路，满足资源的充分共享和弹性调度。',
		},
		faceTrack: {
			title: '智慧人脸追踪',
			desc: '人脸抓拍机、人脸搜索、人脸布控、人员轨迹、人员频次等智能应用。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		qinHanNewCityDaQinWenMingYuan: {
			title: '秦汉新城大秦文明园广场综合管理平台项目',
			introduction: '大秦文明园广场最南端与渭河隔路相望，总占地1870亩，由中轴景观带、两侧商业景观街、地下商业和地下停车场组成，是集休闲、娱乐、购物为一体的景观绿地广场。通过文明园景区项目建设，将文明园景区的核心是将原有孤立的各子系统连接，把人员、车辆、设备、监控、物联网、便民服务、游客互动和业务管理紧密地联系在一起，打破以往系统建设过程中信息孤岛、数据孤岛，连岛成路，实现资源共享，为数据的提取提供整体系统脉络架构。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
