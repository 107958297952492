const zhCN = {
	documentTitle: '智慧社区安防解决方案 奥卡云数据 - 软件定义存储 分布式存储',
	banner: {
		title: '智慧社区安防解决方案',
		subTitle: '社区是城市的细胞，是城市的重要组成部分，也是对城市概念的继承、发展和实施。',
	},
	customerPainPoint: {
		title: '客户痛点',
		subTitle: '流程、安全、效率等方面的问题剖析',
		hiddenDanger: {
			title: '安全隐患突出',
			desc: '园区/社区的访客、外来人员及车辆多，识别困难，造成安全隐患逐渐突出。',
		},
		hardToGetEvidence: {
			title: '调查取证难，管理滞后',
			desc: '传统模式的安防只能在事后寻找线索，取证费时费力，无法做到提前布控，提前预警，及时响应。',
		},
		securityWeakness: {
			title: '安全防控能力弱',
			desc: '未形成人员专题库，对人员属性的精细化管理不足，对进入社区的可疑人员缺乏预警防控，对人员异常行为不能进行有效识别。',
		},
	},
	introduction: {
		title: '方案介绍',
		subTitle: '行业概述、方案特点、产品支持',
		detail: {
			title: '智慧社区安防解决方案介绍',
			desc1: '社区是城市的细胞，是城市的重要组成部分，也是对城市概念的继承、发展和实施。奥卡云智慧社区解决方案依据《全国公安机关社会治安防控体系建设指南》，构建“圈层查控、单元防控、要素管控”的结构布局，以“一标六实”为根基，以社区民警业务流程为指引，集社区警务管理、基础要素采集、公共服务办理、便民利民服务为一体的社区警务信息管理系统 。实现信息采集、治安管理、重点人员管理一系列功能。',
			desc2: '通过智慧社区综合平台，汇聚所有视频图像资源，同时也需要关联业务数据，通过多维碰撞创建一系列新的应用和技战法，对各个部门的业务进行有力的数据支撑，对于人员的管理从迁出精确到户，迁入精确到楼栋，迁入出租房屋推荐，推送到移动采集终端。关联人口房屋、车辆、警情等数据，为后续的大数据分析提供数据基础。',
			desc3: '同时基于视频云的视频大数据平台不但实现了多维数据的汇聚，更是服务于多部门、多业务对数据的实际需求，精准提供相关的数据支撑，从而真正实现数据为应用服务，应用为实战服务的目标。',
		},
	},
	customerValue: {
		title: '客户价值',
		subTitle: '解决客户痛点，凸显客户利益与价值',
		communityGrid: {
			title: '社区网格化管理',
			desc: '强化"一标六实"数据采集，建立精细化社区网格，各类人员分布统计，提升社区网格化管理水平。',
		},
		defenseLine: {
			title: '筑牢四道防线',
			desc: '通过在社区应用人脸识别、车牌识别、视频监控、周界入侵、行为检测、消防烟感等感知技术，构建以边界防护圈、内部防护网、楼栋防护点、安全防护阵地为主的社区四道防线，维护社区的稳定和长治久安。',
		},
		informationIntegration: {
			title: '泛感知多维信息整合',
			desc: '由前端设备采集不同维度信息，同时对接多张网络各种业务系统获取数据，对数据源进行清洗，再通过联网汇聚，基于GIS实现展示和调用。',
		},
		keyPerson: {
			title: '管控重点人员',
			desc: '建立重点人员库，对重点人员的卡/证信息、人脸信息采集，分析重点人员行为，对各类异常行为进行预警防控。通过建立各类人员专题库，实现对人员属性的精细化管理，对进入社区或单元的可疑人员进行预警防控。',
		},
		communityArchive: {
			title: '社区全息档案',
			desc: '将社区感知数据与公安、综治等业务数据进行融合关联，实现人员和房屋、车辆的标签化，构建以一人一档、一车一档和一屋一档为核心的全息档案，并且多档关联。',
		},
	},
	networking: {
		title: '标准组网',
		subTitle: '系统与网络架构',
	},
	case: {
		title: '客户案例',
		subTitle: '客户项目建设与解决方案实施案例',
		weiLiuJiaYuan: {
			title: '秦汉新城渭柳佳苑智慧社区安防',
			introduction: '渭柳佳苑社区是西咸新区秦汉新城最大的安居工程，社区面积大、人口多、流动人口频繁，人口居住的集中化、流动的多样化，给社区管理与治理方面出现一些新的问题，各类社会矛盾和冲突逐步增多。通过智慧社区的建设，通过搭建统一的社区综合安防平台，使一线网格员、警务人员、物业管理人员实现了对实有人口、流动人口、重点关注人员、重点车辆的精确管理与管控，视频监控对整个社区全方位实时监控，实现了突发事件可视化指挥调度，提高处理效率。',
		},
	},
};

const enUS = {};

const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
