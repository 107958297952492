import {productActionTypes} from '@actions/productAction';
import {Reducer} from '../interfaces';

const productReducer: Reducer = (state, action) => {
    const {productList, documentList, documentDetail, afterSalesCaseList, imageGalleryList, imageGalleryDetail, certificateList} = action;

    switch (action.type) {
        case productActionTypes.SET_PRODUCT_LIST:
            return Object.assign({}, state, {productList});

        case productActionTypes.SET_DOCUMENT_LIST:
            return Object.assign({}, state, {documentList});

        case productActionTypes.SET_DOCUMENT_DETAIL:
            return Object.assign({}, state, {documentDetail});

        case productActionTypes.SET_AFTER_SALES_CASE_LIST:
            return Object.assign({}, state, {afterSalesCaseList});

        case productActionTypes.SET_IMAGE_GALLERY_LIST:
            return Object.assign({}, state, {imageGalleryList});

        case productActionTypes.SET_IMAGE_GALLERY_DETAIL:
            return Object.assign({}, state, {imageGalleryDetail});

        case productActionTypes.SET_CERTIFICATE_LIST:
            return Object.assign({}, state, {certificateList});

        default:
            return state;
    }
};

export default productReducer;
