// Locale.
export const DEFAULT_LOCALE = 'zh-CN';

export const API_VERSION = '/api/v2';

export const NORMAL_TIMEOUT_TIME = 2 * 60_000; // 2 minutes.

export const ULTRA_TIMEOUT_TIME = 5 * 60_000; // 5 minutes.

export const SERVICE_HOTLINE = '029-33185901';

export const TECHNICAL_SUPPORT_HOTLINE = '029-33185902';

export const SERVICE_EMAIL = 'service@orcadt.com';

export const HOME_BANNER_CAROUSEL_ITEM_NUMBER = 4;
