import React, {FC, ReactNode, useRef} from 'react';

interface OOSNormalBannerAnimProps {
	children?: ReactNode,
	name: string;
}

const OOSNormalBannerAnim: FC<OOSNormalBannerAnimProps> = ({
	children: pChildren,
	name: pName,
}) => {
	// Reference equivalent child components.
	const BannerBaseRef = useRef(
		<>
			<i className="banner-light-curtain big" />
			<i className="banner-light-curtain small" />
			<i className="banner-light-window-curtain" />
			<i className="banner-light-point-small left" />
			<i className="banner-light-point-small right" />
			<i className="banner-light-circle" />
			<i className="banner-light-bar-box">
				<i className="light-bar" />
				<i className="light-bar" />
			</i>
			<i className="banner-light-ray-box top">
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
			</i>
			<i className="banner-light-ray-box bottom">
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
				<i className="light-ray" />
			</i>
		</>
	);

	return (
		<>
			<div className="normal-banner-anim-base-img">
				{BannerBaseRef.current}
				<i className={`banner-float-img ${pName}`}>
					{pChildren}
				</i>
			</div>
			<div className={`normal-banner-static-img-pad-ph ${pName}`} />
		</>
	);
};

export default OOSNormalBannerAnim;
