import {ActionCreatorSet} from '../interfaces';

export const localeActionTypes = {
	SET_LOCALE: '@LOCALE/SET_LOCALE',
};

const localeAction: ActionCreatorSet = {
	setLocale: locale => ({
		type: localeActionTypes.SET_LOCALE,
		locale
	}),
};

export default localeAction;
