const MOBILE_PHONE_NUMBER_REG = /^1[3456789]\d{9}$/;
const TELEPHONE_NUMBER_REG = /^[0][1-9]{2,3}-[0-9]{5,10}$/;
const EMAIL_REG = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
const PASSWORD_REG = /^.{6,12}$/;

export const validateMobilePhone = (text: number | string) => MOBILE_PHONE_NUMBER_REG.test(String(text));

export const validateTelephone = (text: number | string) => TELEPHONE_NUMBER_REG.test(String(text));

export const validateEmail = (text: string) => EMAIL_REG.test(text);

export const validatePassword = (text: string) => PASSWORD_REG.test(text);
