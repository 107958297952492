const zhCN = {
	title: '立即注册，即可免费试用多款核心产品！',
	service: {
		fullDay: '7*24小时服务',
		originalFactory: '原厂服务',
		trial: '免费试用',
		allPeriod: '全周期服务',
	},
};

const enUS = {};


const i18nComponentMap: Record<string, any> = {zhCN, enUS};

export default i18nComponentMap;
